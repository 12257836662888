import React, { Fragment, useEffect, useState, useRef } from "react";
import { Field } from "redux-form";
import core from "core";
import enhancers from "./enhancers";
import { Editor } from "@tinymce/tinymce-react";
const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function BroadcastForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  createUpdateId,
  clearCreateUpdateId,
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearCreateUpdateId(), []);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    () => goBackList(createUpdateId)
  );

  const isSaving = isAdding || isUpdating;

  return (
    <Fragment>
      <PageTitle title={`Зарлал ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup label="Гарчиг">
                    <Field
                      required
                      name="title"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Гарчиг"
                    />
                  </FormGroup>
                </div>
                {/* <div className="col-xl-4">
                  <FormGroup label="Илгээх суваг">
                    <Field
                      multi={true}
                      name="channels"
                      placeholder="Илгээх суваг"
                      options={[
                        { value: "Notification", label: "notification" },
                        { value: "SMS", label: "sms" },
                        { value: "Email", label: "email" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div> */}
                <div className="col-xl-4">
                  <FormGroup required label="Төрөл">
                    <Field
                      required
                      name="broadcast_type"
                      placeholder="Сонгох.."
                      options={[
                        { value: "partial", label: "Тодорхой мерчант руу" },
                        { value: "all", label: "Бүх мерчант руу" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                {/* <div className="col-xl-4">
                  <FormGroup required label="Суваг">
                    <Field
                      required
                      multi={true}
                      name="channels"
                      placeholder="Сонгох.."
                      options={[
                        { value: "push", label: "Push notification" },
                        { value: "chatbot", label: "Admin chatbot" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div> */}
              </div>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup label="Зураг">
                    <Field
                      name="image"
                      disabled={isSaving}
                      component={core.components.FileUpload}
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup label="Холбоос">
                    <Field
                      name="url"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="https://zochil.mn"
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <FormGroup label="Зурвас">
                    <Field
                      requireds
                      name="body"
                      component="textarea"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Зурвас"
                      cols="60"
                      rows="10"
                    />
                  </FormGroup>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-xl-12">
                  <FormGroup label="Мейл">
                    <Editor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p></p>"
                      requireds
                      component="textarea"
                      disabled={isSaving}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </FormGroup>
                </div>
              </div> */}
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const BroadcastFormWithForm = enhancers.form(BroadcastForm);
const BroadcastFormWithState = enhancers.redux(BroadcastFormWithForm);
export default BroadcastFormWithState;
