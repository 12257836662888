import React, { Fragment } from "react";

function Tabs({ tabIndex, setTabIndex }) {
  return (
    <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
      <li className="nav-item">
        <a
          href="#shop-admins"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link rounded-0 active"
        >
          Админ / Ажилчид
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#shop-domains"
          data-toggle="tab"
          aria-expanded="true"
          className="nav-link rounded-0"
        >
          Домэйн
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#shop-options"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link rounded-0"
        >
          Тохиргоонууд
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#shop-pm"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link rounded-0"
        >
          Данс / Т.Х
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#shop-modules"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link rounded-0"
        >
          Масс мессеж
        </a>
      </li>
    </ul>
  );
}

export default Tabs;
