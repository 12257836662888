import * as types from "./actionTypes";
import * as constants from "./constants";
import ServiceFactory from "lib/serviceFactory";
import { call, put, takeLatest } from "redux-saga/effects";

export function* fetchWithdrawals({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_WITHDRAWALS_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_WITHDRAWALS_COMPLETE,
      payload: {
        withdrawals: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_WITHDRAWALS_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* fetchWithdrawalDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_WITHDRAWAL_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_WITHDRAWAL_DETAIL_COMPLETE,
      payload: {
        withdrawal: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_WITHDRAWAL_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* watchSagas() {
  yield takeLatest(types.FETCH_WITHDRAWALS_BEGIN, fetchWithdrawals);
  yield takeLatest(types.FETCH_WITHDRAWAL_DETAIL_BEGIN, fetchWithdrawalDetail);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [watchSagas()];
