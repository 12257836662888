import { reset } from "redux-form";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import core from "core";
import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { location: { search } }) => ({
  isFetching: loading(state[constants.NAME], constants.FETCH_AD_CAMPAIGNS),
  fetchResult: selectors.getCampaigns(state[constants.NAME]) || {
    adCampaigns: [],
  },
  filter: selectors.getFilter(state[constants.NAME]),
  ...core.selectors.getPaginationProps(
    selectors.getCampaigns(state[constants.NAME]) || { totalCount: 0 },
    search
  ),
});
export const mapDispatchToProps = (dispatch) => ({
  list(page, filter) {
    dispatch(actions.fetchAdCampaigns({ options: { page }, filter }));
  },
  setFilter(filter) {
    dispatch(actions.setFilter({ filter }));
  },
  clearFilter() {
    dispatch(reset("adCampaignsFilterForm"));
    dispatch(actions.setFilter({ filter: null }));
  },
  updateQueryString(page) {
    dispatch(push(`/ad_campaigns?page=${page}`));
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
