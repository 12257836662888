import pick from 'lodash/pick';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match, location: { search } }) => ({
  isEdit: !!match.params.id,
  currentPage: (queryString.parse(search) || {}).page,
  updateError: error(state[constants.NAME], constants.UPDATE_CATEGORY),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_CATEGORY),
  isFetching: loading(state[constants.NAME], constants.FETCH_CATEGORY_DETAIL),
  initialValues: !match.params.id ? {} : pick(
    selectors.getCategoryDetail(
      state[constants.NAME],
    ),
    [
      "name",
      "price",
      "sale_channels",
      "main_category_codes",
    ]
  )
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchCategoryDetail({ id: params.id }));  
    }
  },
  onSubmit(category) {
    if (params.id) {
      category.sale_channels = (category.sale_channels || []).map(sc => sc.id);
      category.main_category_codes = (category.main_category_codes || []).map(sc => sc.id);
      dispatch(actions.updateCategory({ id: params.id, category }));
    }
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.UPDATE_PRODUCT }));
  },
  clearForm() {},
  goBackList(page) {
    dispatch(push(`/product-categories?page=${page}`));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
