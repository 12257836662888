import React from 'react';

export function Loading() {
  return (
    <div className="__spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  )
}

export default Loading;
