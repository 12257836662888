import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BROADCASTS_BEGIN:
      return {
        ...state,
        isFetchingBroadcasts: true
      };

    case types.FETCH_BROADCASTS_COMPLETE:
      return {
        ...state,
        isFetchingBroadcasts: false,
        broadcasts: action.payload.broadcasts
      };

    case types.FETCH_BROADCASTS_ERROR:
      return {
        ...state,
        isFetchingBroadcasts: false,
        errors: {
          ...state.errors,
          [constants.FETCH_BROADCASTS]: action.payload.error.message
        }
      };

    case types.FETCH_BROADCAST_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingBroadcastDetail: true
      };

    case types.FETCH_BROADCAST_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingBroadcastDetail: false,
        broadcastDetail: action.payload.broadcastDetail
      };

    case types.FETCH_BROADCAST_DETAIL_ERROR:
      return {
        ...state,
        isFetchingBroadcastDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_BROADCAST_DETAIL]: action.payload.error.message
        }
      };

    case types.ADD_BROADCAST_BEGIN:
      return {
        ...state,
        isAddingBroadcasts: true
      };

    case types.ADD_BROADCAST_COMPLETE:
      return {
        ...state,
        isAddingBroadcasts: false,
        createUpdateId: action.payload.id
      };

    case types.ADD_BROADCAST_ERROR:
      return {
        ...state,
        isAddingBroadcasts: false,
        errors: {
          ...state.errors,
          [constants.ADD_BROADCAST]: action.payload.error.message
        }
      };

    case types.UPDATE_BROADCAST_BEGIN:
      return {
        ...state,
        isUpdatingBroadcasts: true
      };

    case types.UPDATE_BROADCAST_COMPLETE:
      return {
        ...state,
        isUpdatingBroadcasts: false,
        createUpdateId: action.payload.id
      };

    case types.UPDATE_BROADCAST_ERROR:
      return {
        ...state,
        isUpdatingBroadcasts: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_BROADCAST]: action.payload.error.message
        }
      };

    case types.REMOVE_BROADCAST_BEGIN:
      return {
        ...state,
        isRemovingBroadcasts: true
      };

    case types.REMOVE_BROADCAST_COMPLETE:
      return {
        ...state,
        isRemovingBroadcasts: false
      };

    case types.REMOVE_BROADCAST_ERROR:
      return {
        ...state,
        isRemovingBroadcasts: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_BROADCAST]: action.payload.error.message
        }
      };

    case types.SET_SHOP_IDS:
      return {
        ...state,
        checkedShopIds: action.payload.ids
      };

    case types.CLEAR_CREATE_UPDATE_ID:
      return {
        ...state,
        createUpdateId: null
      };

    case types.SEND_BROADCAST_BEGIN:
      return {
        ...state,
        isSendingBroadcast: true
      };

    case types.SEND_BROADCAST_COMPLETE:
      return {
        ...state,
        isSendingBroadcast: false
      };

    case types.SEND_BROADCAST_ERROR:
      return {
        ...state,
        isSendingBroadcast: false,
        errors: {
          ...state.errors,
          [constants.SEND_BROADCAST]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    case types.SET_SHOP_FILTER:
      return {
        ...state,
        shopFilter: action.payload.filter
      };

    default:
      return state;
  }
};

export default notificationsReducer;
