import React from 'react';
import moment from 'moment';

function Footer() {
  return <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 text-center">
          { moment().format('YYYY') } © zochil.shop
        </div>
      </div>
    </div>
  </footer>
}

export default Footer;
