import * as types from "./actionTypes";
export const fetchTransactions = ({ filter, options }) => ({
  type: types.FETCH_TRANSACTIONS_BEGIN,
  payload: {
    filter,
    options
  }
});
export const fetchTransactionDetail = ({ id }) => ({
  type: types.FETCH_TRANSACTION_DETAIL_BEGIN,
  payload: {
    id
  }
});
export const changeStatus = ({ id, status }) => ({
  type: types.CHANGE_STATUS_BEGIN,
  payload: {
    id,
    status
  }
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const setFilter = ({ filter }) => ({
  
  type: types.SET_FILTER,
  payload: {
    filter
  }
});
