import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import React, { Fragment, useEffect } from "react";
import Summary from "./partials/summary";
import core from "core";
import enhancers from "./enhancers";
import ReactJson from "react-json-view";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function TransactionDetail({ isFetching, fetchDetail, payment }) {
  useEffect(() => fetchDetail(), []);

  return (
    <Fragment>
      <PageTitle title="Төлбөрийн дэлгэрэнгүй" />
      {isFetching === undefined || isFetching === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-3">
            <Summary transaction={payment.payment_request} />
          </div>
          <div className="col-lg-9">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  {/* <ReactJson src={payment.body} theme="monokai" /> */}
                  {payment.payment_request.status === "200" &&
                    "Төлбөр амжилттай төлөгдсөн "}
                  {payment.payment_request.status === "600" &&
                    `Асуудал гарсан:  ${payment.payment_request.response.message.order_no}`}
                  {payment.payment_request.status === "401" &&
                    `Асуудал гарсан:  ${payment.payment_request.response.name}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const TransactionDetailWithState = enhancers.redux(TransactionDetail);
export default TransactionDetailWithState;
