import { all } from "redux-saga/effects";

import core from "core";
import bills from "app/bills";
import shops from "app/shops";
import users from "app/users";
import orders from "app/orders";
import admins from "app/admins";
import issues from "app/issues";
import products from "app/products";
import messages from "app/messages";
import channels from "app/channels";
import dashboard from "app/dashboard";
import customers from "app/customers";
import activities from "app/activities";
import settlements from "app/settlements";
import transactions from "app/transactions";
import notifications from "app/notifications";
import payments from "app/payments";
import contracts from "app/contracts";
import modules from "app/modules";
import campaigns from "app/campaigns";
import ad_sellers from "app/ad/sellers";
import withdrawals from "app/ad/withdrawals";
import ad_campaigns from "app/ad/ad-campaigns";

export default function* appSaga() {
  return yield all([
    ...core.sagas,
    ...bills.sagas,
    ...shops.sagas,
    ...users.sagas,
    ...orders.sagas,
    ...admins.sagas,
    ...issues.sagas,
    ...messages.sagas,
    ...channels.sagas,
    ...products.sagas,
    ...dashboard.sagas,
    ...customers.sagas,
    ...activities.sagas,
    ...settlements.sagas,
    ...notifications.sagas,
    ...transactions.sagas,
    ...payments.sagas,
    ...contracts.sagas,
    ...modules.sagas,
    ...campaigns.sagas,
    ...ad_sellers.sagas,
    ...withdrawals.sagas,
    ...ad_campaigns.sagas,
  ]);
}
