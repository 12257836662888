import { Field } from "redux-form";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function AdminForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  admin_type,
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearForm(), []);
  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    goBackList
  );

  const isSaving = isAdding || isUpdating;

  return (
    <Fragment>
      <PageTitle title={`Админ ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Овог">
                    <Field
                      required
                      name="last_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Овог"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Нэр">
                    <Field
                      required
                      name="first_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэр"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Нэвтрэх нэр">
                    <Field
                      required
                      name="username"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэвтрэх нэр"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Утас">
                    <Field
                      required
                      name="phone"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Утас"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup
                    required={!isEdit ? true : undefined}
                    label="Нууц үг"
                  >
                    <Field
                      required={!isEdit ? true : undefined}
                      name="password"
                      type="password"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нууц үг"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup label="Регистрийн дугаар">
                    <Field
                      name="regno"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Регистрийн дугаар"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4 mb-3">
                  <FormGroup required label="Төрөл">
                    <Field
                      name="admin_type"
                      placeholder="Төрөл.."
                      options={[
                        { value: "admin", label: "Супер админ" },
                        { value: "operator", label: "Оператор" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                {
                  admin_type === "operator" &&
                  <div className="col-xl-8 mb-3">
                    <FormGroup required label="Эрхийн тохиргоо">
                      <Field
                        multi={true}
                        name="roles"
                        placeholder="Эрхийн тохиргоо.."
                        options={[
                          { value: "shop_view", label: "Дэлгүүр үзэх" },
                          { value: "shop_manage", label: "Дэлгүүр удирдах" },
                          { value: "issue_view", label: "Хүсэлт үзэх" },
                          { value: "issue_manage", label: "Хүсэлт удирдах" },
                          { value: "user_view", label: "Хэрэглэгч үзэх" },
                          { value: "user_manage", label: "Хэрэглэгч удирдах" },
                          { value: "order_view", label: "Захиалга үзэх" },
                          { value: "order_manage", label: "Захиалга удирдах" },
                          { value: "broadcast_view", label: "Зарлал үзэх" },
                          { value: "broadcast_manage", label: "Зарлал удирдах" },
                        ]}
                        component={core.components.SelectField}
                      />
                    </FormGroup>
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const AdminFormWithForm = enhancers.form(AdminForm);
const AdminFormWithState = enhancers.redux(AdminFormWithForm);

export default AdminFormWithState;
