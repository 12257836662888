import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchAdSellers({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_AD_SELLERS_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_AD_SELLERS_COMPLETE,
      payload: {
        adSellers: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_AD_SELLERS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchAdSellerDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_AD_SELLER_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_AD_SELLER_DETAIL_COMPLETE,
      payload: {
        adSeller: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_AD_SELLER_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* watchSagas() {
  yield takeLatest(types.FETCH_AD_SELLERS_BEGIN, fetchAdSellers);
  yield takeLatest(types.FETCH_AD_SELLER_DETAIL_BEGIN, fetchAdSellerDetail);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [watchSagas()];
