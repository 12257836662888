import React from "react";

import { Route } from "react-router";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import core from "core";
import appSagas from "app/sagas";
import appRoutes from "app/routes";
import appReducer from "app/reducer";
import ReduxClient from "lib/reduxClient";

const reduxClient = ReduxClient.getInstance(appReducer);
const MainLayout = core.components.MainLayout;
const store = reduxClient.getStore();

reduxClient.runSaga(appSagas);

export default class MainAppContainer extends React.PureComponent {
  componentDidMount() {
    store.dispatch(core.actions.init());
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={reduxClient.getHistory()}>
          <MainLayout>
            {appRoutes.map(({ component, path }) => (
              <Route
                path={path}
                exact={true}
                component={component}
                key={`route_path_${path}`}
              />
            ))}
          </MainLayout>
        </ConnectedRouter>
      </Provider>
    );
  }
}
