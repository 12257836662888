export const SET_FILTER = "@@ad_sellers/SET_FILTER";
export const RESET_ERROR = "@@ad_sellers/RESET_ERROR";
export const FETCH_AD_SELLERS_BEGIN = "@@ad_sellers/FETCH_AD_SELLERS_BEGIN";
export const FETCH_AD_SELLERS_COMPLETE =
  "@@ad_sellers/FETCH_AD_SELLERS_COMPLETE";
export const FETCH_AD_SELLERS_ERROR = "@@ad_sellers/FETCH_AD_SELLERS_ERROR";
export const FETCH_AD_SELLER_DETAIL_BEGIN =
  "@@ad_sellers/FETCH_AD_SELLER_DETAIL_BEGIN";
export const FETCH_AD_SELLER_DETAIL_COMPLETE =
  "@@ad_sellers/FETCH_AD_SELLER_DETAIL_COMPLETE";
export const FETCH_AD_SELLER_DETAIL_ERROR =
  "@@ad_sellers/FETCH_AD_SELLER_DETAIL_ERROR";
