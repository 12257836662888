import screens from './screens';

export default [
  {
    exact: true,
    path: '/shops',
    component: screens.ShopList,
  },
  {
    exact: true,
    path: '/shops/d/:id',
    component: screens.ShopDetail,
  },
  {
    exact: true,
    path: '/shops/edit/:id',
    component: screens.ShopForm,
  }
];