import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
  isChangingStatus,
  changeStatus,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 120 }}>Төлөв</th>
            <th style={{ width: 120 }}>Суваг</th>
            <th colSpan="2" style={{ width: 200 }}>
              Дэлгүүр
            </th>
            <th style={{ width: 150 }}>Утас</th>
            <th style={{ width: 150 }}>Огноо</th>
            <th style={{ width: 125 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-warning": row.status === "pending",
                    "badge-success": row.status === "accepted",
                    "badge-danger":
                      ["stuck", "rejected", "error"].indexOf(row.status) > -1,
                  })}
                  style={{
                    width: `4.8rem`,
                  }}
                >
                  {StatusMap[row.status]}
                </span>
              </td>
              {row.sale_channel === "hipay" && (
                <td className="id">
                  {" "}
                  <span
                    className="badge text-white font-weight-bold py-1"
                    style={{
                      width: "5rem",
                      background: `linear-gradient(190deg,  #f5305e 0%, #c21a6d 100%)`,
                    }}
                  >
                    HiPay
                  </span>
                </td>
              )}
              {row.sale_channel === "monpay" && (
                <td className="id">
                  <span
                    className="badge text-white font-weight-bold py-1"
                    style={{
                      width: "5rem",
                      background: `linear-gradient(190deg,  #914ff7 0%, #14dbf7 100%)`,
                    }}
                  >
                    Monpay
                  </span>
                </td>
              )}
              {row.sale_channel === "socialpay" && (
                <td className="id">
                  <span
                    className="badge text-white font-weight-bold py-1"
                    style={{
                      width: "5rem",
                      background: `linear-gradient(190deg,  #0c9dc4 0%, #076aaf 100%)`,
                    }}
                  >
                    SocialPay
                  </span>
                </td>
              )}
              <td>
                <Link to={`/shops/d/${row.shop_id}`}>{row.name || "-"}</Link>
                <Link to={`/shops/d/${row.shop_id}`}>{row.name || "-"}</Link>
              </td>
              <td>{row.phone_number}</td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td style={{ width: 125 }}>
                {row.status === "pending" && (
                  <a
                    href="#"
                    className="action-icon"
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        changeStatus(row.id, "resolved");
                      }
                    }}
                  >
                    <i className="uil-check-circle" />
                  </a>
                )}
                <Link to={`/channels/edit/${row.id}`} className="action-icon">
                  {" "}
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
                <a
                  href="#"
                  className="action-icon"
                  onClick={(event) => {
                    event.preventDefault();
                    if (window.confirm("Та итгэлтэй байна уу?")) {
                      if (!isRemoving) {
                        remove(row.id);
                      }
                    }
                  }}
                >
                  <i className="mdi mdi-delete" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  error: "Алдаа гарсан",
  pending: "Хүлээгдэж буй",
  accepted: "Нэмсэн",
  stuck: "Гацсан",
  rejected: "Цуцлагдсан",
};

export default Table;
