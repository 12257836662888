export default class UsersService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchUsers({ filter, options }) {
    const { data: users, total: totalCount } = await this.fetchUtil.sendGet(
      `/users/list${this.fetchUtil.qs({
        filter,
        options
      })}`
    );

    return { users, totalCount }
  }

  async fetchUserDetail({ id }) {
    const { data : user } = await this.fetchUtil.sendGet(`/users/detail/${id}`);

    return user;
  }

  async addUser({ user }) {
    await this.fetchUtil.sendPost("/users/create", user);
  }

  async updateUser({ id, user }) {
    await this.fetchUtil.sendPost("/users/update", { ...user, id });
  }

  async removeUser({ id }) {
    await this.fetchUtil.sendPost("/users/remove", { id });
  }

  __PLACEHOLDER_METHOD__() {}
}
