import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchActivities({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_ACTIVITIES_URL,
      {
        filter,
        options
      }
    );
    yield put({
      type: types.FETCH_ACTIVITIES_COMPLETE,
      payload: {
        activities: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_ACTIVITIES_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchActivityDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_ACTIVITY_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_ACTIVITY_DETAIL_COMPLETE,
      payload: {
        handoutDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_ACTIVITY_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_ACTIVITIES_BEGIN, fetchActivities);
  yield takeLatest(types.FETCH_ACTIVITY_DETAIL_BEGIN, fetchActivityDetail);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
