import React from 'react';
import { Link } from 'react-router-dom';

function PageTitle({ title, links = [], breadcrumbTitle }) {
  return <div className="page-title-box">
    <div className="page-title-right">
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item"><Link to="/">Эхлэл</Link></li>
        {
          links.map((link, index) =>
            <li key={`br_${index}`} className="breadcrumb-item" >
              <Link to={link.url}>{ link.title }</Link>
            </li>
          )
        }
        <li className="breadcrumb-item active">{ breadcrumbTitle || title }</li>
      </ol>
    </div>
    <h4 style={{ minHeight: 75 }} className="page-title">{ title }</h4>
  </div>

}

export default PageTitle;
