import * as types from "./actionTypes";
export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const changePassword = ({ currentPassword, newPassword }) => ({
  type: types.CHANGE_PASSWORD_BEGIN,
  payload: {
    currentPassword,
    newPassword
  }
});
export const fetchSummary = () => ({
  type: types.FETCH_SUMMARY_BEGIN
});
export const fetchTopHits = () => ({
  type: types.FETCH_TOP_HITS_BEGIN
});
export const fetchDailyHits = ({ start, end }) => ({
  type: types.FETCH_DAILY_HITS_BEGIN,
  payload: {
    start,
    end
  }
});
export const fetchMonthlyHits = () => ({
  type: types.FETCH_MONTHLY_HITS_BEGIN
});
export const fetchDailyOrders = ({ start, end }) => ({
  type: types.FETCH_DAILY_ORDERS_BEGIN,
  payload: {
    start,
    end
  }
});
export const fetchMonthlyOrders = () => ({
  type: types.FETCH_MONTHLY_ORDERS_BEGIN
});
const __PLACEHOLDER__ = null; // ignore this.

export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter
  }
});
