import React from "react";
import moment from "moment";
import { reduxForm, Field } from "redux-form";

function UsersTable({
  users = [],
  handleSubmit,
  unlinkUser,
  isLinkingUser,
  isUnlinkingUser,
}) {
  return (
    <div id="shop-admins" className="tab-pane fade show active" role="tabpanel">
      <div className="row mb-2">
        <div className="col-lg-12">
          <form
            onSubmit={handleSubmit}
            className="form-inline shop-filter-form"
          >
            <div style={{ width: 250 }} className="form-group mr-2">
              <Field
                style={{ width: 250 }}
                required
                disabled={isLinkingUser || isUnlinkingUser}
                component="input"
                type="number"
                className="form-control"
                placeholder="Утас"
                name="phone"
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isLinkingUser || isUnlinkingUser}
                className="btn btn-primary text-bold"
              >
                Админ нэмэх
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="table-responsive">
        <table className="shops-table table table-centered mb-0">
          <thead className="thead-light">
            <tr>
              <th style={{ width: 20 }}>#</th>
              <th>Нэр</th>
              <th style={{ width: 100 }}>Утас</th>
              <th style={{ width: 150 }}>Огноо</th>
              <th className="text-center" style={{ width: 50 }} />
            </tr>
          </thead>
          <tbody>
            {(users || []).map((row, index) => (
              <tr key={`tr_${row.id}`}>
                <td>{index + 1}</td>
                <td className="name">{`${row.last_name} ${row.first_name}`}</td>
                <td>{row.phone}</td>
                <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
                <td className="text-center" style={{ width: 50 }}>
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    href="#"
                    className={"action-icon text-danger"}
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        unlinkUser(row.id);
                      }
                    }}
                  >
                    <i className="mdi mdi-trash-can-outline" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "linkShopUserForm",
  enableReinitialize: true,
})(UsersTable);
