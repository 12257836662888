import * as constants from "./constants";
import { createSelector } from "reselect";

const loadingSelector = function (state, featureName) {
  switch (featureName) {
    case constants.FETCH_AD_SELLERS:
      return state.isFetchingAdSellers;

    case constants.FETCH_AD_SELLER_DETAIL:
      return state.isFetchingAdSellerDetail;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getAdSellers = createSelector(
  [(state) => state.adSellers],
  (adSellers) => adSellers
);
export const getSellerDetail = createSelector(
  [(state) => state.adSeller],
  (adSeller) => adSeller
);

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
