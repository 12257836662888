import * as types from "./actionTypes";
export const fetchProducts = ({ filter, options }) => ({
  type: types.FETCH_PRODUCTS_BEGIN,
  payload: {
    filter,
    options
  }
});
export const fetchProductCategories = ({ filter, options }) => ({
  type: types.FETCH_PRODUCT_CATEGORIES_BEGIN,
  payload: {
    filter,
    options
  }
});
export const disableProduct = ({ id }) => ({
  type: types.DISABLE_PRODUCT_BEGIN,
  payload: {
    id
  }
});
export const assignMainCategory = ({ id, codes, is_category }) => ({
  type: types.ASSIGN_MAIN_CATEGORY_BEGIN,
  payload: {
    id,
    codes,
    is_category
  }
});
export const assignMainAttribute = ({ id, codes, is_category }) => ({
  type: types.ASSIGN_MAIN_ATTRIBUTE_BEGIN,
  payload: {
    id,
    codes,
    is_category
  }
});
export const addProduct = ({ product }) => ({
  type: types.ADD_PRODUCT_BEGIN,
  payload: {
    product
  }
});
export const removeProduct = ({ id }) => ({
  type: types.REMOVE_PRODUCT_BEGIN,
  payload: {
    id
  }
});
export const updateProduct = ({ id, product }) => ({
  type: types.UPDATE_PRODUCT_BEGIN,
  payload: {
    id,
    product
  }
});
export const fetchProductDetail = ({ id }) => ({
  type: types.FETCH_PRODUCT_DETAIL_BEGIN,
  payload: {
    id
  }
});
export const fetchCategories = ({ filter, options }) => ({
  type: types.FETCH_CATEGORIES_BEGIN,
  payload: {
    filter,
    options
  }
});
export const fetchCategoryDetail = ({ id }) => ({
  type: types.FETCH_CATEGORY_DETAIL_BEGIN,
  payload: {
    id
  }
});
export const updateCategory = ({ id, category }) => ({
  type: types.UPDATE_CATEGORY_BEGIN,
  payload: {
    id,
    category
  }
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter
  }
});
