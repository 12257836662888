import moment from "moment";
import { Field } from "redux-form";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";
import { Link } from "react-router-dom";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function UserForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  shops = [],
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearForm(), []);
  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    goBackList
  );

  const isSaving = isAdding || isUpdating;

  return (
    <Fragment>
      <PageTitle title={`Хэрэглэгч ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Овог">
                    <Field
                      required
                      name="last_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Овог"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Нэр">
                    <Field
                      required
                      name="first_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэр"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Утас">
                    <Field
                      required
                      name="phone"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Утас"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup
                    required={!isEdit ? true : undefined}
                    label="Нууц үг"
                  >
                    <Field
                      required={!isEdit ? true : undefined}
                      name="password"
                      type="password"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нууц үг"
                    />
                  </FormGroup>
                </div>
              </div>
              {isEdit && (
                <div className="row">
                  <div className="col-xl-12">
                    <div className="form-group">
                      <label>Удирддаг дэлгүүрүүд</label>
                      <table className="shops-table table table-centered mb-0 table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: 20 }}>#</th>
                            <th>Дэлгүүр</th>
                            <th style={{ width: 100 }}>Домэйн</th>
                            <th style={{ width: 150 }}>Огноо</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(shops || []).map((row, index) => (
                            <tr key={`tr_${row.id}`}>
                              <td>{index + 1}</td>
                              <td className="name">
                                <Link className="text-primary" to={`/shops/d/${row.id}`} >{row.name}</Link>
                              </td>
                              <td>
                                {row.custom_domain
                                  ? row.custom_domain
                                  : `${row.uid}.zochil.shop`}
                              </td>
                              <td>
                                {moment(row.created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const UserFormWithForm = enhancers.form(UserForm);
const UserFormWithState = enhancers.redux(UserFormWithForm);

export default UserFormWithState;
