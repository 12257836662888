import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function TransactionList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  remove,
  isRemoving,
  list,
  pages,
  limit,
  updateQueryString,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);
  useEffect(() => {
    if (isRemoving === false) {
      list(currentPage, filter);
    }
  }, [isRemoving]);
  
  return (
    <Fragment>
      <PageTitle title="Гүйлгээнүүд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["phone", "code", "price"]);

                if (formData.status) {
                  theFilter.status = formData.status.id;
                }

                if (formData.channel) {
                  theFilter.channel = formData.channel.id;
                }
                // if (formData.channels) {
                //   theFilter.channels = formData.channels.id;
                // }

                if (formData.read) {
                  theFilter.read = formData.read.id;
                }

                if (formData.start) {
                  theFilter.start = formData.start.toISOString();
                }

                if (formData.end) {
                  theFilter.end = formData.end.toISOString();
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Fragment>
              <Table
                limit={limit}
                currentPage={currentPage}
                rows={fetchResult.invoices || []}
              />
              <Pagination
                url="/transactions"
                pages={pages}
                currentPage={currentPage}
              />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const TransactionListWithState = enhancers.redux(TransactionList);
export default TransactionListWithState;
