import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.FETCH_BROADCASTS:
      return state.isFetchingBroadcasts;
      break;

    case constants.FETCH_BROADCAST_DETAIL:
      return state.isFetchingBroadcastDetail;
      break;

    case constants.ADD_BROADCAST:
      return state.isAddingBroadcasts;
      break;

    case constants.UPDATE_BROADCAST:
      return state.isUpdatingBroadcasts;
      break;

    case constants.REMOVE_BROADCAST:
      return state.isRemovingBroadcasts;
      break;

    case constants.SEND_BROADCAST:
      return state.isSendingBroadcast;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getBroadcasts = createSelector(
  [state => state.broadcasts],
  broadcasts => broadcasts
);
export const getBroadcastDetail = createSelector(
  [state => state.broadcastDetail],
  broadcastDetail => broadcastDetail
);
export const getShopIds = createSelector(
  [state => state.checkedShopIds],
  ids => ids
);
export const getCreateUpdateId = createSelector(
  [state => state.createUpdateId],
  id => id
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [state => state.filter],
  filter => filter
);
export const getShopFilter = createSelector(
  [state => state.shopFilter],
  filter => filter
);
