import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchBroadcasts({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_BROADCASTS_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_BROADCASTS_COMPLETE,
      payload: {
        broadcasts: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_BROADCASTS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchBroadcastDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_BROADCAST_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_BROADCAST_DETAIL_COMPLETE,
      payload: {
        broadcastDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_BROADCAST_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addBroadcast({ payload: { broadcast } }) {
  try {
    const id = yield call(ServiceFactory.call, constants.ADD_BROADCAST_URL, {
      broadcast,
    });

    yield put({
      type: types.ADD_BROADCAST_COMPLETE,
      payload: {
        id,
      },
    });
  } catch (error) {
    yield put({
      type: types.ADD_BROADCAST_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* updateBroadcast({ payload: { id, broadcast } }) {
  try {
    yield call(ServiceFactory.call, constants.UPDATE_BROADCAST_URL, {
      id,
      broadcast,
    });
    yield put({
      type: types.UPDATE_BROADCAST_COMPLETE,
      payload: {
        id,
      },
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_BROADCAST_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* removeBroadcast({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.REMOVE_BROADCAST_URL,
      {
        id,
      }
    );
    yield put({
      type: types.REMOVE_BROADCAST_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_BROADCAST_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* sendBroadcast({ payload: { id, shop_ids } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.SEND_BROADCAST_URL,
      {
        id,
        shop_ids,
      }
    );
    yield put({
      type: types.SEND_BROADCAST_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.SEND_BROADCAST_ERROR,
      payload: {
        error,
      },
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_BROADCASTS_BEGIN, fetchBroadcasts);
  yield takeLatest(types.FETCH_BROADCAST_DETAIL_BEGIN, fetchBroadcastDetail);
  yield takeLatest(types.ADD_BROADCAST_BEGIN, addBroadcast);
  yield takeLatest(types.UPDATE_BROADCAST_BEGIN, updateBroadcast);
  yield takeLatest(types.REMOVE_BROADCAST_BEGIN, removeBroadcast);
  yield takeLatest(types.SEND_BROADCAST_BEGIN, sendBroadcast);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
