import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { formValueSelector } from 'redux-form';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

const formSelector = formValueSelector('adminForm');
const ROLE_NAMES = {
  "shop_view": "Дэлгүүр үзэх",
  "shop_manage": "Дэлгүүр удирдах",
  "issue_view": "Хүсэлт үзэх",
  "issue_manage": "Хүсэлт удирдах",
  "user_view": "Хэрэглэгч үзэх",
  "user_manage": "Хэрэглэгч удирдах",
  "order_view": "Захиалга үзэх",
  "order_manage": "Захиалга удирдах",
  "broadcast_view": "Зарлал үзэх",
  "broadcast_manage": "Зарлал удирдах",
};

const getRolesOptions = (admin) => {
  return (admin?.roles || []).map(role => ({
    id: role,
    name: ROLE_NAMES[role],
  }));
}

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  admin_type: (formSelector(state, 'admin_type') || {}).id,
  addError: error(state[constants.NAME], constants.ADD_ADMIN),
  isAdding: loading(state[constants.NAME], constants.ADD_ADMIN),
  updateError: error(state[constants.NAME], constants.UPDATE_ADMIN),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_ADMIN),
  isFetching: loading(state[constants.NAME], constants.FETCH_ADMIN_DETAIL),
  initialValues: !match.params.id ? {} : {
    ...selectors.getAdminDetail(
      state[constants.NAME],
    ),
    roles: getRolesOptions(selectors.getAdminDetail(
      state[constants.NAME],
    )),
    admin_type: getRolesOptions(selectors.getAdminDetail(
      state[constants.NAME],
    ))?.is_super === true ? { name: 'Админ', id: "admin" } : { name: 'Оператор', id: "operator" }
  }
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchAdminDetail({ id: params.id }));  
    }
  },
  onSubmit(admin) {
    admin.is_super = admin.admin_type === 'admin';
    admin.roles = (admin.roles || []).map(adm => adm.id);

    if (params.id) {
      dispatch(actions.updateAdmin({ id: params.id, admin }));
    } else {
      dispatch(actions.addAdmin({ admin }));
    }
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.ADD_ADMIN }));
    dispatch(actions.resetError({ name: constants.UPDATE_ADMIN }));
  },
  clearForm() {},
  goBackList() {
    dispatch(push('/admins'));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
