export const RESET_ERROR = "@@products/RESET_ERROR";
export const SET_FILTER = "@@products/SET_FILTER";
export const FETCH_PRODUCTS_BEGIN = "@@products/FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_COMPLETE = "@@products/FETCH_PRODUCTS_COMPLETE";
export const FETCH_PRODUCTS_ERROR = "@@products/FETCH_PRODUCTS_ERROR";
export const DISABLE_PRODUCT_BEGIN = "@@products/DISABLE_PRODUCT_BEGIN";
export const DISABLE_PRODUCT_COMPLETE = "@@products/DISABLE_PRODUCT_COMPLETE";
export const DISABLE_PRODUCT_ERROR = "@@products/DISABLE_PRODUCT_ERROR";
export const FETCH_PRODUCT_CATEGORIES_BEGIN =
  "@@products/FETCH_PRODUCT_CATEGORIES_BEGIN";
export const FETCH_PRODUCT_CATEGORIES_COMPLETE =
  "@@products/FETCH_PRODUCT_CATEGORIES_COMPLETE";
export const FETCH_PRODUCT_CATEGORIES_ERROR =
  "@@products/FETCH_PRODUCT_CATEGORIES_ERROR";
export const ASSIGN_MAIN_CATEGORY_BEGIN =
  "@@products/ASSIGN_MAIN_CATEGORY_BEGIN";
export const ASSIGN_MAIN_CATEGORY_COMPLETE =
  "@@products/ASSIGN_MAIN_CATEGORY_COMPLETE";
export const ASSIGN_MAIN_CATEGORY_ERROR =
  "@@products/ASSIGN_MAIN_CATEGORY_ERROR";
export const ASSIGN_MAIN_ATTRIBUTE_BEGIN =
  "@@products/ASSIGN_MAIN_ATTRIBUTE_BEGIN";
export const ASSIGN_MAIN_ATTRIBUTE_COMPLETE =
  "@@products/ASSIGN_MAIN_ATTRIBUTE_COMPLETE";
export const ASSIGN_MAIN_ATTRIBUTE_ERROR =
  "@@products/ASSIGN_MAIN_ATTRIBUTE_ERROR";
export const ADD_PRODUCT_BEGIN = "@@products/ADD_PRODUCT_BEGIN";
export const ADD_PRODUCT_COMPLETE = "@@products/ADD_PRODUCT_COMPLETE";
export const ADD_PRODUCT_ERROR = "@@products/ADD_PRODUCT_ERROR";
export const REMOVE_PRODUCT_BEGIN = "@@products/REMOVE_PRODUCT_BEGIN";
export const REMOVE_PRODUCT_COMPLETE = "@@products/REMOVE_PRODUCT_COMPLETE";
export const REMOVE_PRODUCT_ERROR = "@@products/REMOVE_PRODUCT_ERROR";
export const UPDATE_PRODUCT_BEGIN = "@@products/UPDATE_PRODUCT_BEGIN";
export const UPDATE_PRODUCT_COMPLETE = "@@products/UPDATE_PRODUCT_COMPLETE";
export const UPDATE_PRODUCT_ERROR = "@@products/UPDATE_PRODUCT_ERROR";
export const FETCH_PRODUCT_DETAIL_BEGIN =
  "@@products/FETCH_PRODUCT_DETAIL_BEGIN";
export const FETCH_PRODUCT_DETAIL_COMPLETE =
  "@@products/FETCH_PRODUCT_DETAIL_COMPLETE";
export const FETCH_PRODUCT_DETAIL_ERROR =
  "@@products/FETCH_PRODUCT_DETAIL_ERROR";
export const FETCH_CATEGORIES_BEGIN = "@@products/FETCH_CATEGORIES_BEGIN";
export const FETCH_CATEGORIES_COMPLETE = "@@products/FETCH_CATEGORIES_COMPLETE";
export const FETCH_CATEGORIES_ERROR = "@@products/FETCH_CATEGORIES_ERROR";
export const FETCH_CATEGORY_DETAIL_BEGIN =
  "@@products/FETCH_CATEGORY_DETAIL_BEGIN";
export const FETCH_CATEGORY_DETAIL_COMPLETE =
  "@@products/FETCH_CATEGORY_DETAIL_COMPLETE";
export const FETCH_CATEGORY_DETAIL_ERROR =
  "@@products/FETCH_CATEGORY_DETAIL_ERROR";
export const UPDATE_CATEGORY_BEGIN = "@@products/UPDATE_CATEGORY_BEGIN";
export const UPDATE_CATEGORY_COMPLETE = "@@products/UPDATE_CATEGORY_COMPLETE";
export const UPDATE_CATEGORY_ERROR = "@@products/UPDATE_CATEGORY_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
