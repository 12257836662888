export const NAME = "dashboard";
export const CHANGE_PASSWORD = "change_password";
export const CHANGE_PASSWORD_URL = "dashboard/changePassword";
export const FETCH_SUMMARY = "fetch_summary";
export const FETCH_SUMMARY_URL = "dashboard/fetchSummary";
export const FETCH_TOP_HITS = "fetch_top_hits";
export const FETCH_TOP_HITS_URL = "dashboard/fetchTopHits";
export const FETCH_DAILY_HITS = "fetch_daily_hits";
export const FETCH_DAILY_HITS_URL = "dashboard/fetchDailyHits";
export const FETCH_MONTHLY_HITS = "fetch_monthly_hits";
export const FETCH_MONTHLY_HITS_URL = "dashboard/fetchMonthlyHits";
export const FETCH_DAILY_ORDERS = "fetch_daily_orders";
export const FETCH_DAILY_ORDERS_URL = "dashboard/fetchDailyOrders";
export const FETCH_MONTHLY_ORDERS = "fetch_monthly_orders";
export const FETCH_MONTHLY_ORDERS_URL = "dashboard/fetchMonthlyOrders";
const __PLACEHOLDER__ = null; // ignore this.
