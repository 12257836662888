import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import core from 'core';
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }} >#</th>
            <th style={{ width: 150 }} >Төлөв</th>
            <th>Гарчиг</th>
            <th style={{ width: 150 }} >Огноо</th>
            <th style={{ width: 125 }} />
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) =>
              <tr key={`tr_${row.id}`} >
                <td>{ core.helpers.getTableIndex(currentPage, limit, index) }</td>
                <td>
                  <span className={classNames('badge p-1', {
                    'badge-success': row.status === 'done',
                    'badge-warning': row.status === 'pending',
                    'badge-info': row.status === 'in_progress',
                  })}>
                    { StatusMap[row.status] }
                  </span>  
                </td>
                <td>
                  <Link to={`/broadcasts/detail/${row.id}`} >
                    { row.title }
                  </Link>
                </td>
                <td>{ moment(row.created_at).format('YYYY-MM-DD HH:mm') }</td>
                <td style={{ width: 125 }} >
                  <Link to={`/broadcasts/detail/${row.id}`} className="action-icon">
                    <i className="mdi mdi-eye" />
                  </Link>
                  <Link to={`/broadcasts/edit/${row.id}`} className="action-icon"> <i className="mdi mdi-square-edit-outline" /></Link>
                  <a
                    href="#"
                    className="action-icon"

                    onClick={(event) => {
                      event.preventDefault();

                      if (window.confirm('Та итгэлтэй байна уу?')) {
                        if (!isRemoving) {
                          remove(row.id);
                        }
                      }
                    }}
                  >
                      <i className="mdi mdi-delete" />
                  </a>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: 'Хүлээгдэж буй',
  in_progress: 'Илгээж буй',
  done: 'Илгээсэн',
};


export default Table;
