export default class CampaignsService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchCampaigns({ filter, options }) {
    const { data: campaigns, total: totalCount } = await this.fetchUtil.sendGet(
      `/merchant-campaigns/list${this.fetchUtil.qs({
        filter,
        options,
      })}`
    );

    return { campaigns, totalCount };
  }

  async fetchCampaignDetail({ id }) {
    const { data: campaign } = await this.fetchUtil.sendGet(
      `/merchant-campaigns/detail/${id}`
    );

    if (campaign) {
      campaign.expire_at = new Date(campaign.expire_at);
    }

    return campaign;
  }

  async addCampaign({ campaign }) {
    await this.fetchUtil.sendPost("/merchant-campaigns/create", campaign);
  }

  async updateCampaign({ id, campaign }) {
    await this.fetchUtil.sendPost("/merchant-campaigns/update", {
      ...campaign,
      id,
    });
  }

  async removeCampaign({ id }) {
    await this.fetchUtil.sendPost("/merchant-campaigns/remove", { id });
  }

  __PLACEHOLDER_METHOD__() {}
}
