import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const issuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ISSUES_BEGIN:
      return {
        ...state,
        isFetchingIssues: true
      };

    case types.FETCH_ISSUES_COMPLETE:
      return {
        ...state,
        isFetchingIssues: false,
        issues: action.payload.issues
      };

    case types.FETCH_ISSUES_ERROR:
      return {
        ...state,
        isFetchingIssues: false,
        errors: {
          ...state.errors,
          [constants.FETCH_ISSUES]: action.payload.error.message
        }
      };

    case types.FETCH_ISSUE_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingIssueDetail: true
      };

    case types.FETCH_ISSUE_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingIssueDetail: false,
        issueDetail: action.payload.issueDetail
      };

    case types.FETCH_ISSUE_DETAIL_ERROR:
      return {
        ...state,
        isFetchingIssueDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_ISSUE_DETAIL]: action.payload.error.message
        }
      };

    case types.ADD_ISSUE_BEGIN:
      return {
        ...state,
        isAddingIssues: true
      };

    case types.ADD_ISSUE_COMPLETE:
      return {
        ...state,
        isAddingIssues: false
      };

    case types.ADD_ISSUE_ERROR:
      return {
        ...state,
        isAddingIssues: false,
        errors: {
          ...state.errors,
          [constants.ADD_ISSUE]: action.payload.error.message
        }
      };

    case types.UPDATE_ISSUE_BEGIN:
      return {
        ...state,
        isUpdatingIssues: true
      };

    case types.UPDATE_ISSUE_COMPLETE:
      return {
        ...state,
        isUpdatingIssues: false
      };

    case types.UPDATE_ISSUE_ERROR:
      return {
        ...state,
        isUpdatingIssues: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_ISSUE]: action.payload.error.message
        }
      };

    case types.REMOVE_ISSUE_BEGIN:
      return {
        ...state,
        isRemovingIssues: true
      };

    case types.REMOVE_ISSUE_COMPLETE:
      return {
        ...state,
        isRemovingIssues: false
      };

    case types.REMOVE_ISSUE_ERROR:
      return {
        ...state,
        isRemovingIssues: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_ISSUE]: action.payload.error.message
        }
      };

    case types.CHANGE_STATUS_BEGIN:
      return {
        ...state,
        isChangingStatus: true
      };

    case types.CHANGE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingStatus: false
      };

    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isChangingStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_STATUS]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    default:
      return state;
  }
};

export default issuesReducer;
