import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { formValueSelector } from 'redux-form';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();
const formSelector = formValueSelector('issueForm');

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  issueType: (formSelector(state, 'issue_type') || {}).id,
  corporateFlag: (formSelector(state, 'corporate_flag') || {}).id,
  addressDistrict: (formSelector(state, 'address_district') || {}).id,
  addError: error(state[constants.NAME], constants.ADD_ISSUE),
  isAdding: loading(state[constants.NAME], constants.ADD_ISSUE),
  updateError: error(state[constants.NAME], constants.UPDATE_ISSUE),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_ISSUE),
  isFetching: loading(state[constants.NAME], constants.FETCH_ISSUE_DETAIL),
  issue: selectors.getIssueDetail(
    state[constants.NAME],
  ) || {},
  initialValues: !match.params.id ? {} : selectors.getIssueDetail(
    state[constants.NAME],
  ),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchIssueDetail({ id: params.id }));  
    }
  },
  onSubmit(issue) {
    issue.issue_type = issue.issue_type.id;
    issue.merchant_id = issue.merchant_id.id;

    if (issue.qpay_account_bank) {
      issue.qpay_account_bank = issue.qpay_account_bank.id;
    }

    if (issue.category_code) {
      issue.category_code = issue.category_code.id;
    }

    if (issue.corporate_flag) {
      issue.corporate_flag = issue.corporate_flag.id;
    }

    if (issue.checkout_account_bank) {
      issue.checkout_account_bank = issue.checkout_account_bank.id;
    }

    if (issue.address_district) {
      issue.address_district = issue.address_district.id;
    }

    if (issue.address_town) {
      issue.address_town = issue.address_town.id;
    }

    if (params.id) {
      dispatch(actions.updateIssue({ id: params.id, issue }));
    } else {
      dispatch(actions.addIssue({ issue }));
    }
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.ADD_ISSUE }));
    dispatch(actions.resetError({ name: constants.UPDATE_ISSUE }));
  },
  clearForm() {},
  goBackList() {
    dispatch(push('/issues'));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
