import { call, put, takeLatest } from 'redux-saga/effects';
import ServiceFactory from 'lib/serviceFactory';

import * as types from './actionTypes';
import * as constants from './constants';

const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  const __PLACEHOLDER_WATCHER__ = null;
}

export default [watchSagas()];