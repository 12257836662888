import pick from "lodash/pick";
import { createSelector } from "reselect";
import * as helpers from "./helpers";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.INIT:
      return state.isInitializing;

    case constants.LOGIN:
      return state.isLogging;

    case constants.LOGOUT:
      return state.isLoggingOut;

    case constants.SUGGEST_SHOP:
      return state.isSuggestingShop;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getCurrentUser = createSelector(
  [state => state.currentUser],
  currentUser => currentUser
);
export const getPaginationProps = (payload, search) => ({
  limit: parseInt(helpers.parseQueryString(search).limit || constants.LIMIT),
  currentPage: parseInt(helpers.parseQueryString(search).page || 1),
  pages: helpers.generatePagination({
    total: (
      payload || {
        totalCount: 0
      }
    ).totalCount,
    page: parseInt(helpers.parseQueryString(search).page || 1),
    limit: parseInt(helpers.parseQueryString(search).limit || constants.LIMIT)
  })
});
export const getSuggestedShops = createSelector(
  [state => state.suggestedShop],
  suggestedShop => suggestedShop
);
const __PLACEHOLDER__ = null; // ignore this.
