import React, { Fragment, useEffect } from "react";
import { Field } from "redux-form";
import core from "core";
import enhancers from "./enhancers";
const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;
export function ChangePassword({
  isEdit,
  handleSubmit,
  isSaving,
  saveError,
  resetErrors,
  clearForm,
  goBackList,
}) {
  useEffect(() => () => clearForm(), []);
  const onSubmit = useFormSubmit(
    isSaving,
    saveError,
    handleSubmit,
    resetErrors,
    goBackList,
  );

  return (
    <div className="container" >
      <div className="row">
        <div className="col-lg-12">
          <PageTitle title="Нууц үг солих" />
          <div className="card">
            <div className="card-body">
              {(isEdit && isSaving === undefined) || isSaving === true ? (
                <Loading />
              ) : (
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-xl-4">
                      <FormGroup required label="Одоогийн нууц үг">
                        <Field
                          required
                          name="currentPassword"
                          type="password"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Одоогийн нууц үг"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4">
                      <FormGroup required label="Шинэ нууц үг">
                        <Field
                          required
                          name="newPassword"
                          type="password"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Шинэ нууц үг"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4">
                      <FormGroup required label="Дахиж оруулах">
                        <Field
                          required
                          name="confirm"
                          type="password"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Дахиж оруулах"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <button className="btn btn-danger">Хадгалах</button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const ChangePasswordWithForm = enhancers.form(ChangePassword);
const ChangePasswordWithState = enhancers.redux(ChangePasswordWithForm);
export default ChangePasswordWithState;
