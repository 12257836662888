import * as types from './actionTypes';
const __PLACEHOLDER__ = null; // ignore this.
export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter
  }
});