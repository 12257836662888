import React from "react";
import moment from "moment";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";

function Table({
  rows = [],
  limit,
  currentPage,
  isRemoving,
  remove,
  currentUser,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 120 }}>Төлөв</th>
            <th>Купон нэр</th>
            <th>Купон код</th>
            <th style={{ width: 150 }}>Дуусах хугацаа</th>
            <th style={{ width: 150 }}>Үүсгэсэн огноо</th>
            <th style={{ width: 150 }}>Үлдэгдэл</th>
            <th style={{ width: 150 }}>Хямдралын хувь</th>
            <th style={{ width: 125 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "enabled",
                    "badge-danger": row.status === "disabled",
                  })}
                >
                  {row.status === "enabled" ? "Идэвхитэй" : "Идэвхигүй"}
                </span>
              </td>
              <td>{row.name}</td>
              <td>{row.coupon_code}</td>
              <td>{moment(row.expire_at).format("YYYY-MM-DD HH:mm")}</td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td>{row.coupon_remaining}</td>
              <td>{row.discount_percent}%</td>
              <td style={{ width: 125 }}>
                <Link to={`/campaigns/edit/${row.id}`} className="action-icon">
                  {" "}
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
                {currentUser.id !== row.id && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href="#"
                    className="action-icon"
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        if (!isRemoving) {
                          remove(row.id);
                        }
                      }
                    }}
                  >
                    <i className="mdi mdi-delete" />
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
