import core from "./core";
import shops from "./shops";
import bills from "./bills";
import users from "./users";
import issues from "./issues";
import admins from "./admins";
import orders from "./orders";
import products from "./products";
import domains from "./domains";
import channels from "./channels";
import customers from "./customers";
import dashboard from "./dashboard";
import activities from "./activities";
import settlements from "./settlements";
import notifications from "./notifications";
import transactions from "./transactions";
import payments from "./payments";
import contracts from "./contracts";
import modules from "./modules";
import campaigns from "./campaigns";
import ad_sellers from "./ad-sellers";
import withdrawals from "./withdrawals";
import ad_campaigns from "./ad-campaigns";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  core,
  shops,
  users,
  bills,
  admins,
  orders,
  issues,
  domains,
  products,
  channels,
  dashboard,
  customers,
  activities,
  settlements,
  notifications,
  transactions,
  withdrawals,
  payments,
  contracts,
  modules,
  campaigns,
  ad_sellers,
  ad_campaigns,
};
