import { reset } from "redux-form";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import core from "core";
import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { location: { search } }) => ({
  isFetching: loading(state[constants.NAME], constants.FETCH_MESSAGES),
  fetchResult: selectors.getMessages(state[constants.NAME]) || { list: [] },
  filter: selectors.getFilter(state[constants.NAME]),
  ...core.selectors.getPaginationProps(
    selectors.getMessages(state[constants.NAME]) || { totalCount: 0 },
    search
  ),
});
export const mapDispatchToProps = (dispatch) => ({
  list(page, filter) {
    dispatch(actions.fetchMessages({ options: { page }, filter }));
  },
  setFilter(filter) {
    dispatch(actions.setFilter({ filter }));
  },
  clearFilter() {
    dispatch(reset("messageFilterForm"));
    dispatch(actions.setFilter({ filter: null }));
  },
  updateQueryString(page) {
    dispatch(push(`/messages?page=${page}`));
  },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
