import React from 'react';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

import core from 'core';

function Filter({
  filter,
  clearFilter,
  handleSubmit,
}) {
  return (
    <div className="row mb-2">
      <div className="col-lg-10">
        <form
          className="form-inline"
          onSubmit={handleSubmit}
        >
          <div style={{ width: 150 }} className="form-group mr-2 mb-2">
            <Field
              name="shop"
              component={core.components.ShopSelector}
            />
          </div>
          <div className="form-group mb-2 mr-2">
            <Field
              component="input"
              type="text"
              className="form-control"
              id="product-name-field"
              placeholder="Нэр"
              name="name"
            />
          </div>
          <div style={{ width: 300 }} className="form-group mr-2 mb-2">
            <Field
              multi={true}
              name="channels"
              placeholder="Борлуулалтын суваг.."
              options={[
                { value: "supersale", label: "supersale.mn" },
                { value: "moodroom", label: "moodroom.mn" },
                { value: "hipay", label: "hipay" },
                { value: "monpay", label: "monpay" },
                { value: "socialpay", label: "socialpay" },
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div style={{ width: 300 }} className="form-group mr-2 mb-2">
            <Field
              multi={true}
              name="category_codes"
              placeholder="Мастер ангилал.."
              options={[
                { value: "A5", label: "Гоо сайхан" },
                { value: "A3", label: "Спорт" },
                { value: "A4", label: "Хүнс" },
                { value: "A9", label: "Бичиг хэрэг" },
                { value: "A2", label: "Эрэгтэй" },
                { value: "A0", label: "Гэр ахуй" },
                { value: "A1", label: "Эмэгтэй" },
                { value: "A7", label: "Үнэт эдлэл" },
                { value: "A6", label: "Хүүхдийн" },
                { value: "A8", label: "Электрон бараа" },
                { value: "A11", label: "Авто" },
                { value: "A12", label: "Хувцас" },
                { value: "A13", label: "Амьтан" },
                { value: "A15", label: "Хөгжим" },
                { value: "A14", label: "Эрүүл мэнд" },
                { value: "A16", label: "Үйлчилгээ" },
                { value: "A17", label: "Сургалт" },
                { value: "A18", label: "Цүнх" },
                { value: "A19", label: "Гутал" },
                { value: "A20", label: "Бусад"}
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div className="form-group mb-2">
            <button
              className="btn btn-primary text-bold"
            >
              ХАЙХ
            </button> {
              !!(filter && !isEqual(filter, {})) && <button
              onClick={() => clearFilter()}
              className="btn btn-danger ml-2 text-bold"
            >
              <i className="uil-times-square"></i>
            </button>}
          </div>
        </form>                            
      </div>

      <div className="col-lg-2 text-right">
        <a
          href="#"
          className="btn btn-primary mb-2 mr-2"
        >
          <i className="mdi mdi-plus-circle" />
        </a>
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'productCategoryFilterForm',
  enableReinitialize: true,
})(Filter);
