export const LIMIT = 50;
export const NAME = "core";
export const LOGIN = "login";
export const LOGIN_URL = "core/login";
export const LOGOUT = "logout";
export const LOGOUT_URL = "core/logout";
export const INIT = "init";
export const INIT_URL = "core/init";
export const SUGGEST_SHOP = "suggest_shop";
export const SUGGEST_SHOP_URL = "core/suggestShop";
const __PLACEHOLDER__ = null; // ignore this.
