export const RESET_ERROR = "@@contracts/RESET_ERROR";
export const SET_FILTER = "@@contracts/SET_FILTER";
export const FETCH_CONTRACTS_BEGIN = "@@contracts/FETCH_CONTRACTS_BEGIN";
export const FETCH_CONTRACTS_COMPLETE = "@@contracts/FETCH_CONTRACTS_COMPLETE";
export const FETCH_CONTRACTS_ERROR = "@@contracts/FETCH_CONTRACTS_ERROR";
export const FETCH_CONTRACT_DETAIL_BEGIN =
  "@@contracts/FETCH_CONTRACT_DETAIL_BEGIN";
export const FETCH_CONTRACT_DETAIL_COMPLETE =
  "@@contracts/FETCH_CONTRACT_DETAIL_COMPLETE";
export const FETCH_CONTRACT_DETAIL_ERROR =
  "@@contracts/FETCH_CONTRACT_DETAIL_ERROR";
export const CHANGE_STATUS_BEGIN = "@@contracts/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@contracts/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@contracts/CHANGE_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
