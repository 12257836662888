import React, { Component } from 'react';

import FetchUtil from 'lib/fetchUtil';
import parseDataURL from 'parse-data-url';

export class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.fetchUtil = FetchUtil.getInstance();
    this.state = {
      contentType: '',
      isUploading: false,
    };

    this._reader = new FileReader();
  }

  componentDidMount() {
    this._reader.onload = (event) => {
      const file = parseDataURL(event.target.result);
      this.setState({
        isUploading: true,
        contentType: file.contentType,
      });

      this.fetchUtil.sendPost(`/files/upload-image`, { image: file.data }).then(({ url }) =>{
        this.props.input.onChange(url);
        this.setState({ isUploading: false });
      }).catch(() => this.setState({ isUploading: false }));
    }
  }

  onFileChange(event) {
    if (event.target.files.length) {
      this._reader.readAsDataURL(event.target.files[0]);
    }
  }

  render() {
    return (
      <div className="form-control" >
        { this.state.isUploading && <span style={{ display: 'inline-block', marginRight: 10 }} className="spinner-border spinner-border-sm" /> }

        {
          this.props.input.value ? <span>
            <img
              src={this.props.input.value}
              style={{ height: 20, border: '1px solid #ddd' }}
            />

            <button
              type="button"
              onClick={() => this.props.input.onChange('')}
              className="btn btn-xs btn-danger"
              style={{ fontSize: 12, padding: '2px 5px', marginLeft: 5 }} 
            >
              <i className="uil-times-square" style={{ fontSize: 12 }} ></i>
            </button>
          </span> :         
          <input
            type="file"
            style={{ display: 'inline-block' }}
            required={this.props.required}
            onChange={this.onFileChange.bind(this)}
            disabled={this.state.isUploading || this.props.isSaving}
          />
        }
      </div>
    )
  }
}

export default FileUpload;
