import React, { Fragment, useEffect, useState } from "react";
import { Field } from "redux-form";

import core from "core";
import BarChart from "core/components/BarChart";

import enhancers from "./enhancers";
import Tabs from "./partials/tabs";
import Domains from "./partials/domains";
import Summary from "./partials/summary";
import UsersTable from "./partials/users-table";
import OptionsTable from "./partials/options-table";
import PaymentMethodTable from "./partials/pm-table";
import ModulesPanel from "./partials/modules";
import PlanAdjustion from "./partials/plan-adjuster";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;

export function ShopDetail({
  currentUser,
  isFetching,
  fetchShop,
  shop,
  addPM,
  addOption,
  isAddingOption,
  removePM,
  removeOption,
  isAddingPM,
  isRemovingPM,
  isRemovingOption,

  linkDomain,
  linkDomainError,
  isLinkingDomain,
  resetDomainError,
  checkDomain,
  removeDomain,
  isCheckingDomain,
  isRemovingDomain,
  isUpdatingBalance,
  isAdjustingPlanModule,

  shopHits,
  shopOrderStat,

  linkUserError,
  unlinkUserError,
  isLinkingUser,
  isUnlinkingUser,
  linkUser,
  unlinkUser,
  resetUserErrors,
  updateBalance,
  adjustPlanModule,
}) {
  useEffect(() => fetchShop(), []);

  useEffect(() => {
    if (isAddingOption === false) {
      fetchShop();
    }
  }, [isAddingOption]);

  useEffect(() => {
    if (isRemovingOption === false) {
      fetchShop();
    }
  }, [isRemovingOption]);

  useEffect(() => {
    if (isUpdatingBalance === false) {
      fetchShop();
    }
  }, [isUpdatingBalance]);

  useEffect(() => {
    if (isAdjustingPlanModule === false) {
      fetchShop();
    }
  }, [isAdjustingPlanModule]);

  useEffect(() => {
    if (isAddingPM === false) {
      fetchShop();
    }
  }, [isAddingPM]);

  useEffect(() => {
    if (isRemovingPM === false) {
      fetchShop();
    }
  }, [isRemovingPM]);

  useEffect(() => {
    if (isCheckingDomain === false) {
      fetchShop();
    }
  }, [isCheckingDomain]);

  useEffect(() => {
    if (isRemovingDomain === false) {
      fetchShop();
    }
  }, [isRemovingDomain]);

  useEffect(() => {
    if (isLinkingDomain === false) {
      if (linkDomainError) {
        alert(linkDomainError);
        resetDomainError();
      } else {
        fetchShop();
      }
    }

    return () => resetDomainError();
  }, [isLinkingDomain]);

  useEffect(() => {
    if (isLinkingUser === false) {
      if (linkUserError) {
        alert(linkUserError);
        resetUserErrors();
      } else {
        fetchShop();
      }
    }

    return () => resetDomainError();
  }, [isLinkingUser]);

  useEffect(() => {
    if (isUnlinkingUser === false) {
      if (unlinkUserError) {
        alert(unlinkUserError);
        resetUserErrors();
      } else {
        fetchShop();
      }
    }

    return () => resetDomainError();
  }, [isUnlinkingUser]);

  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Fragment>
      <PageTitle
        title={`Дэлгүүрийн дэлгэрэнгүй`}
        links={[{ url: "/shops", title: "Дэлгүүр" }]}
      />
      {isFetching === undefined || isFetching === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <Summary
              shop={shop}
              isAdjustingPlanModule={isAdjustingPlanModule}
              adjustPlanModule={adjustPlanModule}
            />
            {/* <PlanAdjustion
              plan={(shop || {}).plan}
              isAdjustingPlanModule={isAdjustingPlanModule}
              onSubmit={({ plan_uid, quantity }) => {
                if (window.confirm("Зөв оруулсан эсэхээ шалгана уу!!")) {
                  adjustPlanModule(shop.id, plan_uid.id, parseInt(quantity));
                }
              }}
            /> */}
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} />

                  <UsersTable
                    users={(shop || {}).users}
                    unlinkUser={(id) => unlinkUser(id, shop.id)}
                    linkUserError={linkUserError}
                    isLinkingUser={isLinkingUser}
                    isUnlinkingUser={isUnlinkingUser}
                    onSubmit={({ phone }) => {
                      if (phone) {
                        linkUser(shop.id, phone);
                      }
                    }}
                  />
                  <div
                    role="tabpanel"
                    id="shop-domain"
                    className="tab-pane fade"
                  />
                  <OptionsTable
                    options={(shop || {}).options}
                    onSubmit={({ key, value }) => {
                      addOption(shop.id, key.id, value);
                    }}
                    removeOption={(key) => removeOption(shop.id, key)}
                    isAddingOption={isAddingOption}
                  />
                  <PaymentMethodTable
                    removePM={(provider) => removePM(shop.id, provider)}
                    onSubmit={({
                      bank,
                      provider,
                      account_number,
                      account_holder,
                    }) => {
                      addPM(
                        shop.id,
                        bank,
                        provider.id,
                        account_number,
                        account_holder
                      );
                    }}
                    isAddingPM={isAddingPM}
                    paymentMethods={(shop || {}).payment_methods || []}
                  />
                  <Domains
                    checkDomain={checkDomain}
                    removeDomain={removeDomain}
                    isLinkingDomain={isLinkingDomain}
                    isRemovingDomain={isRemovingDomain}
                    isCheckingDomain={isCheckingDomain}
                    domains={(shop || {}).domains || []}
                    onSubmit={({ domain, theme }) => {
                      if (
                        window.confirm("Домэйн нэрээ зөв эсэхийг шалгана уу!!")
                      ) {
                        linkDomain(
                          shop.id,
                          domain,
                          (theme || {}).id || "default"
                        );
                      }
                    }}
                  />
                  <ModulesPanel
                    isUpdatingBalance={isUpdatingBalance}
                    merchantModules={(shop || {}).merchant_module || {}}
                    onSubmit={({ balance }) => {
                      if (window.confirm("Зөв оруулсан эсэхээ шалгана уу!!")) {
                        updateBalance(shop.id, parseInt(balance));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {currentUser.username === "ganbold" && (
              <BarChart data={shopOrderStat} title="Захиалгын статистик" />
            )}
            <BarChart
              data={shopHits}
              hideChooser={true}
              defaultDataKey="total_count"
              title="Хандалтын статистик"
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

const ShopDetailWithState = enhancers.redux(ShopDetail);
export default ShopDetailWithState;
