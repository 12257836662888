import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";

export function* fetchBills({ payload: { filter, options } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.FETCH_BILLS_URL, {
      filter,
      options
    });
    yield put({
      type: types.FETCH_BILLS_COMPLETE,
      payload: {
        bills: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_BILLS_ERROR,
      payload: {
        error
      }
    });
  }
}

export function* fetchBillDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_BILL_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_BILL_DETAIL_COMPLETE,
      payload: {
        billDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_BILL_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}

export function* changeBillStatus({ payload: { id, status } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_BILL_STATUS_URL,
      {
        id,
        status
      }
    );
    yield put({
      type: types.CHANGE_BILL_STATUS_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_BILL_STATUS_ERROR,
      payload: {
        error
      }
    });
  }
}

const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_BILLS_BEGIN, fetchBills);
  yield takeLatest(types.FETCH_BILL_DETAIL_BEGIN, fetchBillDetail);
  yield takeLatest(types.CHANGE_BILL_STATUS_BEGIN, changeBillStatus);
  const __PLACEHOLDER_WATCHER__ = null;
}

export default [watchSagas()];
