import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect, useState } from "react";

import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function CategoryList({
  isFetching,
  limit,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  fetchCategories,
  pages,
  updateQueryString,
}) {
  // const [isAssignModalOpen, setAssignModalOpen] = useState(false);

  useEffect(() => {
    fetchCategories(currentPage, filter);
  }, [currentPage, filter]);

  return (
    <Fragment>
      <PageTitle title="Ангилалын жагсаалт" />
      <div className="card">
        <div className="card-body">
          <Filter
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["name", "sku", "price"]);

                if (formData.status) {
                  theFilter.status = formData.status.id;
                }

                if (Array.isArray(formData.channels) && formData.channels.length) {
                  theFilter.channels = `"${(formData.channels).map(ch => ch.id).join('","')}"`;
                }

                if (Array.isArray(formData.category_codes) && formData.category_codes.length) {
                  theFilter.category_codes = `"${(formData.category_codes).map(ch => ch.id).join('","')}"`;
                }

                if (formData.shop) {
                  theFilter.shop_id = formData.shop.id;
                }

                if (formData.start) {
                  theFilter.start = formData.start.toISOString();
                }

                if (formData.end) {
                  theFilter.end = formData.end.toISOString();
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <>
              <Table
                limit={limit}
                rows={fetchResult.list}
                currentPage={currentPage}
              />
              <Pagination
                url="/product-categories"
                pages={pages}
                currentPage={currentPage}
              />
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const CategoryListWithState = enhancers.redux(CategoryList);
export default CategoryListWithState;
