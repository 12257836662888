import screens from "./screens";

export default [
  {
    path: true,
    path: "/admins",
    component: screens.AdminList,
  },
  {
    path: true,
    path: "/admins/new",
    component: screens.AdminForm,
  },
  {
    path: true,
    path: "/admins/newManager",
    component: screens.ManagerForm,
  },
  {
    path: true,
    path: "/admins/edit/:id",
    component: screens.AdminForm,
  },
];
