import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MESSAGES_BEGIN:
      return {
        ...state,
        isFetchingMessages: true,
      };

    case types.FETCH_MESSAGES_COMPLETE:
      return {
        ...state,
        isFetchingMessages: false,
        messages: action.payload.messages,
      };

    case types.FETCH_MESSAGES_ERROR:
      return {
        ...state,
        isFetchingMessages: false,
        errors: {
          ...state.errors,
          [constants.FETCH_MESSAGES]: action.payload.error.message,
        },
      };

    case types.FETCH_MESSAGE_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingMessageDetail: true,
      };

    case types.FETCH_MESSAGE_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingMessageDetail: false,
        messageDetail: action.payload.messageDetail,
      };

    case types.FETCH_MESSAGE_DETAIL_ERROR:
      return {
        ...state,
        isFetchingMessageDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_MESSAGE_DETAIL]: action.payload.error.message,
        },
      };

    case types.CHANGE_STATUS_BEGIN:
      return {
        ...state,
        isChangingStatus: true,
      };

    case types.CHANGE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingStatus: false,
      };

    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isChangingStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_STATUS]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default messagesReducer;
