import React from "react";
import classNames from 'classnames';

function OrderSummary({ order, sendOrder, shopIds }) {
  return <div className="card text-center">
    <div className="card-body">
      <h4 className="mb-0 mt-2">
        
      </h4>
      {/* <p
        className="font-14 mt-1"
      >
        <span className={classNames('badge p-1', {
          'badge-success': order.status === 'done',
          'badge-warning': order.status === 'pending',
          'badge-info': order.status === 'in_progress',
        })}>
          { StatusMap[order.status] }
        </span>  
        
      </p> */}
      <div className="text-left mt-3">
        <h4 className="font-13 text-uppercase">
          Зурвас
        </h4>
        <p>test</p>
      </div>
    </div>
  </div>
}

const StatusMap = {
  done: 'Илгээсэн',
  pending: 'Хүлээгдэж буй',
  in_progress: 'Илгээж буй',
};

export default OrderSummary;

