import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.FETCH_CHANNELS:
      return state.isFetchingChannels;
      break;

    case constants.FETCH_CHANNEL_DETAIL:
      return state.isFetchingChannelDetail;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getChannels = createSelector(
  [(state) => state.channels],
  (channels) => channels
);
export const getChannelDetail = createSelector(
  [(state) => state.channelDetail],
  (channelDetail) => channelDetail
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
