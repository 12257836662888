import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PRODUCTS_BEGIN:
      return {
        ...state,
        product: [],
        isFetchingProducts: true
      };

    case types.FETCH_PRODUCTS_COMPLETE:
      return {
        ...state,
        isFetchingProducts: false,
        products: action.payload.products
      };

    case types.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        product: [],
        isFetchingProducts: false,
        errors: {
          ...state.errors,
          [constants.FETCH_PRODUCTS]: action.payload.error.message
        }
      };

    case types.FETCH_PRODUCT_CATEGORIES_BEGIN:
      return {
        ...state,
        categories: [],
        isFetchingProductCategories: true
      };

    case types.FETCH_PRODUCT_CATEGORIES_COMPLETE:
      return {
        ...state,
        isFetchingProductCategories: false,
        categories: action.payload.categories
      };

    case types.FETCH_PRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        categories: [],
        isFetchingProductCategories: false,
        errors: {
          ...state.errors,
          [constants.FETCH_PRODUCT_CATEGORIES]: action.payload.error.message
        }
      };

    case types.DISABLE_PRODUCT_BEGIN:
      return {
        ...state,
        isDisablingProduct: true
      };

    case types.DISABLE_PRODUCT_COMPLETE:
      return {
        ...state,
        isDisablingProduct: false
      };

    case types.DISABLE_PRODUCT_ERROR:
      return {
        ...state,
        product: [],
        isDisablingProduct: false,
        errors: {
          ...state.errors,
          [constants.DISABLE_PRODUCT]: action.payload.error.message
        }
      };

    case types.ASSIGN_MAIN_CATEGORY_BEGIN:
      return {
        ...state,
        isAssigningCategory: true
      };

    case types.ASSIGN_MAIN_CATEGORY_COMPLETE:
      return {
        ...state,
        isAssigningCategory: false
      };

    case types.ASSIGN_MAIN_CATEGORY_ERROR:
      return {
        ...state,
        product: [],
        isAssigningCategory: false,
        errors: {
          ...state.errors,
          [constants.ASSIGN_MAIN_CATEGORY]: action.payload.error.message
        }
      };

    case types.ASSIGN_MAIN_ATTRIBUTE_BEGIN:
      return {
        ...state,
        isAssigningAttribute: true
      };

    case types.ASSIGN_MAIN_ATTRIBUTE_COMPLETE:
      return {
        ...state,
        isAssigningAttribute: false
      };

    case types.ASSIGN_MAIN_ATTRIBUTE_ERROR:
      return {
        ...state,
        product: [],
        isAssigningAttribute: false,
        errors: {
          ...state.errors,
          [constants.ASSIGN_MAIN_CATEGORY]: action.payload.error.message
        }
      };

    case types.ADD_PRODUCT_BEGIN:
      return {
        ...state,
        isAddingProduct: true
      };

    case types.ADD_PRODUCT_COMPLETE:
      return {
        ...state,
        isAddingProduct: false
      };

    case types.ADD_PRODUCT_ERROR:
      return {
        ...state,
        isAddingProduct: false,
        errors: {
          ...state.errors,
          [constants.ADD_PRODUCT]: action.payload.error.message
        }
      };

    case types.REMOVE_PRODUCT_BEGIN:
      return {
        ...state,
        isRemovingProduct: true
      };

    case types.REMOVE_PRODUCT_COMPLETE:
      return {
        ...state,
        isRemovingProduct: false
      };

    case types.REMOVE_PRODUCT_ERROR:
      return {
        ...state,
        isRemovingProduct: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_PRODUCT]: action.payload.error.message
        }
      };

    case types.UPDATE_PRODUCT_BEGIN:
      return {
        ...state,
        isUpdatingProduct: true
      };

    case types.UPDATE_PRODUCT_COMPLETE:
      return {
        ...state,
        isUpdatingProduct: false
      };

    case types.UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        isUpdatingProduct: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_PRODUCT]: action.payload.error.message
        }
      };

    case types.FETCH_PRODUCT_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingProductDetail: true
      };

    case types.FETCH_PRODUCT_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingProductDetail: false,
        productDetail: action.payload.productDetail
      };

    case types.FETCH_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isFetchingProductDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_PRODUCT_DETAIL]: action.payload.error.message
        }
      };

    case types.FETCH_CATEGORIES_BEGIN:
      return {
        ...state,
        isFetchingCategories: true
      };

    case types.FETCH_CATEGORIES_COMPLETE:
      return {
        ...state,
        isFetchingCategories: false,
        categories: action.payload.categories
      };

    case types.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingCategories: false,
        errors: {
          ...state.errors,
          [constants.FETCH_CATEGORIES]: action.payload.error.message
        }
      };

    case types.FETCH_CATEGORY_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingCategoryDetail: true
      };

    case types.FETCH_CATEGORY_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingCategoryDetail: false,
        categoryDetail: action.payload.categoryDetail
      };

    case types.FETCH_CATEGORY_DETAIL_ERROR:
      return {
        ...state,
        isFetchingCategoryDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_CATEGORY_DETAIL]: action.payload.error.message
        }
      };

    case types.UPDATE_CATEGORY_BEGIN:
      return {
        ...state,
        isUpdatingCategory: true
      };

    case types.UPDATE_CATEGORY_COMPLETE:
      return {
        ...state,
        isUpdatingCategory: false
      };

    case types.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        isUpdatingCategory: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_CATEGORY]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    default:
      return state;
  }
};

export default productsReducer;
