export const NAME = "admins";
export const FETCH_ADMINS = "fetch_admins";
export const FETCH_ADMINS_URL = "admins/fetchAdmins";
export const FETCH_ADMIN_DETAIL = "fetch_admin_detail";
export const FETCH_ADMIN_DETAIL_URL = "admins/fetchAdminDetail";
export const ADD_ADMIN = "add_admin";
export const ADD_ADMIN_URL = "admins/addAdmin";
export const ADD_ROLES = "add_roles";
export const ADD_ROLES_URL = "admins/addRoles";
export const ADD_MANAGER = "add_manager";
export const ADD_MANAGER_URL = "admins/addManager";
export const UPDATE_ADMIN = "update_admin";
export const UPDATE_ADMIN_URL = "admins/updateAdmin";
export const REMOVE_ADMIN = "remove_admin";
export const REMOVE_ADMIN_URL = "admins/removeAdmin";
