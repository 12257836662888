export default class AdSellerService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchAdSellers({ filter, options }) {
    const { data: adSellers, total: totalCount } = await this.fetchUtil.sendGet(
      `/ad_sellers${this.fetchUtil.qs({ filter, options })}`
    );
    return { adSellers, totalCount };
  }

  async fetchAdCampaignDetail({ id }) {
    const { data: adSeller, total: totalCount } = await this.fetchUtil.sendGet(
      `/ad_sellers/${id}`
    );
    return { adSeller, totalCount };
  }
}
