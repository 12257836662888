import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import core from "core";

function Table({
  rows = [],
  limit,
  currentPage,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 20 }} />
            <th style={{ width: 250 }} >Дэлгүүр</th>
            <th>Нэр</th>
            <th  style={{ width: 150 }}>Мастер ангилал</th>
            <th  style={{ width: 150 }}>Б.Суваг</th>
            <th style={{ width: 150 }}>Огноо</th>
            <th style={{ width: 60 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td style={{ paddingRight: 0, paddingLeft: 8,  }} >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                  />
                </div>
              </td>
              <td>{row.shop_name}</td>
              <td>{row.name}</td>
              <td>
                { (row.main_category_codes || []).length === 0 && '-' } 
                { (row.main_category_codes || []).length > 0 && (row.main_category_codes || []).map(code => CategoryMap[code]).join(", ") }
              </td>
              <td>
                { row.sale_channels.length === 0  && "-" }
                { (row.sale_channels || []).join(", ") }
              </td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td style={{ width: 60 }}>
                <Link className="action-icon" to={`/product-categories/edit/${row.id}?page=${currentPage}`}>
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


const CategoryMap = {
  "A5": "Гоо сайхан",
  "A3": "Спорт",
  "A4": "Хүнс",
  "A9": "Бичиг хэрэг",
  "A2": "Эрэгтэй",
  "A0": "Гэр ахуй",
  "A1": "Эмэгтэй",
  "A7": "Үнэт эдлэл",
  "A6": "Хүүхдийн",
  "A8": "Электрон бараа",
  "A11": "Авто",
  "A12": "Хувцас",
  "A13": "Амьтан",
  "A15": "Хөгжим",
  "A14": "Эрүүл мэнд",
  "A16": "Үйлчилгээ ",
  "A17": "Сургалт ",
  "A18": "Цүнх ",
  "A19": "Гутал ",
  "A20": "Бусад "
};

export default Table;
