export const RESET_ERROR = "@@notifications/RESET_ERROR";
export const SET_FILTER = "@@notifications/SET_FILTER";
export const SET_SHOP_FILTER = "@@notifications/SET_SHOP_FILTER";
export const SET_SHOP_IDS = "@@notifications/SET_SHOP_IDS";
export const FETCH_BROADCASTS_BEGIN = "@@notifications/FETCH_BROADCASTS_BEGIN";
export const FETCH_BROADCASTS_COMPLETE =
  "@@notifications/FETCH_BROADCASTS_COMPLETE";
export const FETCH_BROADCASTS_ERROR = "@@notifications/FETCH_BROADCASTS_ERROR";
export const FETCH_BROADCAST_DETAIL_BEGIN =
  "@@notifications/FETCH_BROADCAST_DETAIL_BEGIN";
export const FETCH_BROADCAST_DETAIL_COMPLETE =
  "@@notifications/FETCH_BROADCAST_DETAIL_COMPLETE";
export const FETCH_BROADCAST_DETAIL_ERROR =
  "@@notifications/FETCH_BROADCAST_DETAIL_ERROR";
export const ADD_BROADCAST_BEGIN = "@@notifications/ADD_BROADCAST_BEGIN";
export const ADD_BROADCAST_COMPLETE = "@@notifications/ADD_BROADCAST_COMPLETE";
export const ADD_BROADCAST_ERROR = "@@notifications/ADD_BROADCAST_ERROR";
export const UPDATE_BROADCAST_BEGIN = "@@notifications/UPDATE_BROADCAST_BEGIN";
export const UPDATE_BROADCAST_COMPLETE =
  "@@notifications/UPDATE_BROADCAST_COMPLETE";
export const UPDATE_BROADCAST_ERROR = "@@notifications/UPDATE_BROADCAST_ERROR";
export const REMOVE_BROADCAST_BEGIN = "@@notifications/REMOVE_BROADCAST_BEGIN";
export const REMOVE_BROADCAST_COMPLETE =
  "@@notifications/REMOVE_BROADCAST_COMPLETE";
export const REMOVE_BROADCAST_ERROR = "@@notifications/REMOVE_BROADCAST_ERROR";
export const CLEAR_CREATE_UPDATE_ID = "@@notifications/CLEAR_CREATE_UPDATE_ID";
export const SEND_BROADCAST_BEGIN = "@@notifications/SEND_BROADCAST_BEGIN";
export const SEND_BROADCAST_COMPLETE =
  "@@notifications/SEND_BROADCAST_COMPLETE";
export const SEND_BROADCAST_ERROR = "@@notifications/SEND_BROADCAST_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
