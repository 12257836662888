export const TOWNS = [
  {
    value: "15782385",
    districtId: "1",
    label: "1-р хороо",
    labelNo: 1,
  },
  {
    value: "15782836",
    districtId: "1",
    label: "10-р хороо",
    labelNo: 10,
  },
  {
    value: "15782837",
    districtId: "1",
    label: "11-р хороо",
    labelNo: 11,
  },
  {
    value: "15782838",
    districtId: "1",
    label: "12-р хороо",
    labelNo: 12,
  },
  {
    value: "15782839",
    districtId: "1",
    label: "13-р хороо",
    labelNo: 13,
  },
  {
    value: "15782840",
    districtId: "1",
    label: "14-р хороо",
    labelNo: 14,
  },
  {
    value: "15782841",
    districtId: "1",
    label: "15-р хороо",
    labelNo: 15,
  },
  {
    value: "15782842",
    districtId: "1",
    label: "16-р хороо",
    labelNo: 16,
  },
  {
    value: "15782843",
    districtId: "1",
    label: "17-р хороо",
    labelNo: 17,
  },
  {
    value: "15782844",
    districtId: "1",
    label: "18-р хороо",
    labelNo: 18,
  },
  {
    value: "15782845",
    districtId: "1",
    label: "19-р хороо",
    labelNo: 19,
  },
  {
    value: "15782387",
    districtId: "1",
    label: "2-р хороо",
    labelNo: 2,
  },
  {
    value: "15782846",
    districtId: "1",
    label: "20-р хороо",
    labelNo: 20,
  },
  {
    value: "15782847",
    districtId: "1",
    label: "21-р хороо",
    labelNo: 21,
  },
  {
    value: "15782848",
    districtId: "1",
    label: "22-р хороо",
    labelNo: 22,
  },
  {
    value: "15782849",
    districtId: "1",
    label: "23-р хороо",
    labelNo: 23,
  },
  {
    value: "15782850",
    districtId: "1",
    label: "24-р хороо",
    labelNo: 24,
  },
  {
    value: "15782851",
    districtId: "1",
    label: "25-р хороо",
    labelNo: 25,
  },
  {
    value: "15782386",
    districtId: "1",
    label: "26-р хороо",
    labelNo: 26,
  },
  {
    value: "15782823",
    districtId: "1",
    label: "27-р хороо",
    labelNo: 27,
  },
  {
    value: "15782825",
    districtId: "1",
    label: "28-р хороо",
    labelNo: 28,
  },
  {
    value: "15782827",
    districtId: "1",
    label: "29-р хороо",
    labelNo: 29,
  },
  {
    value: "15782824",
    districtId: "1",
    label: "3-р хороо",
    labelNo: 3,
  },
  {
    value: "15782829",
    districtId: "1",
    label: "30-р хороо",
    labelNo: 30,
  },
  {
    value: "15782831",
    districtId: "1",
    label: "31-р хороо",
    labelNo: 31,
  },
  {
    value: "15782833",
    districtId: "1",
    label: "32-р хороо",
    labelNo: 32,
  },
  {
    value: "15782852",
    districtId: "1",
    label: "33-р хороо",
    labelNo: 33,
  },
  {
    value: "15782853",
    districtId: "1",
    label: "34-р хороо",
    labelNo: 34,
  },
  {
    value: "15782854",
    districtId: "1",
    label: "35-р хороо",
    labelNo: 35,
  },
  {
    value: "15782855",
    districtId: "1",
    label: "36-р хороо",
    labelNo: 36,
  },
  {
    value: "15782856",
    districtId: "1",
    label: "37-р хороо",
    labelNo: 37,
  },
  {
    value: "15782857",
    districtId: "1",
    label: "38-р хороо",
    labelNo: 38,
  },
  {
    value: "15782858",
    districtId: "1",
    label: "39-р хороо",
    labelNo: 39,
  },
  {
    value: "15782826",
    districtId: "1",
    label: "4-р хороо",
    labelNo: 4,
  },
  {
    value: "15782859",
    districtId: "1",
    label: "40-р хороо",
    labelNo: 40,
  },
  {
    value: "15782860",
    districtId: "1",
    label: "41-р хороо",
    labelNo: 41,
  },
  {
    value: "15782861",
    districtId: "1",
    label: "42-р хороо",
    labelNo: 42,
  },
  {
    value: "15782862",
    districtId: "1",
    label: "43-р хороо",
    labelNo: 43,
  },
  {
    value: "15782828",
    districtId: "1",
    label: "5-р хороо",
    labelNo: 5,
  },
  {
    value: "15782830",
    districtId: "1",
    label: "6-р хороо",
    labelNo: 6,
  },
  {
    value: "15782832",
    districtId: "1",
    label: "7-р хороо",
    labelNo: 7,
  },
  {
    value: "15782834",
    districtId: "1",
    label: "8-р хороо",
    labelNo: 8,
  },
  {
    value: "15782835",
    districtId: "1",
    label: "9-р хороо",
    labelNo: 9,
  },
  {
    value: "15782324",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "5",
  },
  {
    value: "15782333",
    label: "10-р хороо",
    labelNo: 10,
    districtId: "5",
  },
  {
    value: "15782334",
    label: "11-р хороо",
    labelNo: 11,
    districtId: "5",
  },
  {
    value: "15782335",
    label: "12-р хороо",
    labelNo: 12,
    districtId: "5",
  },
  {
    value: "15782336",
    label: "13-р хороо",
    labelNo: 13,
    districtId: "5",
  },
  {
    value: "15782337",
    label: "14-р хороо",
    labelNo: 14,
    districtId: "5",
  },
  {
    value: "15782338",
    label: "15-р хороо",
    labelNo: 15,
    districtId: "5",
  },
  {
    value: "15782339",
    label: "16-р хороо",
    labelNo: 16,
    districtId: "5",
  },
  {
    value: "15782340",
    label: "17-р хороо",
    labelNo: 17,
    districtId: "5",
  },
  {
    value: "15782341",
    label: "18-р хороо",
    labelNo: 18,
    districtId: "5",
  },
  {
    value: "15782342",
    label: "19-р хороо",
    labelNo: 19,
    districtId: "5",
  },
  {
    value: "15782325",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "5",
  },
  {
    value: "15782343",
    label: "20-р хороо",
    labelNo: 20,
    districtId: "5",
  },
  {
    value: "15782344",
    label: "21-р хороо",
    labelNo: 21,
    districtId: "5",
  },
  {
    value: "15782345",
    label: "22-р хороо",
    labelNo: 22,
    districtId: "5",
  },
  {
    value: "15782346",
    label: "23-р хороо",
    labelNo: 23,
    districtId: "5",
  },
  {
    value: "15782347",
    label: "24-р хороо",
    labelNo: 24,
    districtId: "5",
  },
  {
    value: "15782348",
    label: "25-р хороо",
    labelNo: 25,
    districtId: "5",
  },
  {
    value: "15782938",
    label: "26-р хороо",
    labelNo: 26,
    districtId: "5",
  },
  {
    value: "15782939",
    label: "27-р хороо",
    labelNo: 27,
    districtId: "5",
  },
  {
    value: "15782940",
    label: "28-р хороо",
    labelNo: 28,
    districtId: "5",
  },
  {
    value: "15782941",
    label: "29-р хороо",
    labelNo: 29,
    districtId: "5",
  },
  {
    value: "15782326",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "5",
  },
  {
    value: "15782942",
    label: "30-р хороо",
    labelNo: 30,
    districtId: "5",
  },
  {
    value: "15782943",
    label: "31-р хороо",
    labelNo: 31,
    districtId: "5",
  },
  {
    value: "15782944",
    label: "32-р хороо",
    labelNo: 32,
    districtId: "5",
  },
  {
    value: "15782945",
    label: "33-р хороо",
    labelNo: 33,
    districtId: "5",
  },
  {
    value: "15782946",
    label: "34-р хороо",
    labelNo: 34,
    districtId: "5",
  },
  {
    value: "15782327",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "5",
  },
  {
    value: "15782328",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "5",
  },
  {
    value: "15782329",
    label: "6-р хороо",
    labelNo: 6,
    districtId: "5",
  },
  {
    value: "15782330",
    label: "7-р хороо",
    labelNo: 7,
    districtId: "5",
  },
  {
    value: "15782331",
    label: "8-р хороо",
    labelNo: 8,
    districtId: "5",
  },
  {
    value: "15782332",
    label: "9-р хороо",
    labelNo: 9,
    districtId: "5",
  },
  {
    khorooId: "15782349",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "7",
  },
  {
    khorooId: "15782361",
    label: "10-р хороо",
    labelNo: 10,
    districtId: "7",
  },
  {
    khorooId: "15782362",
    label: "11-р хороо",
    labelNo: 11,
    districtId: "7",
  },
  {
    khorooId: "15782363",
    label: "12-р хороо",
    labelNo: 12,
    districtId: "7",
  },
  {
    khorooId: "15782364",
    label: "13-р хороо",
    labelNo: 13,
    districtId: "7",
  },
  {
    khorooId: "15782365",
    label: "14-р хороо",
    labelNo: 14,
    districtId: "7",
  },
  {
    khorooId: "15782366",
    label: "15-р хороо",
    labelNo: 15,
    districtId: "7",
  },
  {
    khorooId: "15782367",
    label: "16-р хороо",
    labelNo: 16,
    districtId: "7",
  },
  {
    khorooId: "15782368",
    label: "17-р хороо",
    labelNo: 17,
    districtId: "7",
  },
  {
    khorooId: "15782369",
    label: "18-р хороо",
    labelNo: 18,
    districtId: "7",
  },
  {
    khorooId: "15782370",
    label: "19-р хороо",
    labelNo: 19,
    districtId: "7",
  },
  {
    khorooId: "15782351",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "7",
  },
  {
    khorooId: "15782371",
    label: "20-р хороо",
    labelNo: 20,
    districtId: "7",
  },
  {
    khorooId: "15782372",
    label: "21-р хороо",
    labelNo: 21,
    districtId: "7",
  },
  {
    khorooId: "15782373",
    label: "22-р хороо",
    labelNo: 22,
    districtId: "7",
  },
  {
    khorooId: "15782374",
    label: "23-р хороо",
    labelNo: 23,
    districtId: "7",
  },
  {
    khorooId: "15782375",
    label: "24-р хороо",
    labelNo: 24,
    districtId: "7",
  },
  {
    khorooId: "15782376",
    label: "25-р хороо",
    labelNo: 25,
    districtId: "7",
  },
  {
    khorooId: "15782350",
    label: "26-р хороо",
    labelNo: 26,
    districtId: "7",
  },
  {
    khorooId: "15782352",
    label: "27-р хороо",
    labelNo: 27,
    districtId: "7",
  },
  {
    khorooId: "15782354",
    label: "28-р хороо",
    labelNo: 28,
    districtId: "7",
  },
  {
    khorooId: "15782923",
    label: "29-р хороо",
    labelNo: 29,
    districtId: "7",
  },
  {
    khorooId: "15782353",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "7",
  },
  {
    khorooId: "15782924",
    label: "30-р хороо",
    labelNo: 30,
    districtId: "7",
  },
  {
    khorooId: "15782925",
    label: "31-р хороо",
    labelNo: 31,
    districtId: "7",
  },
  {
    khorooId: "15782926",
    label: "32-р хороо",
    labelNo: 32,
    districtId: "7",
  },
  {
    khorooId: "15782927",
    label: "33-р хороо",
    labelNo: 33,
    districtId: "7",
  },
  {
    khorooId: "15782928",
    label: "34-р хороо",
    labelNo: 34,
    districtId: "7",
  },
  {
    khorooId: "15782929",
    label: "35-р хороо",
    labelNo: 35,
    districtId: "7",
  },
  {
    khorooId: "15782930",
    label: "36-р хороо",
    labelNo: 36,
    districtId: "7",
  },
  {
    khorooId: "15782931",
    label: "37-р хороо",
    labelNo: 37,
    districtId: "7",
  },
  {
    khorooId: "15782932",
    label: "38-р хороо",
    labelNo: 38,
    districtId: "7",
  },
  {
    khorooId: "15782933",
    label: "39-р хороо",
    labelNo: 39,
    districtId: "7",
  },
  {
    khorooId: "15782355",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "7",
  },
  {
    khorooId: "15782934",
    label: "40-р хороо",
    labelNo: 40,
    districtId: "7",
  },
  {
    khorooId: "15782935",
    label: "41-р хороо",
    labelNo: 41,
    districtId: "7",
  },
  {
    khorooId: "15782936",
    label: "42-р хороо",
    labelNo: 42,
    districtId: "7",
  },
  {
    khorooId: "15782937",
    label: "43-р хороо",
    labelNo: 43,
    districtId: "7",
  },
  {
    khorooId: "15782356",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "7",
  },
  {
    khorooId: "15782357",
    label: "6-р хороо",
    labelNo: 6,
    districtId: "7",
  },
  {
    khorooId: "15782358",
    label: "7-р хороо",
    labelNo: 7,
    districtId: "7",
  },
  {
    khorooId: "15782359",
    label: "8-р хороо",
    labelNo: 8,
    districtId: "7",
  },
  {
    khorooId: "15782360",
    label: "9-р хороо",
    labelNo: 9,
    districtId: "7",
  },
  {
    value: "15782863",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "4",
  },
  {
    value: "15782872",
    label: "10-р хороо",
    labelNo: 10,
    districtId: "4",
  },
  {
    value: "15782873",
    label: "11-р хороо",
    labelNo: 11,
    districtId: "4",
  },
  {
    value: "15782874",
    label: "12-р хороо",
    labelNo: 12,
    districtId: "4",
  },
  {
    value: "15782875",
    label: "13-р хороо",
    labelNo: 13,
    districtId: "4",
  },
  {
    value: "15782876",
    label: "14-р хороо",
    labelNo: 14,
    districtId: "4",
  },
  {
    value: "15782877",
    label: "15-р хороо",
    labelNo: 15,
    districtId: "4",
  },
  {
    value: "15782878",
    label: "16-р хороо",
    labelNo: 16,
    districtId: "4",
  },
  {
    value: "15782879",
    label: "17-р хороо",
    labelNo: 17,
    districtId: "4",
  },
  {
    value: "15782880",
    label: "18-р хороо",
    labelNo: 18,
    districtId: "4",
  },
  {
    value: "15782881",
    label: "19-р хороо",
    labelNo: 19,
    districtId: "4",
  },
  {
    value: "15782864",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "4",
  },
  {
    value: "15782882",
    label: "20-р хороо",
    labelNo: 20,
    districtId: "4",
  },
  {
    value: "15782865",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "4",
  },
  {
    value: "15782866",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "4",
  },
  {
    value: "15782867",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "4",
  },
  {
    value: "15782868",
    label: "6-р хороо",
    labelNo: 6,
    districtId: "4",
  },
  {
    value: "15782869",
    label: "7-р хороо",
    labelNo: 7,
    districtId: "4",
  },
  {
    value: "15782870",
    label: "8-р хороо",
    labelNo: 8,
    districtId: "4",
  },
  {
    value: "15782871",
    label: "9-р хороо",
    labelNo: 9,
    districtId: "4",
  },
  {
    value: "15782904",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "6",
  },
  {
    value: "15782913",
    label: "10-р хороо",
    labelNo: 10,
    districtId: "6",
  },
  {
    value: "15782914",
    label: "11-р хороо",
    labelNo: 11,
    districtId: "6",
  },
  {
    value: "15782915",
    label: "12-р хороо",
    labelNo: 12,
    districtId: "6",
  },
  {
    value: "15782916",
    label: "13-р хороо",
    labelNo: 13,
    districtId: "6",
  },
  {
    value: "15782917",
    label: "14-р хороо",
    labelNo: 14,
    districtId: "6",
  },
  {
    value: "15782918",
    label: "15-р хороо",
    labelNo: 15,
    districtId: "6",
  },
  {
    value: "15782919",
    label: "16-р хороо",
    labelNo: 16,
    districtId: "6",
  },
  {
    value: "15782920",
    label: "17-р хороо",
    labelNo: 17,
    districtId: "6",
  },
  {
    value: "15782921",
    label: "18-р хороо",
    labelNo: 18,
    districtId: "6",
  },
  {
    value: "15782922",
    label: "19-р хороо",
    labelNo: 19,
    districtId: "6",
  },
  {
    value: "15782905",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "6",
  },
  {
    value: "15782947",
    label: "20-р хороо",
    labelNo: 20,
    districtId: "6",
  },
  {
    value: "15782948",
    label: "21-р хороо",
    labelNo: 21,
    districtId: "6",
  },
  {
    value: "15782949",
    label: "22-р хороо",
    labelNo: 22,
    districtId: "6",
  },
  {
    value: "15782906",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "6",
  },
  {
    value: "15782907",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "6",
  },
  {
    value: "15782908",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "6",
  },
  {
    value: "15782909",
    label: "6-р хороо",
    labelNo: 6,
    districtId: "6",
  },
  {
    value: "15782910",
    label: "7-р хороо",
    labelNo: 7,
    districtId: "6",
  },
  {
    value: "15782911",
    label: "8-р хороо",
    labelNo: 8,
    districtId: "6",
  },
  {
    value: "15782912",
    label: "9-р хороо",
    labelNo: 9,
    districtId: "6",
  },
  {
    value: "15782377",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "2",
  },
  {
    value: "15782378",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "2",
  },
  {
    value: "15782379",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "2",
  },
  {
    value: "15782380",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "2",
  },
  {
    value: "15782381",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "2",
  },
  {
    value: "15782382",
    label: "6-р хороо",
    labelNo: 6,
    districtId: "2",
  },
  {
    value: "15782383",
    label: "7-р хороо",
    labelNo: 7,
    districtId: "2",
  },
  {
    value: "15782384",
    label: "8-р хороо",
    labelNo: 8,
    districtId: "2",
  },
  {
    value: "15782317",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "8",
  },
  {
    value: "15782318",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "8",
  },
  {
    value: "15782319",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "8",
  },
  {
    value: "15782320",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "8",
  },
  {
    value: "15782321",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "8",
  },
  {
    value: "15782322",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "9",
  },
  {
    value: "15782323",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "9",
  },
  {
    value: "15782883",
    label: "1-р хороо",
    labelNo: 1,
    districtId: "3",
  },
  {
    value: "15782892",
    label: "10-р хороо",
    labelNo: 10,
    districtId: "3",
  },
  {
    value: "15782893",
    label: "11-р хороо",
    labelNo: 11,
    districtId: "3",
  },
  {
    value: "15782894",
    label: "12-р хороо",
    labelNo: 12,
    districtId: "3",
  },
  {
    value: "15782895",
    label: "13-р хороо",
    labelNo: 13,
    districtId: "3",
  },
  {
    value: "15782896",
    label: "14-р хороо",
    labelNo: 14,
    districtId: "3",
  },
  {
    value: "15782897",
    label: "15-р хороо",
    labelNo: 15,
    districtId: "3",
  },
  {
    value: "15782898",
    label: "16-р хороо",
    labelNo: 16,
    districtId: "3",
  },
  {
    value: "15782899",
    label: "17-р хороо",
    labelNo: 17,
    districtId: "3",
  },
  {
    value: "15782900",
    label: "18-р хороо",
    labelNo: 18,
    districtId: "3",
  },
  {
    value: "15782901",
    label: "19-р хороо",
    labelNo: 19,
    districtId: "3",
  },
  {
    value: "15782884",
    label: "2-р хороо",
    labelNo: 2,
    districtId: "3",
  },
  {
    value: "15782902",
    label: "20-р хороо",
    labelNo: 20,
    districtId: "3",
  },
  {
    value: "15782903",
    label: "21-р хороо",
    labelNo: 21,
    districtId: "3",
  },
  {
    value: "15782950",
    label: "22-р хороо",
    labelNo: 22,
    districtId: "3",
  },
  {
    value: "15782951",
    label: "23-р хороо",
    labelNo: 23,
    districtId: "3",
  },
  {
    value: "15782952",
    label: "24-р хороо",
    labelNo: 24,
    districtId: "3",
  },
  {
    value: "15782953",
    label: "25-р хороо",
    labelNo: 25,
    districtId: "3",
  },
  {
    value: "15782885",
    label: "3-р хороо",
    labelNo: 3,
    districtId: "3",
  },
  {
    value: "15782886",
    label: "4-р хороо",
    labelNo: 4,
    districtId: "3",
  },
  {
    value: "15782887",
    label: "5-р хороо",
    labelNo: 5,
    districtId: "3",
  },
  {
    value: "15782888",
    label: "6-р хороо",
    labelNo: 6,
    districtId: "3",
  },
  {
    value: "15782889",
    label: "7-р хороо",
    labelNo: 7,
    districtId: "3",
  },
  {
    value: "15782890",
    label: "8-р хороо",
    labelNo: 8,
    districtId: "3",
  },
  {
    value: "15782891",
    label: "9-р хороо",
    labelNo: 9,
    districtId: "3",
  },
];

export const QPAY_AIMAG_HOT = [
  {
    value: "11000",
    label: "Улаанбаатар",
  },
  {
    value: "21000",
    label: "Дорнод аймаг",
  },
  {
    value: "22000",
    label: "Сүхбаатар аймаг",
  },
  {
    value: "23000",
    label: "Хэнтий аймаг",
  },
  {
    value: "41000",
    label: "Төв аймаг",
  },
  {
    value: "42000",
    label: "Говьсүмбэр аймаг",
  },
  {
    value: "43000",
    label: "Сэлэнгэ аймаг",
  },
  {
    value: "44000",
    label: "Дорноговь аймаг",
  },
  {
    value: "45000",
    label: "Дархан-уул аймаг",
  },
  {
    value: "46000",
    label: "Өмнөговь аймаг",
  },
  {
    value: "48000",
    label: "Дундговь аймаг",
  },
  {
    value: "61000",
    label: "Орхон аймаг",
  },
  {
    value: "62000",
    label: "Өвөрхангай аймаг",
  },
  {
    value: "63000",
    label: "Булган аймаг",
  },
  {
    value: "64000",
    label: "Баянхонгор аймаг",
  },
  {
    value: "65000",
    label: "Архангай аймаг",
  },
  {
    value: "67000",
    label: "Хөвсгөл аймаг",
  },
  {
    value: "81000",
    label: "Завхан аймаг",
  },
  {
    value: "82000",
    label: "Говь-алтай аймаг",
  },
  {
    value: "83000",
    label: "Баянөлгий аймаг",
  },
  {
    value: "84000",
    label: "Ховд аймаг",
  },
  {
    value: "85000",
    label: "Увс аймаг",
  },
  {
    value: "99999",
    label: "Хаяг тодорхойгүй",
  },
];
