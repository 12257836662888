export default class NotificationsService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchBroadcasts({ filter, options }) {
    const { data: broadcasts, total: totalCount } =
      await this.fetchUtil.sendGet(
        `/announcements/list${this.fetchUtil.qs({
          filter,
          options,
        })}`
      );

    return { broadcasts, totalCount };
  }

  async fetchBroadcastDetail({ id }) {
    const { data: broadcast } = await this.fetchUtil.sendGet(
      `/announcements/detail?id=${id}`
    );

    if (broadcast) {
      const bt = broadcast.broadcast_type;
      broadcast.broadcasttype = bt;
      broadcast.broadcast_type = {
        id: bt,
        name: bt === "all" ? "Бүх мерчант руу" : "Тодорхой мерчант руу",
      };
      broadcast.channels = broadcast.channels.map((channel) => ({
        id: channel,
        name: channel === "push" ? "Push notification" : "Admin chatbot",
      }));
    }

    return broadcast;
  }

  async addBroadcast({ broadcast }) {
    const { id } = await this.fetchUtil.sendPost(
      "/announcements/create",
      broadcast
    );
    return id;
  }

  async updateBroadcast({ id, broadcast }) {
    await this.fetchUtil.sendPost("/announcements/update", {
      ...broadcast,
      id,
    });
    return id;
  }

  async removeBroadcast({ id }) {
    await this.fetchUtil.sendPost("/announcements/remove", {
      id,
    });
  }

  async sendBroadcast({ id, shop_ids }) {
    await this.fetchUtil.sendPost("/announcements/send", {
      id,
      shop_ids,
    });
  }

  __PLACEHOLDER_METHOD__() {}
}
