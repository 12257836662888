import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function AdSellerList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  isRemoving,
  list,
  pages,
  limit,
  updateQueryString,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);

  useEffect(() => {
    if (isRemoving === false) {
      list(currentPage, filter);
    }
  }, [isRemoving]);

  return (
    <Fragment>
      <PageTitle title="Нөлөөлөгчид" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["phone"]);

                if (formData.status) {
                  theFilter.status = formData.status.id;
                }

                if (formData.start) {
                  theFilter.start = formData.start.toISOString();
                }

                if (formData.end) {
                  theFilter.end = formData.end.toISOString();
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Fragment>
              <Table
                limit={limit}
                currentPage={currentPage}
                rows={fetchResult.adSellers || []}
              />
              <Pagination
                url="/ad/sellers"
                pages={pages}
                currentPage={currentPage}
              />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const AdSellerListWithState = enhancers.redux(AdSellerList);
export default AdSellerListWithState;
