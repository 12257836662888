import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USERS_BEGIN:
      return {
        ...state,
        isFetchingUsers: true
      };

    case types.FETCH_USERS_COMPLETE:
      return {
        ...state,
        isFetchingUsers: false,
        users: action.payload.users
      };

    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        isFetchingUsers: false,
        errors: {
          ...state.errors,
          [constants.FETCH_USERS]: action.payload.error.message
        }
      };

    case types.FETCH_USER_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingUserDetail: true
      };

    case types.FETCH_USER_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingUserDetail: false,
        userDetail: action.payload.userDetail
      };

    case types.FETCH_USER_DETAIL_ERROR:
      return {
        ...state,
        isFetchingUserDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_USER_DETAIL]: action.payload.error.message
        }
      };

    case types.ADD_USER_BEGIN:
      return {
        ...state,
        isAddingUsers: true
      };

    case types.ADD_USER_COMPLETE:
      return {
        ...state,
        isAddingUsers: false
      };

    case types.ADD_USER_ERROR:
      return {
        ...state,
        isAddingUsers: false,
        errors: {
          ...state.errors,
          [constants.ADD_USER]: action.payload.error.message
        }
      };

    case types.UPDATE_USER_BEGIN:
      return {
        ...state,
        isUpdatingUsers: true
      };

    case types.UPDATE_USER_COMPLETE:
      return {
        ...state,
        isUpdatingUsers: false
      };

    case types.UPDATE_USER_ERROR:
      return {
        ...state,
        isUpdatingUsers: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_USER]: action.payload.error.message
        }
      };

    case types.REMOVE_USER_BEGIN:
      return {
        ...state,
        isRemovingUsers: true
      };

    case types.REMOVE_USER_COMPLETE:
      return {
        ...state,
        isRemovingUsers: false
      };

    case types.REMOVE_USER_ERROR:
      return {
        ...state,
        isRemovingUsers: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_USER]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    default:
      return state;
  }
};

export default usersReducer;
