import * as types from "./actionTypes";
export const fetchAdCampaigns = ({ filter, options }) => ({
  type: types.FETCH_AD_CAMPAIGNS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchAdCampaignDetail = ({ id }) => ({
  type: types.FETCH_AD_CAMPAIGN_DETAIL_BEGIN,
  payload: {
    id,
  },
});

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
