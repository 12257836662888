import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";
const Pagination = core.components.Pagination;

function Table({ rows = [], limit, currentPage, totalCount }) {
  return (
    <div className="table-responsive">
      <table className="shops-table table table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th>Төлөв</th>
            <th>Мерчант</th>
            <th>Мейл хаяг</th>
            <th>Төлбөрийн хэрэгсэл</th>
            <th>Үнийн дүн</th>
            <th>Нэхэмжлэх дугаар</th>
            <th style={{ width: 120 }}>Үүссэн огноо</th>
            <th>Сунгасан хугацаа /Сараар/</th>
            <th style={{ width: 120 }}>Купон код</th>
            <th className="text-center" style={{ width: 120 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td style={{ width: 100 }}>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "paid",
                    "badge-warning": row.status === "pending",
                  })}
                >
                  {row.status === "paid" && "төлөгдсөн"}
                  {row.status === "pending" && "хүлээгдэж буй"}
                </span>
              </td>
              <td className="name">
                <div className="media">
                  <div className="media-body">
                    <strong>{row.name}</strong>
                    <div>{row.phone}</div>
                  </div>
                </div>
              </td>
              <td>{row.email}</td>
              <td>{row.provider}</td>
              <td>{row.amount}</td>
              <td>{row.invoiceno}</td>
              <td>{row.created_at}</td>
              <td>{row.quantity}</td>
              <td>{row.coupon_code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
