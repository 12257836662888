import * as types from "./actionTypes";
export const fetchAdSellers = ({ filter, options }) => ({
  type: types.FETCH_AD_SELLERS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchAdSellerDetail = ({ id }) => ({
  type: types.FETCH_AD_SELLER_DETAIL_BEGIN,
  payload: {
    id,
  },
});

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
