import omit from "lodash/omit";

export default class CoreService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async init() {
    return await this.storage.getItem("current_user", true);
  }

  async login({ username, password }) {
    const user = await this.fetchUtil.sendPost(
      "/admins/login-with-password",
      {
        username,
        password
      },
      true
    );

    if (user && user.access_token) {
      await this.storage.setItem("access_token", user.access_token);
      await this.storage.setItem(
        "current_user",
        omit(user, "access_token"),
        true
      );

      return omit(user, "access_token");
    }
  }

  async changePassword({ currentPassword, newPassword }) {
    await this.fetchUtil.sendPost(
      "/admins/change-password",
      {
        new_password: newPassword,
        current_password: currentPassword
      },
      true
    );
  }

  async logout() {
    const accessToken = await this.storage.getItem("access_token");
    await this.storage.removeItem("access_token");
    await this.storage.removeItem("current_user");
    fetch(`${process.env.REACT_APP_BACKEND_URL}/admins/logout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": accessToken
      }
    });
  }

  async suggestShop({ name }) {
    if (!name) {
      return [];
    }

    const { data : shops } = await this.fetchUtil.sendPost(
      "/shops/suggest-shops",
      { name },
      true
    );

    return shops;
  }
}
