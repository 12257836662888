import React, { PureComponent, Fragment } from 'react';
import DatePicker from 'react-datepicker';

import { transformOptToVal, transformValToOpt } from '../../helpers';

export default class DateTimePicker extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <div className="clearfix form-control">
        <DatePicker
          selected={input.value}
          className="form-control-datetime"
          onChange={input.onChange}
          required={this.props.required}
          dateFormat={this.props.dateFormat || 'yyyy-MM-dd'}
          placeholderText={this.props.placeholder}
          showTimeSelect={this.props.showTimeSelect}
        />
      </div>
    );
  }
}
