import screens from './screens';

export default [
  {
    exact: true,
    path: "/products",
    component: screens.ProductList,
  },
  {
    exact: true,
    path: "/products/edit/:id",
    component: screens.ProductForm,
  },
  {
    exact: true,
    path: "/product-categories",
    component: screens.CategoryList,
  },
  {
    exact: true,
    path: "/product-categories/edit/:id",
    component: screens.CategoryForm,
  },
];