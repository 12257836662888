import screens from "./screens";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/orders",
    component: screens.OrderList,
  },
  {
    path: "/orders/detail/:id",
    component: screens.OrderDetail,
  },
];
