import core from "core";
import bills from "app/bills";
import shops from "app/shops";
import users from "app/users";
import orders from "app/orders";
import admins from "app/admins";
import issues from "app/issues";
import messages from "app/messages";
import channels from "app/channels";
import products from "app/products";
import dashboard from "app/dashboard";
import customers from "app/customers";
import activities from "app/activities";
import settlements from "app/settlements";
import notifications from "app/notifications";
import transactions from "app/transactions";
import payments from "app/payments";
import contracts from "app/contracts";
import modules from "app/modules";
import campaigns from "app/campaigns";
import ad_sellers from "app/ad/sellers";
import withdrawals from "app/ad/withdrawals";
import ad_campaigns from "app/ad/ad-campaigns";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...core.routes,
  ...bills.routes,
  ...shops.routes,
  ...users.routes,
  ...orders.routes,
  ...admins.routes,
  ...issues.routes,
  ...messages.routes,
  ...channels.routes,
  ...products.routes,
  ...dashboard.routes,
  ...customers.routes,
  ...activities.routes,
  ...settlements.routes,
  ...payments.routes,
  ...contracts.routes,
  ...modules.routes,
  ...campaigns.routes,
  ...notifications.routes,
  ...transactions.routes,
  ...withdrawals.routes,
  ...ad_campaigns.routes,
  ...ad_sellers.routes,
];
