import omit from "lodash/omit";
export default class BillService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchBills({ filter, options }) {
    const { data: bills, total: totalCount } = await this.fetchUtil.sendGet(
      `/bills/list${this.fetchUtil.qs({ filter, options })}`
    );
    return { bills, totalCount };
  }

  __PLACEHOLDER_METHOD__() {}
}
