import redux from "./redux";
import { reduxForm } from "redux-form";

export default {
  redux,
  form: (component) =>
    reduxForm({
      form: "componentForm",
      enableReinitialize: true,
    })(component),
};
