import screens from './screens';

export default [
  {
    path: "/broadcasts",
    component: screens.BroadcastList,
  },
  {
    path: "/broadcasts/new",
    component: screens.BroadcastForm,
  },
  {
    path: "/broadcasts/edit/:id",
    component: screens.BroadcastForm,
  },
  {
    path: "/broadcasts/detail/:id",
    component: screens.BroadcastDetail,
  },
];