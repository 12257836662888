import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import core from 'core';
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }} >#</th>
            <th style={{ width: 120 }}>Төлөв</th>
            <th>Мерчант</th>
            <th style={{ width: 250 }} >Дүн</th>
            <th style={{ width: 150 }} >Захиалгын тоо</th>
            <th style={{ width: 150 }} >Огноо</th>
            <th style={{ width: 40 }} />
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) =>
              <tr key={`tr_${row.id}`} >
                <td>{ core.helpers.getTableIndex(currentPage, limit, index) }</td>
                <td>
                  <span className={classNames('badge p-1', {
                    'badge-success': row.status === 'success',
                    'badge-warning': row.status === 'pending',
                    'badge-danger': row.status === 'failed',
                  })}>
                    { StatusMap[row.status] }
                  </span>
                </td>
                <td>{ row.merchant_name }</td>
                <td>{
                  numeral(row.transfer_amount).format('0,0')
                  } / {
                    numeral(row.total_amount).format('0,0')
                  } ₮</td>
                <td>{ row.order_count }</td>
                <td>{ moment(row.created_at).format('YYYY-MM-DD HH:mm') }</td>
                <td style={{ width: 40 }} >
                  <a href="#" className="action-icon"> <i className="mdi mdi-eye" /></a>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: 'Хүлээгдэж буй',
  failed: 'Амжилтгүй',
  success: 'Амжилттай',
};

export default Table;
