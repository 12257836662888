import sort from "lodash/sortBy";
import React, { Fragment, useEffect } from "react";
import { Field } from "redux-form";

import core from "core";
import enhancers from "./enhancers";
import { TOWNS } from "./address_data";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function IssueForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  issue,
  issueType,
  corporateFlag,
  addressDistrict,
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearForm(), []);

  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    goBackList
  );

  const isSaving = isAdding || isUpdating;

  return (
    <Fragment>
      <PageTitle title={`Хүсэлт ${isEdit ? "засах" : "үүсгэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-6">
                  <FormGroup required label="Гарчиг">
                    <Field
                      required
                      name="title"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Гарчиг"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-6">
                  <FormGroup required label="Мерчант">
                    <Field
                      required
                      name="merchant_id"
                      disabled={isSaving}
                      placeholder="Мерчант сонгох..."
                      component={core.components.ShopSelector}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <FormGroup required label="Хүсэлтийн төрөл">
                    <Field
                      required
                      name="issue_type"
                      placeholder="Сонгох.."
                      options={[
                        { value: "qpay", label: "Qpay холбох" },
                        { value: "qpay2", label: "Qpay2 холбох" },
                        { value: "monpay", label: "MonPay холбох" },
                        { value: "socialpay", label: "SocialPay холбох" },
                        {
                          value: "zochil_checkout",
                          label: "Zochil Checkout холбох",
                        },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-6">
                  <FormGroup label="Тайлбар">
                    <Field
                      name="description"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Тайлбар"
                    />
                  </FormGroup>
                </div>
              </div>
              {issueType === "zochil_checkout" && (
                <>
                  <div className="row">
                    <div style={{ marginBottom: 20 }} className="col-xl-6">
                      <FormGroup required label="Үйл ажиллагааны чиглэл">
                        <Field
                          required
                          name="category_code"
                          placeholder="Сонгох.."
                          disabled={isSaving}
                          options={[
                            {
                              value: "14",
                              label: "Авто худалдаа",
                            },
                            {
                              value: "15",
                              label: "Амьтны дэлгүүр",
                            },
                            {
                              value: "16",
                              label: "Арьсан эдлэл",
                            },
                            {
                              value: "17",
                              label: "Аяны хэрэгсэл",
                            },
                            {
                              value: "18",
                              label: "Барилгын материал",
                            },
                            {
                              value: "19",
                              label: "Бөс бараа, Торго",
                            },
                            {
                              value: "20",
                              label: "Бэлэг дурсгал",
                            },
                            {
                              value: "21",
                              label: "Бэлэн хувцас",
                            },
                            {
                              value: "22",
                              label: "Гар урлал",
                            },
                            {
                              value: "23",
                              label:
                                "Гоёл чимэглэл, Аксесуар (Нүдний шил, цүнх, түрийвч)",
                            },
                            {
                              value: "24",
                              label: "Гоо сайхны бараа",
                            },
                            {
                              value: "25",
                              label: "Гэр ахуйн бараа",
                            },
                            {
                              value: "26",
                              label: "Монгол гэр, туслах хэрэгсэл",
                            },
                            {
                              value: "27",
                              label: "Номын худалдаа, Бичиг хэрэг",
                            },
                            {
                              value: "28",
                              label: "Спортын бараа",
                            },
                            {
                              value: "29",
                              label: "Тавилгын худалдаа",
                            },
                            {
                              value: "30",
                              label: "Тоглоом",
                            },
                            {
                              value: "31",
                              label: "Унадаг дугуй худалдаа",
                            },
                            {
                              value: "32",
                              label: "Үнэт эдлэл, эртний эдлэл",
                            },
                            {
                              value: "33",
                              label: "Хөгжмийн хэрэгсэл, тоног төхөөрөмж ",
                            },
                            {
                              value: "34",
                              label: "Хүнсний бүтээгдэхүүн",
                            },
                            {
                              value: "35",
                              label: "Шашны эдлэл",
                            },
                            {
                              value: "36",
                              label: "Электрон бараа (Компьютер, гар утас)",
                            },
                            {
                              value: "37",
                              label: "Бусад бараа",
                            },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Байгууллага / Хувь хүн">
                        <Field
                          required
                          name="corporate_flag"
                          placeholder="Сонгох.."
                          disabled={isSaving}
                          options={[
                            { value: "0", label: "Хувь хүн" },
                            { value: "1", label: "Байгууллага" },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <FormGroup required label="Банк">
                        <Field
                          required
                          name="checkout_account_bank"
                          placeholder="Сонгох.."
                          disabled={isSaving}
                          options={[
                            { value: "050000", label: "Хаан банк" },
                            { value: "150000", label: "Голомт банк" },
                            { value: "040000", label: "ХХБ" },
                            { value: "320000", label: "Хас банк" },
                            { value: "340000", label: "Төрийн банк" },
                            { value: "300000", label: "Капитрон банк" },
                            { value: "210000", label: "Ариг банк" },
                            {
                              value: "290000",
                              label: "Үндэсний хөрөнгө оруулалтын банк",
                            },
                            { value: "330000", label: "Чингис хаан банк" },
                            { value: "900000", label: "Төрийн сан" },
                            { value: "380000", label: "Банк банк" },
                            { value: "190000", label: "Транс банк" },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup label="Дансны дугаар">
                        <Field
                          name="checkout_account_number"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Дансны дугаар"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <FormGroup required label="Дүүрэг">
                        <Field
                          required
                          name="address_district"
                          placeholder="Сонгох.."
                          disabled={isSaving}
                          options={[
                            {
                              value: "1",
                              label: "Сонгинохайрхан",
                            },
                            {
                              value: "5",
                              label: "Баянгол",
                            },
                            {
                              value: "7",
                              label: "Баянзүрх",
                            },
                            {
                              value: "4",
                              label: "Сүхбаатар",
                            },
                            {
                              value: "6",
                              label: "Чингэлтэй",
                            },
                            {
                              value: "2",
                              label: "Налайх",
                            },
                            {
                              value: "8",
                              label: "Багануур",
                            },
                            {
                              value: "9",
                              label: "Багахангай",
                            },
                            {
                              value: "3",
                              label: "Хан-Уул",
                            },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label={`Хороо`}>
                        <Field
                          required
                          name="address_town"
                          placeholder="Сонгох.."
                          disabled={isSaving || !addressDistrict}
                          options={
                            !addressDistrict
                              ? []
                              : sort(
                                  TOWNS.filter(
                                    (town) =>
                                      town.districtId === addressDistrict
                                  ),
                                  (o) => o.labelNo
                                )
                          }
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-xl-6">
                      <FormGroup required label="Барилга / хашааны дугаар">
                        <Field
                          required
                          name="address_building"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Барилга / хашааны дугаарр"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Тоот">
                        <Field
                          required
                          name="address_doorno"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Тоот"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <FormGroup required label="Утас">
                        <Field
                          required
                          name="address_phone"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Утас"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Имэйл">
                        <Field
                          required
                          name="address_email"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Имэйл"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    {corporateFlag === "0" && (
                      <>
                        <div className="col-xl-6">
                          <FormGroup required label="Өөрийн нэр">
                            <Field
                              required
                              name="first_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Өөрийн нэр"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-xl-6">
                          <FormGroup required label="Эцэг эхийн нэр">
                            <Field
                              required
                              name="last_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Эцэг эхийн нэр"
                            />
                          </FormGroup>
                        </div>
                      </>
                    )}
                    {corporateFlag === "1" && (
                      <div className="col-xl-6">
                        <FormGroup required label="Байгууллагын нэр">
                          <Field
                            required
                            name="company_name"
                            type="text"
                            component="input"
                            disabled={isSaving}
                            className="form-control"
                            placeholder="Байгууллагын нэр"
                          />
                        </FormGroup>
                      </div>
                    )}
                    <div className="col-xl-6">
                      <FormGroup required label="Регистрийн дугаар">
                        <Field
                          required
                          name="regno"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Регистрийн дугаар"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                {issueType === "monpay" && (
                  <>
                    <div className="col-xl-6">
                      <FormGroup required label="Мерчантийн нэвтрэх нэр">
                        <Field
                          required
                          name="monpay_branch_username"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Мерчантийн нэвтрэх нэр"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Мерчантийн англи нэр">
                        <Field
                          required
                          name="monpay_english_name"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Мерчантийн англи нэр"
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
                {issueType === "qpay" && (
                  <>
                    <div className="col-xl-6">
                      <FormGroup required label="Банк">
                        <Field
                          required
                          name="qpay_account_bank"
                          disabled={isSaving}
                          placeholder="Банк сонгох..."
                          options={[
                            { value: "Хаан банк", label: "Хаан банк" },
                            { value: "Төрийн банк", label: "Төрийн банк" },
                            { value: "ХХБ", label: "ХХБ" },
                            { value: "Хас Банк", label: "Хас Банк" },
                            { value: "Капитрон банк", label: "Капитрон банк" },
                            { value: "Ариг банк", label: "Ариг банк" },
                            { value: "Богд банк", label: "Богд банк" },
                            { value: "М банк", label: "М банк" },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Дансны дугаар">
                        <Field
                          required
                          name="qpay_account_number"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Дансны дугаар"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Дансны нэр">
                        <Field
                          required
                          name="qpay_account_holder"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Дансны нэр"
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
                {/* {
    "owner_register_no": "АО85010101",
    "owner_first_name": "Наран",
    "owner_last_name": "Бат",
    "location_lat": "47.9184676",
    "location_lng": "106.9177016",
    "register_number": "5120163",
    "name": "Батхүлэг",
    "mcc_code": "0110",
    "city": "Ulaanbaatar",
    "district": "Sukhbaatar",
    "address": "6 хороо 14-10",
    "phone": "8888888",
    "email": "bat@email.com"
} */}
                {issueType === "qpay2" && (
                  <>
                    <div className="col-xl-6">
                      <FormGroup required label="Үйл ажиллагааны чиглэл">
                        <Field
                          required
                          name="mcc_code"
                          disabled={isSaving}
                          placeholder="Сонгох.."
                          options={[
                            {
                              value: "5411",
                              label: "Хүнсний дэлгүүр, супермаркет",
                            },
                            {
                              value: "5499",
                              label: "Bakery",
                            },
                            {
                              value: "5631",
                              label: "Эмэгтэй гоёл, зүүлт",
                            },
                            {
                              value: "5641",
                              label:
                                "Хүүхэд болон нярайн хувцас хэрэглэлийн дэлгүүр",
                            },
                            {
                              value: "5661",
                              label: "Гутлын дэлгүүр",
                            },
                            {
                              value: "5732",
                              label: "Цахилгаан бараа",
                            },
                            {
                              value: "5944",
                              label: "Алт мөнгөн эдлэл, гоёл чимэглэл",
                            },
                            {
                              value: "7994",
                              label: "Интэрнет кафе",
                            },
                            {
                              value: "4812",
                              label: "Гар утас худалдаа",
                            },
                            {
                              value: "5932",
                              label: "Шашны бараа",
                            },
                            {
                              value: "5942",
                              label: "Номын дэлгүүр",
                            },
                            {
                              value: "5021",
                              label: "Оффисын тавилга",
                            },
                            {
                              value: "8062",
                              label: "Эмнэлэг",
                            },
                            {
                              value: "5231",
                              label: "Нүдний шил",
                            },
                            {
                              value: "8211",
                              label: "Сургалтын төв",
                            },
                            {
                              value: "8299",
                              label: "Гадаад хэлний сургалт",
                            },
                            {
                              value: "8351",
                              label: "Хүүхдийн цэцэрлэг",
                            },
                            {
                              value: "4582",
                              label: "Тийз",
                            },
                            {
                              value: "9402",
                              label: "Шуудангийн салбар",
                            },
                            {
                              value: "7534",
                              label: "Авто засварын газар",
                            },
                            {
                              value: "7542",
                              label: "Авто угаалга",
                            },
                            {
                              value: "7011",
                              label: "Зочид буудал",
                            },
                            {
                              value: "5172",
                              label: "Шатахуун түгээх",
                            },
                            {
                              value: "7997",
                              label: "Гольф",
                            },
                            {
                              value: "7941",
                              label: "Фитнесс",
                            },
                            {
                              value: "7832",
                              label: "Кино Театр",
                            },
                            {
                              value: "4722",
                              label: "Аялал жуулчлал",
                            },
                            {
                              value: "4816",
                              label: "Компьютер, гар утас худалдаа",
                            },
                            {
                              value: "5812",
                              label: "Хоолны газар, ресторан",
                            },
                            {
                              value: "5813",
                              label: "Шөнийн цэнгээний газар, паб",
                            },
                            {
                              value: "6012",
                              label: "Ломбард",
                            },
                            {
                              value: "6300",
                              label: "Даатгалын компани",
                            },
                            {
                              value: "6010",
                              label: "Банк бус",
                            },
                            {
                              value: "7216",
                              label: "Хими цэвэрлэгээ",
                            },
                            {
                              value: "5013",
                              label: "Авто сэлбэг",
                            },
                            {
                              value: "5039",
                              label: "Барилгын материал",
                            },
                            {
                              value: "5192",
                              label: "Бичиг хэрэг",
                            },
                            {
                              value: "5193",
                              label: "Гэр ахуйн бараа",
                            },
                            {
                              value: "5921",
                              label:
                                "Шар айраг, вино, архины төрөлжсөн дэлгүүр",
                            },
                            {
                              value: "5399",
                              label: "Бусад бараа бүтээгдэхүүний худалдаа",
                            },
                            {
                              value: "5422",
                              label: "Мах, махан бүтээгдэхүүний нийлүүлэлт",
                            },
                            {
                              value: "5441",
                              label: "Чихэр, самар, амттаны дэлгүүр",
                            },
                            {
                              value: "5451",
                              label: "Сүү, сүүн бүтээгдэхүүний дэлгүүр",
                            },
                            {
                              value: "5311",
                              label: "Их дэлгүүр",
                            },
                            {
                              value: "5331",
                              label: "Төрөлжсөн бус барааны дэлгүүр",
                            },
                            {
                              value: "5309",
                              label: "Татваргүй барааны дэлгүүр",
                            },
                            {
                              value: "5310",
                              label: "Хямдралтай барааны дэлгүүр",
                            },
                            {
                              value: "5300",
                              label: "Бөөний худалдаа",
                            },
                            {
                              value: "5611",
                              label:
                                "Эрэгтэйчүүдийн хувцас, хэрэглэлийн төрөлжсөн дэлгүүр",
                            },
                            {
                              value: "5621",
                              label:
                                "Эмэгтэйчүүдийн хувцасны төрөлжсөн дэлгүүр",
                            },
                            {
                              value: "5651",
                              label: "Гэр бүлийн хувцасны дэлгүүр",
                            },
                            {
                              value: "5655",
                              label: "Спорт хувцасны дэлгүүр",
                            },
                            {
                              value: "5681",
                              label: "Үслэг эдлэлийн дэлгүүр",
                            },
                            {
                              value: "5698",
                              label: "Хиймэл үсний дэлгүүр",
                            },
                            {
                              value: "5699",
                              label:
                                "Бусад эдлэл хэрэглэл, гоѐл чимэглэлийн дэлгүүр",
                            },
                            {
                              value: "5712",
                              label:
                                "Гэрийн тавилга, цахилгаан бараанаас бусад ахуйн барааны дэлгүүр",
                            },
                            {
                              value: "5713",
                              label:
                                "Хивс, хивсэнцэр, шалны дэвсгэрийн дэлгүүр",
                            },
                            {
                              value: "5714",
                              label: "Бөс бараа, хөшиг, бүрээсний дэлгүүр",
                            },
                            {
                              value: "5718",
                              label:
                                "Зуух, зуухны хамгаалалт, жижиг хэрэгслийн дэлгүүр",
                            },
                            {
                              value: "5719",
                              label:
                                "Бусад гэр ахуйн төрөлжсөн барааны дэлгүүр",
                            },
                            {
                              value: "5722",
                              label: "Гэр ахуйн цахилгаан барааны дэлгүүр",
                            },
                            {
                              value: "5733",
                              label:
                                "Хөгжмийн дэлгүүр - хөгжмийн зэмсэг, төгөлдөр хуур, нот",
                            },
                            {
                              value: "5734",
                              label: "Компьютерийн програм хангамжийн дэлгүүр",
                            },
                            {
                              value: "5735",
                              label: "Дуу бичлэг, хуурцаг, CD, DVD-ний дэлгүүр",
                            },
                            {
                              value: "5912",
                              label:
                                "Эм, гоо сайхан, ариун цэврийн барааны дэлгүүр",
                            },
                            {
                              value: "5931",
                              label: "Хуучин барааны дэлгүүр",
                            },
                            {
                              value: "5940",
                              label: "Унадаг дугуйн дэлгүүр",
                            },
                            {
                              value: "5941",
                              label: "Спорт барааны дэлгүүр",
                            },
                            {
                              value: "5943",
                              label:
                                "Бичиг хэрэг, оффисын хангамж, бичгийн болон хэвлэлийн цаасны дэлгүүр",
                            },
                            {
                              value: "5945",
                              label: "Тоглоомын дэлгүүр",
                            },
                            {
                              value: "5946",
                              label:
                                "Гэрэл зургийн аппарат, холбогдох хэрэгслийн дэлгүүр",
                            },
                            {
                              value: "5947",
                              label:
                                "Бэлэг, мэндчилгээ, бэлэг дурсгалын дэлгүүр",
                            },
                            {
                              value: "5948",
                              label:
                                "Аяллын цүнх, арьсан бүтээгдэхүүний дэлгүүр",
                            },
                            {
                              value: "5949",
                              label: "Бөс бараа, оѐдлын барааны дэлгүүр",
                            },
                            {
                              value: "5950",
                              label: "Шилэн эдлэлийн дэлгүүр",
                            },
                            {
                              value: "5970",
                              label:
                                "Уран зураг, гар урлалын хэрэгслийн дэлгүүр",
                            },
                            {
                              value: "5971",
                              label: "Галарей, уран зургийн худалдаа",
                            },
                            {
                              value: "5972",
                              label: "Тамга, зоосны дэлгүүр",
                            },
                            {
                              value: "5973",
                              label: "Шашны эдлэл хэрэглэлийн дэлгүүр",
                            },
                            {
                              value: "5975",
                              label:
                                "Сонсголын аппарат, дагалдах хэрэгслийн худалдаа",
                            },
                            {
                              value: "5976",
                              label: "Согог заслын бүтээгдэхүүн худалдаа",
                            },
                            {
                              value: "5977",
                              label: "Гоо сайхны барааны дэлгүүр",
                            },
                            {
                              value: "5992",
                              label: "Цэцгийн дэлгүүр",
                            },
                            {
                              value: "5993",
                              label: "Тамхины төрөлжсөн дэлгүүр болон цэг",
                            },
                            {
                              value: "5994",
                              label: "Сонин сэтгүүлийн худалдаа",
                            },
                            {
                              value: "5995",
                              label:
                                "Тэжээвэр амьтан, амьтны хоол, жижиг хэрэгслийн дэлгүүр,",
                            },
                            {
                              value: "5998",
                              label: "Майхан, сүүдрэвчний дэлгүүр",
                            },
                            {
                              value: "5999",
                              label: "Бусад төрөлжсөн барааны дэлгүүр",
                            },
                            {
                              value: "5200",
                              label: "Гэр ахуйн барааны дэлгүүр",
                            },
                            {
                              value: "5211",
                              label: "Модон эдлэл, барилгын материалын дэлгүүр",
                            },
                            {
                              value: "5251",
                              label: "Техник хэрэгслийн дэлгүүр",
                            },
                            {
                              value: "5261",
                              label:
                                "Зүлэгжүүлэлт, цэцэрлэгжүүлэлтийн барааны дэлгүүр",
                            },
                            {
                              value: "5592",
                              label: "Зөөврийн сууцны худалдаа",
                            },
                            {
                              value: "5531",
                              label:
                                "Автомашин болон гэр ахуйн барааны дэлгүүр",
                            },
                            {
                              value: "5532",
                              label: "Автомашины дугуйны дэлгүүр",
                            },
                            {
                              value: "5533",
                              label:
                                "Автомашины сэлбэг, жижиг хэрэгслийн дэлгүүр",
                            },
                            {
                              value: "5561",
                              label: "Мотоциклийн дэлгүүр, худалдаа",
                            },
                            {
                              value: "742",
                              label: "Мал эмнэлгийн үйлчилгээ",
                            },
                            {
                              value: "4119",
                              label: "Түргэн тусламжийн үйлчилгээ",
                            },

                            {
                              value: "8071",
                              label: "Эрүүл мэнд, шүдний лаборатори",
                            },
                            {
                              value: "8099",
                              label: "Эрүүл мэндийн үйлчилгээ",
                            },
                            {
                              value: "8049",
                              label: "Хөлийн эрүүл мэнд, эмчилгээ",
                            },
                            {
                              value: "8050",
                              label: "Асаргаа, сувилгааны газар",
                            },
                            {
                              value: "8011",
                              label: "Эмч",
                            },
                            {
                              value: "8021",
                              label: "Шүдний эмч",
                            },
                            {
                              value: "8041",
                              label: "Бариа засал",
                            },
                            {
                              value: "8043",
                              label: "Нүдний эмч, нүдний шилний газар",
                            },
                            {
                              value: "7911",
                              label: "Бүжгийн танхим, студи, сургууль",
                            },
                            {
                              value: "8220",
                              label: "Их дээд сургууль",
                            },

                            {
                              value: "8241",
                              label: "Эчнээ сургууль",
                            },
                            {
                              value: "8244",
                              label: "Бизнесийн сургууль",
                            },
                            {
                              value: "8249",
                              label: "Мэргэжил олгох сургууль",
                            },
                            {
                              value: "4011",
                              label: "Төмөр замын ачаа тээвэр",
                            },
                            {
                              value: "4112",
                              label: "Төмөр замын зорчигч тээвэр",
                            },
                            {
                              value: "4511",
                              label: "Агаарын ачаа болон зорчигч тээвэр",
                            },
                            {
                              value: "4789",
                              label: "Бусад тээврийн үйлчилгээ",
                            },
                            {
                              value: "780",
                              label: "Орчны тохижилтын үйлчилгээ",
                            },
                            {
                              value: "1740",
                              label:
                                "Чулуун буюу тоосгон өрлөг, засал чимэглэлийн үйлчилгээ",
                            },
                            {
                              value: "2741",
                              label: "Төрөл бүрийн хэвлэх, хувилах үйлчилгээ",
                            },

                            {
                              value: "2791",
                              label: "Үсэг өрөх, хэв бэлдэх үйлчилгээ",
                            },
                            {
                              value: "2842",
                              label: "Цэвэрлэгээ үйлчилгээ",
                            },
                            {
                              value: "4131",
                              label: "Автобусны шугамын үйлчилгээ",
                            },
                            {
                              value: "4214",
                              label:
                                "Том оврын ачааны машины үйлчилгээ - хол болон ойрын зайн нүүлгэлт, хүргэлтийн үйлчилгээ",
                            },
                            {
                              value: "4215",
                              label: "Шуудангийн үйлчилгээ",
                            },
                            {
                              value: "4225",
                              label: "Агуулах болон хадгалалтын үйлчилгээ",
                            },
                            {
                              value: "4814",
                              label: "Харилцаа холбооны үйлчилгээ",
                            },
                            {
                              value: "4821",
                              label: "Цахилгаан холбооны үйлчилгээ",
                            },
                            {
                              value: "5937",
                              label: "Эртний эдлэл засварлах үйлчилгээ",
                            },
                            {
                              value: "7210",
                              label: "Угаалга, цэвэрлэгээний үйлчилгээ",
                            },

                            {
                              value: "7261",
                              label: "Оршуулга, чандарлах үйлчилгээ",
                            },
                            {
                              value: "7298",
                              label: "Эрүүл мэнд, гоо сайхны үйлчилгээ",
                            },
                            {
                              value: "7299",
                              label: "Бусад үйлчилгээ",
                            },
                            {
                              value: "7311",
                              label: "Зар сурталчилгааны үйлчилгээ",
                            },
                            {
                              value: "7342",
                              label: "Устгалын үйлчилгээ",
                            },
                            {
                              value: "7349",
                              label: "Цэвэрлэгээ, арчилгаа",
                            },
                            {
                              value: "7372",
                              label: "Компьютерын програмчлалын үйлчилгээ",
                            },
                            {
                              value: "7375",
                              label: "Мэдээллийн лавлах үйлчилгээ",
                            },
                            {
                              value: "7379",
                              label: "Компьютер засварын үйлчилгээ",
                            },
                            {
                              value: "7392",
                              label: "Зөвлөх, олон нийттэй харилцах үйлчилгээ",
                            },
                            {
                              value: "7394",
                              label: "Тоног төхөөрөмжийн түрээсийн үйлчилгээ",
                            },
                            {
                              value: "7395",
                              label: "Зураг засварын үйлчилгээ",
                            },
                            {
                              value: "7399",
                              label: "Бусад бизнесийн үйлчилгээ",
                            },
                            {
                              value: "7513",
                              label:
                                "Том оврын машин, чиргүүл түрээслэх үйлчилгээ",
                            },
                            {
                              value: "7535",
                              label: "Автомашин будаж засах үйлчилгээ",
                            },
                            {
                              value: "7549",
                              label: "Ачих, чирэх үйлчилгээ",
                            },
                            {
                              value: "8911",
                              label: "Архитектур зураг төслийн үйлчилгээ",
                            },
                            {
                              value: "8931",
                              label: "Нягтлан бодох бүртгэлийн үйлчилгээ",
                            },
                            {
                              value: "8999",
                              label: "Мэргэжлийн үйлчилгээ",
                            },
                            {
                              value: "5521",
                              label:
                                "Хуучин моторт тээврийн хэрэгслийн худалдаа, үйлчилгээ, сэлбэг болон түрээс",
                            },

                            {
                              value: "8111",
                              label: "Хуулийн зөвлөгөө, өмгөөллийн үйлчилгээ",
                            },
                            {
                              value: "7276",
                              label: "Татварын зөвлөгөө өгөх үйлчилгээ",
                            },
                            {
                              value: "7277",
                              label: "Зөвлөгөө өгөх үйлчилгээ",
                            },
                            {
                              value: "8398",
                              label:
                                "Буяны байгууллага болон хандив цуглуулах үйл ажиллагаа",
                            },
                            {
                              value: "8641",
                              label: "Иргэдийн, нийгмийн, ах дүүсийн нийгэмлэг",
                            },
                            {
                              value: "8651",
                              label: "Улс төрийн байгууллага",
                            },
                            {
                              value: "8661",
                              label: "Шашны байгууллага",
                            },
                            {
                              value: "8675",
                              label: "Автомашин сонирхогчдын холбоо",
                            },
                            {
                              value: "8699",
                              label: "Гишүүнчлэлийн байгууллага",
                            },

                            {
                              value: "8734",
                              label: "Шинжилгээ судалгааны лаборатори",
                            },
                            {
                              value: "9222",
                              label:
                                "Төрийн үйлчилгээний байгууллагуудын торгууль",
                            },
                            {
                              value: "9311",
                              label: "Татвар төлөлт",
                            },
                            {
                              value: "9399",
                              label: "Төрийн бусад үйлчилгээ",
                            },
                            {
                              value: "7999",
                              label: "Бусад амралт зугаалгын газар",
                            },
                            {
                              value: "7929",
                              label: "Хамтлаг дуучид",
                            },
                            {
                              value: "7998",
                              label: "Аквариум",
                            },
                            {
                              value: "7361",
                              label: "Ажил олгох агентлаг",
                            },

                            {
                              value: "7393",
                              label: "Мөрдөх агентлаг",
                            },
                            {
                              value: "7512",
                              label: "Машин түрээсийн газар",
                            },
                            {
                              value: "7523",
                              label: "Машины зогсоол, гарааш",
                            },
                            {
                              value: "4784",
                              label: "Зам, гүүрний хураамж",
                            },
                            {
                              value: "5541",
                              label: "Автомашины үйлчилгээний цэгүүд",
                            },
                            {
                              value: "5542",
                              label: "Шатахуун түгээх газар",
                            },
                            {
                              value: "5983",
                              label:
                                "Шатахууны жижиглэн худалдаа /non-automotive/",
                            },
                            {
                              value: "7992",
                              label: "Гольфын сургалт",
                            },

                            {
                              value: "7993",
                              label: "Видео тоглоомын хэрэгсэл худалдаа",
                            },
                            {
                              value: "7995",
                              label: "Мөрийтэй тоглоомын газар",
                            },
                            {
                              value: "7932",
                              label: "Биллиард, усан сан",
                            },
                            {
                              value: "7933",
                              label: "Боулингийн газар",
                            },
                            {
                              value: "7829",
                              label: "Зураг авалт, видео бичлэгийн газар",
                            },
                            {
                              value: "7841",
                              label: "Видео хуурцаг түрээсийн газар",
                            },
                            {
                              value: "7922",
                              label: "Үзвэр үйлчилгээний билетийн касс",
                            },
                            {
                              value: "7012",
                              label: "Амралтын байр",
                            },

                            {
                              value: "7032",
                              label: "Спорт, амралт зугаалгын газар",
                            },
                            {
                              value: "7991",
                              label:
                                "Музей ба түүхийн дурсгалт газар, байшин барилга үзүүлэх үйл ажиллагаа, үзэсгэлэн",
                            },
                            {
                              value: "7996",
                              label:
                                "Цэнгэлдэх хүрээлэн, парк болон цэцэрлэгт хүрээлэнгийн үйл ажиллагаа",
                            },
                            {
                              value: "7622",
                              label: "Цахилгаан барааны засвар",
                            },
                            {
                              value: "7623",
                              label: "Агааржуулалт, хөргөлтийн систем засвар",
                            },
                            {
                              value: "7629",
                              label: "Гэр ахуйн жижиг хэрэгсэл засвар",
                            },
                            {
                              value: "7631",
                              label: "Цаг, үнэт эдлэл засвар",
                            },
                            {
                              value: "7641",
                              label: "Тавилга засвар",
                            },

                            {
                              value: "7692",
                              label: "Гагнуур",
                            },
                            {
                              value: "7699",
                              label: "Бусад засварын газар",
                            },
                            {
                              value: "7531",
                              label: "Автомашин засвар",
                            },
                            {
                              value: "7538",
                              label: "Автомашины үйлчилгээ үзүүлэх газар",
                            },
                            {
                              value: "7251",
                              label: "Гутал засвар/малгай цэвэрлэгээ",
                            },
                            {
                              value: "5814",
                              label: "Түргэн хоолны газар",
                            },
                            {
                              value: "5462",
                              label: "Нарийн боовны газар",
                            },
                            {
                              value: "5811",
                              label: "Хоол хүнс нийлүүлэгч",
                            },
                            {
                              value: "6051",
                              label: "Банк бус санхүүгийн байгууллага",
                            },
                            {
                              value: "6211",
                              label: "Үнэт цаасны брокер/дилер",
                            },
                            {
                              value: "6513",
                              label: "Үл хөдлөх хөрөнгийн агентлаг",
                            },
                            {
                              value: "7321",
                              label: "Зээлийн мэдээллийн агентлаг",
                            },
                            {
                              value: "6011",
                              label: "АТМ-ын гүйлгээ",
                            },
                            {
                              value: "5933",
                              label: "Ломбард",
                            },
                            {
                              value: "1520",
                              label: "Ердийн гэрээт ажилчид",
                            },
                            {
                              value: "1711",
                              label: "Халаалт, сантехник, агааржуулалт",
                            },
                            {
                              value: "1731",
                              label: "Цахилгаан техник",
                            },
                            {
                              value: "1750",
                              label: "Мужаан, дархан",
                            },
                            {
                              value: "1761",
                              label: "Дээврийн ажил",
                            },
                            {
                              value: "1771",
                              label: "Бетон зуурмагийн ажил",
                            },
                            {
                              value: "1799",
                              label: "Бусад үйлчилгээний гэрээт ажилчид",
                            },
                            {
                              value: "5697",
                              label: "Оѐдолчин",
                            },
                            {
                              value: "7211",
                              label: "Угаалгын газар",
                            },
                            {
                              value: "7217",
                              label: "Хивс, бүрээсний цэвэрлэгээ",
                            },
                            {
                              value: "7221",
                              label: "Гэрэл зургийн студи",
                            },
                            {
                              value: "7230",
                              label: "Үсчин, гоо сайхны салон",
                            },
                            {
                              value: "7296",
                              label: "Хувцас түрээс",
                            },
                            {
                              value: "7297",
                              label: "Массажны газар",
                            },
                            {
                              value: "5599",
                              label: "Бусад машин, тоног төхөөрөмжийн худалдаа",
                            },
                            {
                              value: "5044",
                              label:
                                "Гэрэл зураг хэвлэх, хувилах тоног төхөөрөмж, дагалдах хэрэгслийн нийлүүлэлт",
                            },
                            {
                              value: "5045",
                              label:
                                "Компьютер, түүний дагалдах хэрэгсэл, програм хангамжийн төрөлжсөн нийлүүлэлт",
                            },
                            {
                              value: "5046",
                              label:
                                "Бусад үйлдвэрлэлийн тоног төхөөрөмжийн нийлүүлэлт",
                            },
                            {
                              value: "5047",
                              label:
                                "Эрүүл мэнд, шүд, нүд болон эмнэлгийн тоног төхөөрөмж, дагалдах хэрэгслийн нийлүүлэлт",
                            },
                            {
                              value: "5065",
                              label:
                                "Цахилгааны тоног төхөөрөмж, дагалдах хэрэгслийн нийлүүлэлт",
                            },
                            {
                              value: "5072",
                              label:
                                "Техник хэрэгсэл, дагалдах хэрэгслийн нийлүүлэлт",
                            },
                            {
                              value: "5074",
                              label:
                                "Сантехник, дулааны шугам сүлжээний тоноглол, дагалдах хэрэгслийн нийлүүлэлт",
                            },
                            {
                              value: "5094",
                              label:
                                "Үнэт чулуу, метал, цаг, үнэт эдлэлийн нийлүүлэлт",
                            },
                            {
                              value: "5099",
                              label:
                                "Бусад удаан эдэлгээтэй бараа бүтээгдэхүүний худалдаа",
                            },
                            {
                              value: "5111",
                              label:
                                "Бичиг хэрэг, оффисын хангамж, бичгийн болон хэвлэлийн цаасны нийлүүлэлт",
                            },
                            {
                              value: "5122",
                              label:
                                "Эм, эмийн бүтээгдэхүүн, эрүүл мэндийн жижиг хэрэгслийн нийлүүлэлт",
                            },
                            {
                              value: "5131",
                              label: "Төрөлжсөн бус барааны нийлүүлэлт",
                            },
                            {
                              value: "5137",
                              label: "Дүрэмт хувцас, ажлын хувцасны нийлүүлэлт",
                            },
                            {
                              value: "5139",
                              label: "Гутлын ханган нийлүүлэлт",
                            },
                            {
                              value: "5169",
                              label:
                                "Химийн бодис, бусад холбогдох бүтээгдэхүүний нийлүүлэлт",
                            },
                            {
                              value: "5199",
                              label:
                                "Богино хугацаанд хэрэглэгдэх бараа бүтээгдэхүүний нийлүүлэлт",
                            },
                            {
                              value: "5691",
                              label: "Эрэгтэй эмэгтэй бэлэн хувцас",
                            },
                            {
                              value: "763",
                              label: "Хөдөө аж ахуйн нэгдэл",
                            },
                            {
                              value: "4121",
                              label: "Такси, дуудлагын үйлчилгээ",
                            },
                            {
                              value: "4899",
                              label:
                                "Кабель,  сансрын  хиймэл  дагуулаар  телевизийн  шууд  нэвтрүүлэг  дамжуулалт болон бусад төлбөртэй зурагт, радио",
                            },

                            {
                              value: "4900",
                              label: "Орон сууцны контор, СӨХ, ХҮТ",
                            },
                            {
                              value: "5085",
                              label: "Бусад үйлдвэрлэлийн бараа нийлүүлэлт",
                            },
                            {
                              value: "5198",
                              label:
                                "Будаг, лак, орлох бүтээгдэхүүний нийлүүлэлт",
                            },
                            {
                              value: "5511",
                              label:
                                "Шинэ болон хуучин моторт тээврийн хэрэгслийн худалдаа, үйлчилгээ, сэлбэг болон түрээс",
                            },
                            {
                              value: "5965",
                              label:
                                "Шууд маркетинг, каталог ашиглан бүтээгдэхүүн борлуулагч",
                            },
                            {
                              value: "6399",
                              label: "Даатгалын үйлчилгээ",
                            },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Байгууллага / Хувь хүн">
                        <Field
                          required
                          name="corporate_flag"
                          component={core.components.SelectField}
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Сонгох.."
                          options={[
                            { value: "0", label: "Хувь хүн" },
                            { value: "1", label: "Байгууллага" },
                          ]}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-xl-6">
                      <FormGroup required label={`Хот, Аймаг`}>
                        <Field
                          required
                          name="city"
                          placeholder="Сонгох.."
                          component={core.components.SelectField}
                          disabled={isSaving}
                          options={[
                            {
                              value: "11000",
                              label: "Улаанбаатар",
                            },
                            {
                              value: "21000",
                              label: "Дорнод аймаг",
                            },
                            {
                              value: "22000",
                              label: "Сүхбаатар аймаг",
                            },
                            {
                              value: "23000",
                              label: "Хэнтий аймаг",
                            },
                            {
                              value: "41000",
                              label: "Төв аймаг",
                            },
                            {
                              value: "42000",
                              label: "Говьсүмбэр аймаг",
                            },
                            {
                              value: "43000",
                              label: "Сэлэнгэ аймаг",
                            },
                            {
                              value: "44000",
                              label: "Дорноговь аймаг",
                            },
                            {
                              value: "45000",
                              label: "Дархан-уул аймаг",
                            },
                            {
                              value: "46000",
                              label: "Өмнөговь аймаг",
                            },
                            {
                              value: "48000",
                              label: "Дундговь аймаг",
                            },
                            {
                              value: "61000",
                              label: "Орхон аймаг",
                            },
                            {
                              value: "62000",
                              label: "Өвөрхангай аймаг",
                            },
                            {
                              value: "63000",
                              label: "Булган аймаг",
                            },
                            {
                              value: "64000",
                              label: "Баянхонгор аймаг",
                            },
                            {
                              value: "65000",
                              label: "Архангай аймаг",
                            },
                            {
                              value: "67000",
                              label: "Хөвсгөл аймаг",
                            },
                            {
                              value: "81000",
                              label: "Завхан аймаг",
                            },
                            {
                              value: "82000",
                              label: "Говь-алтай аймаг",
                            },
                            {
                              value: "83000",
                              label: "Баянөлгий аймаг",
                            },
                            {
                              value: "84000",
                              label: "Ховд аймаг",
                            },
                            {
                              value: "85000",
                              label: "Увс аймаг",
                            },
                            {
                              value: "99999",
                              label: "Хаяг тодорхойгүй",
                            },
                          ]}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Дүүрэг">
                        <Field
                          required
                          name="district"
                          placeholder="Сонгох.."
                          disabled={isSaving}
                          options={[
                            {
                              value: "12000",
                              label: "Багануур",
                            },
                            {
                              value: "12300",
                              label: "Багахангай",
                            },
                            {
                              value: "12600",
                              label: "Налайх дүүрэг",
                            },
                            {
                              value: "13000",
                              label: "Баянзүрх дүүрэг",
                            },
                            {
                              value: "14000",
                              label: "Сүхбаатар дүүрэг",
                            },
                            {
                              value: "15000",
                              label: "Чингэлтэй дүүрэг",
                            },
                            {
                              value: "16000",
                              label: "Баянгол дүүрэг",
                            },
                            {
                              value: "17000",
                              label: "Хан-Уул",
                            },
                            {
                              value: "18000",
                              label: "Сонгинохайрхан дүүрэг",
                            },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-xl-6">
                      <FormGroup label="Дэлгэрэнгүй хаяг">
                        <Field
                          name="address"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Дэлгэрэнгүй хаяг"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Утас">
                        <Field
                          required
                          name="phone"
                          type="number"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Утас"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-6">
                      <FormGroup required label="Имэйл">
                        <Field
                          required
                          name="email"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Имэйл"
                        />
                      </FormGroup>
                    </div>
                    {corporateFlag === "0" && (
                      <>
                        <div className="col-xl-6">
                          <FormGroup required label="Өөрийн нэр">
                            <Field
                              required
                              name="first_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Өөрийн нэр"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-xl-6">
                          <FormGroup required label="Эцэг эхийн нэр">
                            <Field
                              required
                              name="last_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Эцэг эхийн нэр"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-xl-6">
                          <FormGroup required label="Регистерийн дугаар">
                            <Field
                              required
                              name="register_number"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Регистерийн дугаар"
                            />
                          </FormGroup>
                        </div>
                      </>
                    )}
                    {corporateFlag === "1" && (
                      <>
                        <div className="col-xl-6">
                          <FormGroup required label="Байгууллагын нэр">
                            <Field
                              required
                              name="company_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Байгууллагын нэр"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-xl-6">
                          <FormGroup required label="Байгууллагын регистер">
                            <Field
                              required
                              name="register_number"
                              type="number"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Байгууллагын регистер"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-xl-6">
                          <FormGroup required label="Өөрийн нэр">
                            <Field
                              required
                              name="owner_first_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Өөрийн нэр"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-xl-6">
                          <FormGroup required label="Эцэг эхийн нэр">
                            <Field
                              required
                              name="owner_last_name"
                              type="text"
                              component="input"
                              disabled={isSaving}
                              className="form-control"
                              placeholder="Эцэг эхийн нэр"
                            />
                          </FormGroup>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              {!!(issue.status === "error" && issue.response) && (
                <div className="row">
                  <div className="col-xl-12">
                    <div className="alert alert-warning">
                      Алдаа: {issue.response}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-success">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const IssueFormWithForm = enhancers.form(IssueForm);
const IssueFormWithState = enhancers.redux(IssueFormWithForm);

export default IssueFormWithState;
