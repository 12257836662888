import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import core from "core";
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
  currentUser,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th>Нэр</th>
            <th>Утас</th>
            <th>Username</th>
            <th style={{ width: 150 }}>Огноо</th>
            <th style={{ width: 125 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{index + 1}</td>
              <td>
                {row.last_name} {row.first_name}
              </td>
              <td>{row.phone}</td>
              <td>{row.username || "-"}</td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td style={{ width: 125 }}>
                <a href="#" className="action-icon">
                  {" "}
                  <i className="mdi mdi-eye" />
                </a>
                <Link to={`/admins/edit/${row.id}`} className="action-icon">
                  {" "}
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
                {currentUser.id !== row.id && (
                  <a
                    href="#"
                    className="action-icon"
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        if (!isRemoving) {
                          remove(row.id);
                        }
                      }
                    }}
                  >
                    <i className="mdi mdi-delete" />
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
