import { connect } from 'react-redux';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const loading = selectors.makeLoadingSelector();

export const mapStateToProps = state => ({
  currentUser: selectors.getCurrentUser(state[constants.NAME]),
  isInitializing: loading(state[constants.NAME], constants.INIT),
});

export default Component => connect(mapStateToProps)(Component);
