import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchMessages({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_MESSAGES_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_MESSAGES_COMPLETE,
      payload: {
        messages: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MESSAGES_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchMessageDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_MESSAGE_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_MESSAGE_DETAIL_COMPLETE,
      payload: {
        messageDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MESSAGE_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* changeStatus({ payload: { id, status } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_STATUS_URL,
      {
        id,
        status,
      }
    );
    yield put({
      type: types.CHANGE_STATUS_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_ERROR,
      payload: {
        error,
      },
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_MESSAGES_BEGIN, fetchMessages);
  yield takeLatest(types.FETCH_MESSAGE_DETAIL_BEGIN, fetchMessageDetail);
  yield takeLatest(types.CHANGE_STATUS_BEGIN, changeStatus);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
