export const RESET_ERROR = "@@admins/RESET_ERROR";
export const SET_FILTER = "@@admins/SET_FILTER";
export const FETCH_ADMINS_BEGIN = "@@admins/FETCH_ADMINS_BEGIN";
export const FETCH_ADMINS_COMPLETE = "@@admins/FETCH_ADMINS_COMPLETE";
export const FETCH_ADMINS_ERROR = "@@admins/FETCH_ADMINS_ERROR";
export const FETCH_ADMIN_DETAIL_BEGIN = "@@admins/FETCH_ADMIN_DETAIL_BEGIN";
export const FETCH_ADMIN_DETAIL_COMPLETE =
  "@@admins/FETCH_ADMIN_DETAIL_COMPLETE";
export const FETCH_ADMIN_DETAIL_ERROR = "@@admins/FETCH_ADMIN_DETAIL_ERROR";
export const ADD_ADMIN_BEGIN = "@@admins/ADD_ADMIN_BEGIN";
export const ADD_ADMIN_COMPLETE = "@@admins/ADD_ADMIN_COMPLETE";
export const ADD_ADMIN_ERROR = "@@admins/ADD_ADMIN_ERROR";
export const UPDATE_ADMIN_BEGIN = "@@admins/UPDATE_ADMIN_BEGIN";
export const UPDATE_ADMIN_COMPLETE = "@@admins/UPDATE_ADMIN_COMPLETE";
export const UPDATE_ADMIN_ERROR = "@@admins/UPDATE_ADMIN_ERROR";
export const REMOVE_ADMIN_BEGIN = "@@admins/REMOVE_ADMIN_BEGIN";
export const REMOVE_ADMIN_COMPLETE = "@@admins/REMOVE_ADMIN_COMPLETE";
export const REMOVE_ADMIN_ERROR = "@@admins/REMOVE_ADMIN_ERROR";
export const ADD_ROLES_BEGIN = "@@admins/ADD_ROLES_BEGIN";
export const ADD_ROLES_COMPLETE = "@@admins/ADD_ROLES_COMPLETE";
export const ADD_ROLES_ERROR = "@@admins/ADD_ROLES_ERROR";
export const ADD_MANAGER_BEGIN = "@@admins/ADD_MANAGER_BEGIN";
export const ADD_MANAGER_COMPLETE = "@@admins/ADD_MANAGER_COMPLETE";
export const ADD_MANAGER_ERROR = "@@admins/ADD_MANAGER_ERROR";
