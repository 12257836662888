import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.FETCH_ADMINS:
      return state.isFetchingAdmins;

    case constants.FETCH_ADMIN_DETAIL:
      return state.isFetchingAdminDetail;

    case constants.ADD_ADMIN:
      return state.isAddingAdmins;

    case constants.ADD_MANAGER:
      return state.isAddingManager;

    case constants.ADD_ROLES:
      return state.isAddingRoles;

    case constants.UPDATE_ADMIN:
      return state.isUpdatingAdmins;

    case constants.REMOVE_ADMIN:
      return state.isRemovingAdmins;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getAdmins = createSelector(
  [(state) => state.admins],
  (admins) => admins
);
export const getAdminDetail = createSelector(
  [(state) => state.adminDetail],
  (adminDetail) => adminDetail
);
export const getManagerResult = createSelector(
  [(state) => state.admin],
  (admin) => admin
);
export const getRolesResult = createSelector(
  [(state) => state.result],
  (admin) => admin
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
