import omit from "lodash/omit";

export default class CustomerService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  __PLACEHOLDER_METHOD__() {}
}
