import React from "react";
import moment from "moment";
import classNames from "classnames";
import { reduxForm, Field } from "redux-form";

import core from "core";

function DomainPanel({
  handleSubmit,
  checkDomain,
  removeDomain,
  isLinkingDomain,
  isCheckingDomain,
  isRemovingDomain,
  domains = [],
}) {
  return (
    <div id="shop-domains" className="tab-pane fade" role="tabpanel">
      {domains.length === 0 && (
        <div className="row mb-2">
          <div className="col-lg-12">
            <form
              onSubmit={handleSubmit}
              className="form-inline shop-filter-form"
            >
              <div style={{ width: 250 }} className="form-group mr-2">
                <Field
                  style={{ width: 250 }}
                  required
                  disabled={
                    isLinkingDomain || isCheckingDomain || isRemovingDomain
                  }
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="Домэйн нэр"
                  name="domain"
                />
              </div>
              {/* <div style={{ width: 250 }} className="form-group mr-2">
                <Field
                  required
                  name="theme"
                  disabled={
                    isLinkingDomain || isCheckingDomain || isRemovingDomain
                  }
                  placeholder="Дизайн сонгох..."
                  options={[
                    { label: "Сошиал", value: "default" },
                    { label: "Минимал", value: "legacy" },
                  ]}
                  component={core.components.SelectField}
                />
              </div> */}
              <div className="form-group">
                <button
                  disabled={
                    isLinkingDomain || isCheckingDomain || isRemovingDomain
                  }
                  className="btn btn-primary text-bold"
                >
                  ХОЛБОХ
                </button>
              </div>
            </form>
            <p className="alert alert-info mt-2">
              <strong>АНХААР!!!</strong> Энэ хэсгийг болгоомжтой ашиглана уу.
              Бүртгэлгүй эсвэл алдаатай бичсэн домэйн нэр оруулж буй эсэхээ
              шалгаарай.
            </p>
          </div>
        </div>
      )}

      {domains.length > 0 && (
        <div className="table-responsive">
          <table className="shops-table table table-centered mb-0">
            <thead className="thead-light">
              <tr>
                <th style={{ width: 20 }}>#</th>
                <th>Нэр</th>
                <th style={{ width: 150 }}>Төлөв</th>
                <th style={{ width: 150 }}>SSL Expire</th>
                <th style={{ width: 200 }}>IP төлөв</th>
                <th className="text-center" style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {(domains || []).map((row, index) => (
                <tr key={`tr_${row.id}`}>
                  <td>{index + 1}</td>
                  <td className="name">{row.name}</td>
                  <td>
                    <span
                      className={classNames("badge p-1", {
                        "badge-success": row.configured === true,
                        "badge-warning": !row.configured,
                      })}
                    >
                      {!!row.configured && "идэвхтэй"}
                      {!row.configured && "хүлээгдэж байгаа"}
                    </span>
                  </td>
                  <td>
                    {!row.expire_at
                      ? "-"
                      : moment(row.expire_at).format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>
                    {!row.ip_valid && (
                      <span className="text-danger">
                        <i className="uil-times" /> Чиглээгүй
                      </span>
                    )}
                    {!!row.ip_valid && (
                      <span className="text-success">
                        <i className="uil-check" /> Чиглэсэн
                      </span>
                    )}
                  </td>
                  <td className="text-center" style={{ width: 50 }}>
                    {!row.ip_valid && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        className={"action-icon text-success"}
                        onClick={(event) => {
                          event.preventDefault();
                          checkDomain(row.merchant_id, row.name);
                        }}
                      >
                        <i className="uil-refresh" />
                      </a>
                    )}
                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <a
                      href="#"
                      className={"action-icon text-danger"}
                      onClick={(event) => {
                        event.preventDefault();
                        if (window.confirm("Та итгэлтэй байна уу?")) {
                          if (!isRemovingDomain) {
                            removeDomain(row.id);
                          }
                        }
                      }}
                    >
                      <i className="uil-times-square" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "domainPanel",
  enableReinitialize: true,
})(DomainPanel);
