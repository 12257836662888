import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const settlementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SETTLEMENTS_BEGIN:
      return {
        ...state,
        isFetchingSettlements: true
      };

    case types.FETCH_SETTLEMENTS_COMPLETE:
      return {
        ...state,
        isFetchingSettlements: false,
        settlements: action.payload.settlements
      };

    case types.FETCH_SETTLEMENTS_ERROR:
      return {
        ...state,
        isFetchingSettlements: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SETTLEMENTS]: action.payload.error.message
        }
      };

    case types.FETCH_SETTLEMENT_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingSettlementDetail: true
      };

    case types.FETCH_SETTLEMENT_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingSettlementDetail: false,
        settlementDetail: action.payload.settlementDetail
      };

    case types.FETCH_SETTLEMENT_DETAIL_ERROR:
      return {
        ...state,
        isFetchingSettlementDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SETTLEMENT_DETAIL]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    default:
      return state;
  }
};

export default settlementsReducer;
