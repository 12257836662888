export const RESET_ERROR = "@@bills/RESET_ERROR";
export const SET_FILTER = "@@bills/SET_FILTER";
export const FETCH_BILLS_BEGIN = "@@bills/FETCH_BILLS_BEGIN";
export const FETCH_BILLS_COMPLETE = "@@bills/FETCH_BILLS_COMPLETE";
export const FETCH_BILLS_ERROR = "@@bills/FETCH_BILLS_ERROR";
export const FETCH_BILL_DETAIL_BEGIN = "@@bills/FETCH_BILL_DETAIL_BEGIN";
export const FETCH_BILL_DETAIL_COMPLETE = "@@bills/FETCH_BILL_DETAIL_COMPLETE";
export const FETCH_BILL_DETAIL_ERROR = "@@bills/FETCH_BILL_DETAIL_ERROR";
export const CHANGE_BILL_STATUS_BEGIN = "@@bills/CHANGE_BILL_STATUS_BEGIN";
export const CHANGE_BILL_STATUS_COMPLETE =
  "@@bills/CHANGE_BILL_STATUS_COMPLETE";
export const CHANGE_BILL_STATUS_ERROR = "@@bills/CHANGE_BILL_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
