import omit from "lodash/omit";
export default class ChannelService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchChannels({ filter, options }) {
    return await this.fetchUtil.sendGet(
      `/sale-channel-requests/list${this.fetchUtil.qs({
        filter,
        options,
      })}`
    );
  }

  __PLACEHOLDER_METHOD__() {}
}
