const DISTRICT_MAPS = {
  "1": "Сонгинохайрхан",
  "5": "Баянгол",
  "7": "Баянзүрх",
  "4": "Сүхбаатар",
  "6": "Чингэлтэй",
  "2": "Налайх",
  "8": "Багануур",
  "9": "Багахангай",
  "3": "Хан-Уул",
}

const CATEGORY_MAPS = {
  "14": "Авто худалдаа",
  "15": "Амьтны дэлгүүр",
  "16": "Арьсан эдлэл",
  "17": "Аяны хэрэгсэл",
  "18": "Барилгын материал",
  "19": "Бөс бараа, Торго",
  "20": "Бэлэг дурсгал",
  "21": "Бэлэн хувцас",
  "22": "Гар урлал",
  "23": "Гоёл чимэглэл, Аксесуар (Нүдний шил, цүнх, түрийвч)",
  "24": "Гоо сайхны бараа",
  "25": "Гэр ахуйн бараа",
  "26": "Монгол гэр, туслах хэрэгсэл",
  "27": "Номын худалдаа, Бичиг хэрэг",
  "28": "Спортын бараа",
  "29": "Тавилгын худалдаа",
  "30": "Тоглоом",
  "31": "Унадаг дугуй худалдаа",
  "32": "Үнэт эдлэл, эртний эдлэл",
  "33": "Хөгжмийн хэрэгсэл, тоног төхөөрөмж ",
  "34": "Хүнсний бүтээгдэхүүн",
  "35": "Шашны эдлэл",
  "36": "Электрон бараа (Компьютер, гар утас)",
  "37": "Бусад бараа",
  "44": "Сонин борлуулах цэг"
}

const TOWN_MAPS = {
  "15782385" :"1-р хороо",
  "15782836" :"10-р хороо",
  "15782837" :"11-р хороо",
  "15782838" :"12-р хороо",
  "15782839" :"13-р хороо",
  "15782840" :"14-р хороо",
  "15782841" :"15-р хороо",
  "15782842" :"16-р хороо",
  "15782843" :"17-р хороо",
  "15782844" :"18-р хороо",
  "15782845" :"19-р хороо",
  "15782387" :"2-р хороо",
  "15782846" :"20-р хороо",
  "15782847" :"21-р хороо",
  "15782848" :"22-р хороо",
  "15782849" :"23-р хороо",
  "15782850" :"24-р хороо",
  "15782851" :"25-р хороо",
  "15782386" :"26-р хороо",
  "15782823" :"27-р хороо",
  "15782825" :"28-р хороо",
  "15782827" :"29-р хороо",
  "15782824" :"3-р хороо",
  "15782829" :"30-р хороо",
  "15782831" :"31-р хороо",
  "15782833" :"32-р хороо",
  "15782852" :"33-р хороо",
  "15782853" :"34-р хороо",
  "15782854" :"35-р хороо",
  "15782855" :"36-р хороо",
  "15782856" :"37-р хороо",
  "15782857" :"38-р хороо",
  "15782858" :"39-р хороо",
  "15782826" :"4-р хороо",
  "15782859" :"40-р хороо",
  "15782860" :"41-р хороо",
  "15782861" :"42-р хороо",
  "15782862" :"43-р хороо",
  "15782828" :"5-р хороо",
  "15782830" :"6-р хороо",
  "15782832" :"7-р хороо",
  "15782834" :"8-р хороо",
  "15782835" :"9-р хороо",
  "15782324" :"1-р хороо",
  "15782333" :"10-р хороо",
  "15782334" :"11-р хороо",
  "15782335" :"12-р хороо",
  "15782336" :"13-р хороо",
  "15782337" :"14-р хороо",
  "15782338" :"15-р хороо",
  "15782339" :"16-р хороо",
  "15782340" :"17-р хороо",
  "15782341" :"18-р хороо",
  "15782342" :"19-р хороо",
  "15782325" :"2-р хороо",
  "15782343" :"20-р хороо",
  "15782344" :"21-р хороо",
  "15782345" :"22-р хороо",
  "15782346" :"23-р хороо",
  "15782347" :"24-р хороо",
  "15782348" :"25-р хороо",
  "15782938" :"26-р хороо",
  "15782939" :"27-р хороо",
  "15782940" :"28-р хороо",
  "15782941" :"29-р хороо",
  "15782326" :"3-р хороо",
  "15782942" :"30-р хороо",
  "15782943" :"31-р хороо",
  "15782944" :"32-р хороо",
  "15782945" :"33-р хороо",
  "15782946" :"34-р хороо",
  "15782327" :"4-р хороо",
  "15782328" :"5-р хороо",
  "15782329" :"6-р хороо",
  "15782330" :"7-р хороо",
  "15782331" :"8-р хороо",
  "15782332" :"9-р хороо",
  "15782349" :"1-р хороо",
  "15782361" :"10-р хороо",
  "15782362" :"11-р хороо",
  "15782363" :"12-р хороо",
  "15782364" :"13-р хороо",
  "15782365" :"14-р хороо",
  "15782366" :"15-р хороо",
  "15782367" :"16-р хороо",
  "15782368" :"17-р хороо",
  "15782369" :"18-р хороо",
  "15782370" :"19-р хороо",
  "15782351" :"2-р хороо",
  "15782371" :"20-р хороо",
  "15782372" :"21-р хороо",
  "15782373" :"22-р хороо",
  "15782374" :"23-р хороо",
  "15782375" :"24-р хороо",
  "15782376" :"25-р хороо",
  "15782350" :"26-р хороо",
  "15782352" :"27-р хороо",
  "15782354" :"28-р хороо",
  "15782923" :"29-р хороо",
  "15782353" :"3-р хороо",
  "15782924" :"30-р хороо",
  "15782925" :"31-р хороо",
  "15782926" :"32-р хороо",
  "15782927" :"33-р хороо",
  "15782928" :"34-р хороо",
  "15782929" :"35-р хороо",
  "15782930" :"36-р хороо",
  "15782931" :"37-р хороо",
  "15782932" :"38-р хороо",
  "15782933" :"39-р хороо",
  "15782355" :"4-р хороо",
  "15782934" :"40-р хороо",
  "15782935" :"41-р хороо",
  "15782936" :"42-р хороо",
  "15782937" :"43-р хороо",
  "15782356" :"5-р хороо",
  "15782357" :"6-р хороо",
  "15782358" :"7-р хороо",
  "15782359" :"8-р хороо",
  "15782360" :"9-р хороо",
  "15782863" :"1-р хороо",
  "15782872" :"10-р хороо",
  "15782873" :"11-р хороо",
  "15782874" :"12-р хороо",
  "15782875" :"13-р хороо",
  "15782876" :"14-р хороо",
  "15782877" :"15-р хороо",
  "15782878" :"16-р хороо",
  "15782879" :"17-р хороо",
  "15782880" :"18-р хороо",
  "15782881" :"19-р хороо",
  "15782864" :"2-р хороо",
  "15782882" :"20-р хороо",
  "15782865" :"3-р хороо",
  "15782866" :"4-р хороо",
  "15782867" :"5-р хороо",
  "15782868" :"6-р хороо",
  "15782869" :"7-р хороо",
  "15782870" :"8-р хороо",
  "15782871" :"9-р хороо",
  "15782904" :"1-р хороо",
  "15782913" :"10-р хороо",
  "15782914" :"11-р хороо",
  "15782915" :"12-р хороо",
  "15782916" :"13-р хороо",
  "15782917" :"14-р хороо",
  "15782918" :"15-р хороо",
  "15782919" :"16-р хороо",
  "15782920" :"17-р хороо",
  "15782921" :"18-р хороо",
  "15782922" :"19-р хороо",
  "15782905" :"2-р хороо",
  "15782947" :"20-р хороо",
  "15782948" :"21-р хороо",
  "15782949" :"22-р хороо",
  "15782906" :"3-р хороо",
  "15782907" :"4-р хороо",
  "15782908" :"5-р хороо",
  "15782909" :"6-р хороо",
  "15782910" :"7-р хороо",
  "15782911" :"8-р хороо",
  "15782912" :"9-р хороо",
  "15782377" :"1-р хороо",
  "15782378" :"2-р хороо",
  "15782379" :"3-р хороо",
  "15782380" :"4-р хороо",
  "15782381" :"5-р хороо",
  "15782382" :"6-р хороо",
  "15782383" :"7-р хороо",
  "15782384" :"8-р хороо",
  "15782317" :"1-р хороо",
  "15782318" :"2-р хороо",
  "15782319" :"3-р хороо",
  "15782320" :"4-р хороо",
  "15782321" :"5-р хороо",
  "15782322" :"1-р хороо",
  "15782323" :"2-р хороо",
  "15782883" :"1-р хороо",
  "15782892" :"10-р хороо",
  "15782893" :"11-р хороо",
  "15782894" :"12-р хороо",
  "15782895" :"13-р хороо",
  "15782896" :"14-р хороо",
  "15782897" :"15-р хороо",
  "15782898" :"16-р хороо",
  "15782899" :"17-р хороо",
  "15782900" :"18-р хороо",
  "15782901" :"19-р хороо",
  "15782884" :"2-р хороо",
  "15782902" :"20-р хороо",
  "15782903" :"21-р хороо",
  "15782950" :"22-р хороо",
  "15782951" :"23-р хороо",
  "15782952" :"24-р хороо",
  "15782953" :"25-р хороо",
  "15782885" :"3-р хороо",
  "15782886" :"4-р хороо",
  "15782887" :"5-р хороо",
  "15782888" :"6-р хороо",
  "15782889" :"7-р хороо",
  "15782890" :"8-р хороо",
  "15782891" :"9-р хороо",
}

const BANKS_MAPS = {
  "050000": "Хаан банк",
  "150000": "Голомт банк",
  "040000": "ХХБ",
  "320000": "Хас банк",
  "340000": "Төрийн банк",
  "300000": "Капитрон банк",
  "210000": "Ариг банк",
  "290000": "Үндэсний хөрөнгө оруулалтын банк",
  "330000": "Чингис хаан банк",
  "900000": "Төрийн сан",
  "380000": "Банк банк",
  "190000": "Транс банк",
}

export default class IssueService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchIssues({ filter, options }) {
    const { data: issues, total: totalCount } = await this.fetchUtil.sendGet(
      `/issues/list${this.fetchUtil.qs({
        filter,
        options
      })}`
    );

    return { issues, totalCount }
  }

  async fetchIssueDetail({ id }) {
    const TYPE_LABELS = {
      monpay: "MonPay холбох",
      qpay: "Qpay холбох",
      socialpay: "SocialPay холбох",
      zochil_checkout: "Zochil Checkout холбох",
    };
    const { data } = await this.fetchUtil.sendGet(`/issues/detail?id=${id}`);
    let theIssue = {
      ...data
    };

    if (theIssue) {
      theIssue = {
        ...theIssue,
        ...theIssue.metadata
      };
      theIssue.issue_type = {
        id: theIssue.issue_type,
        name: TYPE_LABELS[theIssue.issue_type]
      };
      theIssue.merchant_id = {
        id: theIssue.merchant_id,
        name: theIssue.merchant_name
      };

      if (theIssue.qpay_account_bank) {
        theIssue.qpay_account_bank = {
          id: theIssue.qpay_account_bank,
          name: theIssue.qpay_account_bank
        };
      }

      if (theIssue.category_code) {
        theIssue.category_code = {
          id: theIssue.category_code,
          name: CATEGORY_MAPS[theIssue.category_code] || ""
        };
      }
  
      if (theIssue.corporate_flag) {
        theIssue.corporate_flag = {
          id: theIssue.corporate_flag,
          name: theIssue.corporate_flag === "0" ? "Хувь хүн" : "Байгууллага",
        };
      }
  
      if (theIssue.checkout_account_bank) {
        theIssue.checkout_account_bank = {
          id: theIssue.checkout_account_bank,
          name: BANKS_MAPS[theIssue.checkout_account_bank],
        };
      }
  
      if (theIssue.address_district) {
        theIssue.address_district = {
          id: theIssue.address_district,
          name: DISTRICT_MAPS[theIssue.address_district],
        };
      }
  
      if (theIssue.address_town) {
        theIssue.address_town = {
          id: theIssue.address_town,
          name: TOWN_MAPS[theIssue.address_town],
        };
      }
    }

    return theIssue;
  }

  async addIssue({ issue }) {
    await this.fetchUtil.sendPost("/issues/create", issue);
  }

  async updateIssue({ id, issue }) {
    await this.fetchUtil.sendPost("/issues/update", {
      ...issue,
      id
    });
  }

  async removeIssue({ id }) {
    await this.fetchUtil.sendPost("/issues/remove", {
      id
    });
  }

  async changeStatus({ id, status }) {
    await this.fetchUtil.sendPost("/issues/change-status", {
      id,
      status,
    });
  }

  __PLACEHOLDER_METHOD__() {}
}
