import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const coreReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_BEGIN:
      return {
        ...state,
        isLogging: true
      };

    case types.LOGIN_COMPLETE:
      return {
        ...state,
        isLogging: false,
        currentUser: action.payload.currentUser
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        isLogging: false,
        errors: {
          ...state.errors,
          [constants.LOGIN]: action.payload.error.message
        }
      };

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.LOGOUT_BEGIN:
      return {
        ...state,
        isLoggingOut: true
      };

    case types.LOGOUT_COMPLETE:
      return {
        ...state,
        isLoggingOut: false,
        currentUser: null
      };

    case types.LOGOUT_ERROR:
      return {
        ...state,
        isLoggingOut: false,
        errors: {
          ...state.errors,
          [constants.LOGOUT]: action.payload.error.message
        }
      };

    case types.INIT_BEGIN:
      return {
        ...state,
        isInitializing: true
      };

    case types.INIT_COMPLETE:
      return {
        ...state,
        isInitializing: false,
        currentUser: action.payload.currentUser
      };

    case types.INIT_ERROR:
      return {
        ...state,
        isInitializing: false,
        errors: {
          ...state.errors,
          [constants.INIT]: action.payload.error.message
        }
      };

    case types.SUGGEST_SHOP_BEGIN:
      return {
        ...state,
        isSuggestingShop: true
      };

    case types.SUGGEST_SHOP_COMPLETE:
      return {
        ...state,
        isSuggestingShop: false,
        suggestedShop: action.payload.suggestedShop
      };

    case types.SUGGEST_SHOP_ERROR:
      return {
        ...state,
        isSuggestingShop: false,
        errors: {
          ...state.errors,
          [constants.SUGGEST_SHOP]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    default:
      return state;
  }
};

export default coreReducer;
