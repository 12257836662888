import moment from "moment";
import { Field } from "redux-form";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";
import { Link } from "react-router-dom";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function CampaignForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  shops = [],
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearForm(), []);
  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    goBackList
  );

  const isSaving = isAdding || isUpdating;

  return (
    <Fragment>
      <PageTitle title={`Купон ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Купон нэр">
                    <Field
                      required
                      name="name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Купон нэр"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Купон код">
                    <Field
                      required
                      name="coupon_code"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Купон код"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Тоо хэмжээ">
                    <Field
                      required
                      name="coupon_count"
                      type="number"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Тоо хэмжээ"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup label="Дуусах хугацаа">
                    <Field
                      name="expire_at"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Дуусах хугацаа"
                      dateFormat="yyyy-MM-dd"
                      component={core.components.DateTimePicker}
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Хямдралын хувь">
                    <Field
                      required
                      name="discount_percent"
                      type="number"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Хямдралын хувь"
                    />
                  </FormGroup>
                </div>
                {isEdit && (
                  <div className="col-xl-4">
                    <div className="form-group">
                      <FormGroup classNames="clearfix" label="Төлөв">
                        <Field
                          name="status"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Сонгох..."
                          options={[
                            { label: "Идэвхтэй", value: "enabled" },
                            { label: "Идэвхгүй", value: "disabled" },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const CampaignFormWithForm = enhancers.form(CampaignForm);
const CampaignFormWithState = enhancers.redux(CampaignFormWithForm);

export default CampaignFormWithState;
