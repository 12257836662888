import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchSettlements({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_SETTLEMENTS_URL,
      {
        filter,
        options
      }
    );
    yield put({
      type: types.FETCH_SETTLEMENTS_COMPLETE,
      payload: {
        settlements: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SETTLEMENTS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchSettlementDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_SETTLEMENT_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_SETTLEMENT_DETAIL_COMPLETE,
      payload: {
        settlementDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SETTLEMENT_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_SETTLEMENTS_BEGIN, fetchSettlements);
  yield takeLatest(types.FETCH_SETTLEMENT_DETAIL_BEGIN, fetchSettlementDetail);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
