import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect } from "react";
import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";
const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function CampaignList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  fetchCampaigns,
  currentUser,
  limit,
  pages,
  updateQueryString,
  isRemoving,
  remove,
}) {
  useEffect(() => {
    fetchCampaigns(currentPage, filter);
  }, [currentPage, filter, isRemoving]);

  return (
    <Fragment>
      <PageTitle title="Купонууд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/campaigns/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["name", "phone"]);

                if (formData.start) {
                  theFilter.start = formData.start.toISOString();
                }

                if (formData.end) {
                  theFilter.end = formData.end.toISOString();
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined ||
          isFetching === true ||
          isRemoving === true ? (
            <Loading />
          ) : (
            <>
              <Table
                currentPage={currentPage}
                limit={limit}
                rows={fetchResult.campaigns}
                currentUser={currentUser}
                remove={remove}
                isRemoving={isRemoving}
              />
              <Pagination
                url="/campaigns"
                pages={pages}
                currentPage={currentPage}
              />
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const CampaignListWithState = enhancers.redux(CampaignList);
export default CampaignListWithState;
