import pick from "lodash/pick";
import queryString from "query-string";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { formValueSelector } from "redux-form";

import core from "core";
import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();
const formSelector = formValueSelector("shopUpdateForm");

const THEME_MAP = {
  default: { id: "default", name: "Сошиал" },
  legacy: { id: "legacy", name: "Минимал" },
};

export const mapStateToProps = (state, { match, location: { search } }) => ({
  isEdit: !!match.params.id,
  shopType: (queryString.parse(search) || {}).status,
  currentPage: (queryString.parse(search) || {}).page,
  has_chatbot: (formSelector(state, "has_chatbot") || {}).id,
  settlement_status: (formSelector(state, "settlement_status") || {}).id,
  order_sms_enabled: (formSelector(state, "order_sms_enabled") || {}).id,
  custom_domain: formSelector(state, "custom_domain"),
  updateError: error(state[constants.NAME], constants.UPDATE_SHOP),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_SHOP),
  isFetching: loading(state[constants.NAME], constants.FETCH_SHOP_DETAIL),
  shopCategories: selectors.getShopCategories(state[constants.NAME]) || [],
  initialValues: !match.params.id
    ? {}
    : {
        ...pick(selectors.getShopDetail(state[constants.NAME]), [
          "name",
          "uid",
          "logo",
          "lng",
          "lat",
          "email",
          "phone",
          "address",
          "expire_at",
          "youtube_url",
          "facebook_url",
          "instagram_url",
          "custom_domain",
          "checkout_form_type",
          "chatbot_product_home_image_url",
          "chatbot_product_all_image_url",
          "chatbot_product_sales_image_url",
          "chatbot_product_featured_image_url",
          "chatbot_post_home_image_url",
          "chatbot_contact_home_image_url",
          "chatbot_contact_call_now_image_url",
          "chatbot_contact_branches_now_image_url",
          "chatbot_help_faq_image_url",
          "chatbot_help_about_image_url",
          "chatbot_help_terms_image_url",
          "main_account_number",
          "main_account_holder",
          "order_min_limit",
          "order_max_limit",
          "mainBanner1",
          "mainBanner2",
          "mainBanner3",
          "order_sms_template_1",
          "order_sms_template_2",
        ]),
        sale_channels: (
          (selectors.getShopDetail(state[constants.NAME]) || {})
            .sale_channels || []
        ).map((sc) => ({
          id: sc,
          name: sc,
        })),
        enabled_delivery_companies: (
          (selectors.getShopDetail(state[constants.NAME]) || {})
            .enabled_delivery_companies || []
        ).map((company) => ({
          id: company,
          name: company,
        })),
        has_chatbot:
          (selectors.getShopDetail(state[constants.NAME]) || {}).has_chatbot ===
          "1"
            ? { id: "enabled", name: "Идэвхтэй" }
            : { id: "disabled", name: "Идэвхгүй" },
        order_sms_enabled:
          (selectors.getShopDetail(state[constants.NAME]) || {})
            .order_sms_enabled === "1"
            ? { id: "enabled", name: "Идэвхтэй" }
            : { id: "disabled", name: "Идэвхгүй" },
        settlement_status:
          (selectors.getShopDetail(state[constants.NAME]) || {})
            .settlement_status === "enabled"
            ? { id: "enabled", name: "Идэвхтэй" }
            : { id: "disabled", name: "Идэвхгүй" },
        theme:
          THEME_MAP[
            (selectors.getShopDetail(state[constants.NAME]) || {}).theme ||
              "default"
          ],
        main_account_bank: !!(
          selectors.getShopDetail(state[constants.NAME]) || {}
        ).main_account_bank && {
          id: (selectors.getShopDetail(state[constants.NAME]) || {})
            .main_account_bank,
          name: core.helpers.PM_MAP[
            (selectors.getShopDetail(state[constants.NAME]) || {})
              .main_account_bank
          ],
        },
        category_codes: getCategoryNames(
          selectors.getShopCategories(state[constants.NAME]) || [],
          (selectors.getShopDetail(state[constants.NAME]) || {})
            .category_codes || []
        ),
      },
});
export const mapDispatchToProps = (
  dispatch,
  { match: { params }, location: { search } }
) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchShopDetail({ id: params.id }));
    }
  },
  fetchCategories() {
    dispatch(actions.fetchCategories());
  },
  onSubmit(shop) {
    if (shop.has_chatbot) {
      shop.has_chatbot = shop.has_chatbot.id;
    }

    if (shop.order_sms_enabled) {
      shop.order_sms_enabled = shop.order_sms_enabled.id;
    }

    if (shop.theme) {
      shop.theme = shop.theme.id;
    }

    if (shop.checkout_form_type) {
      shop.checkout_form_type = shop.checkout_form_type.id;
    }

    if (shop.settlement_status) {
      shop.settlement_status = shop.settlement_status.id;
    }

    if (shop.sale_channels) {
      shop.sale_channels = shop.sale_channels.map((sc) => sc.id);
    }

    if (shop.enabled_delivery_companies) {
      shop.enabled_delivery_companies = shop.enabled_delivery_companies.map(
        (sc) => sc.id
      );
    }

    if (shop.category_codes) {
      shop.category_codes = shop.category_codes.map((cc) => cc.id);
    }

    for (const bannerIndex of [1, 2, 3]) {
      if (shop[`mainBanner${bannerIndex}`]) {
        shop[`mainBanner${bannerIndex}`] = shop[
          `mainBanner${bannerIndex}`
        ].replace("_t500", "");
      }
    }

    if (shop.main_account_bank) {
      shop.main_account_bank = shop.main_account_bank.id;
    }

    dispatch(actions.updateShop({ id: params.id, shop }));
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.UPDATE_SHOP }));
  },
  clearForm() {},
  goBackList(page, shopType) {
    dispatch(push(`/shops?page=${page}&shopType=${shopType}`));
  },
});

function getCategoryNames(categories, codes) {
  return categories
    .filter((c) => codes.indexOf(c.code) > -1)
    .map((c) => ({
      name: c.name,
      id: c.code,
    }));
}

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
