import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
  isChangingStatus,
  changeStatus,
}) {
  return (
    <div className="table-responsive">
      <table className="shops-table table table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th>Төлөв</th>
            <th>Нэр</th>
            <th>Овог</th>
            <th>Компаний нэр</th>
            <th>Компаний регистер</th>
            <th>Имэйл</th>
            <th>Утас</th>
            <th style={{ width: 120 }}>Гэрээний дугаар</th>
            <th style={{ width: 100 }}>Бүтэн нэр</th>
            <th style={{ width: 150 }}>Дэлгүүрийн нэр</th>
            <th style={{ width: 150 }}>Үүссэн</th>
            <th className="text-center" style={{ width: 120 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td style={{ width: 100 }}>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "approved",
                    "badge-warning": row.status === "pending",
                    "badge-danger": row.status === "cancelled",
                  })}
                >
                  {row.status === "approved" && "хүлээн авсан"}
                  {row.status === "pending" && "хүлээгдэж буй"}
                  {row.status === "cancelled" && "буцаагдсан"}
                </span>
              </td>
              <td>{row.first_name}</td>
              <td>{row.last_name}</td>
              <td>{row.company_name}</td>
              <td>{row.company_regno}</td>
              <td>{row.email}</td>
              <td>{row.phone}</td>
              <td>{row.contractno}</td>
              <td className="text-center" style={{ width: 100 }}>
                {row.full_name}
              </td>
              <td className="text-center" style={{ width: 100 }}>
                {row.merchant_name}
              </td>
              <td className="text-center" style={{ width: 100 }}>
                {!row.created_at
                  ? "-"
                  : moment(row.created_at).format("YYYY-MM-DD")}
              </td>
              <td style={{ width: 125 }}>
                {/* {row.status === "pending" && (
                  <a
                    href="#"
                    className="action-icon"
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        changeStatus(row.id, "resolved");
                      }
                    }}
                  >
                    <i className="uil-check-circle" />
                  </a>
                )} */}
                <Link
                  to={`/contracts/detail/${row.id}`}
                  className="action-icon"
                >
                  {" "}
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
