import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";
import Shops from "./partials/shops";
import Summary from "./partials/summary";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function BroadcastDetail({
  isFetching,
  isSending,
  filter,
  setFilter,
  clearFilter,
  limit,
  currentPage,
  broadcast,
  fetchDetail,
  shops,
  isFetchingShops,
  fetchShops,
  shopIds,
  setShopIds,
  pages,
  sendBroadcast,
}) {
  useEffect(() => () => setShopIds([]), []);
  useEffect(() => fetchDetail(), []);

  useEffect(() => {
    if (isSending === false) {
      fetchDetail();
    }
  }, [isSending]);

  useEffect(() => {
    if (
      broadcast.status === "pending" &&
      broadcast.broadcasttype === "partial"
    ) {
      fetchShops(currentPage, { status: "active", ...filter });
    }
  }, [currentPage, filter, broadcast]);

  const isLoading = isSending || isFetching;

  return (
    <Fragment>
      <PageTitle title="Зарлалын дэлгэрэнгүй" />
      {isLoading === undefined || isLoading === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-3">
            <Summary
              sendBroadcast={sendBroadcast}
              shopIds={shopIds}
              broadcast={broadcast}
            />
          </div>
          <div className="col-lg-9">
            <p
              className={classNames("alert", {
                "alert-success": broadcast.status === "done",
                "alert-info": broadcast.status === "in_progress",
                "alert-warning": broadcast.status === "pending",
              })}
            >
              {broadcast.status === "done" && "Зарлалыг амжилттай илгээсэн."}
              {broadcast.status === "in_progress" && "Зарлалыг илгээж байна."}
              {broadcast.status === "pending" &&
                'Та зүүн талд байгаа "ИЛГЭЭХ" товчин дээр дарж зарлалаа илгээнэ үү.'}
            </p>
            {broadcast.status === "pending" &&
              broadcast.broadcasttype === "partial" && (
                <Shops
                  rows={shops}
                  limit={limit}
                  currentPage={currentPage}
                  filter={filter}
                  clearFilter={clearFilter}
                  isFetching={isFetchingShops}
                  shopIds={shopIds}
                  setShopIds={setShopIds}
                  pages={pages}
                  broadcast_id={broadcast.id}
                  onSubmit={(formData) => {
                    if (!isEqual(formData, {})) {
                      const theFilter = pick(formData, ["name", "phone"]);

                      if (formData.status) {
                        theFilter.status = formData.status.id;
                      }

                      setFilter(theFilter);
                    }
                  }}
                />
              )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

const BroadcastDetailWithState = enhancers.redux(BroadcastDetail);
export default BroadcastDetailWithState;
