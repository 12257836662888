import omit from "lodash/omit";
export default class DashboardService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async changePassword({ currentPassword, newPassword }) {
    await this.fetchUtil.sendPost(
      "/admins/change-password",
      {
        new_password: newPassword,
        current_password: currentPassword,
      },
      true
    );
  }

  async fetchSummary() {
    return await this.fetchUtil.sendGet(`/analytics/summary`);
  }

  async fetchTopHits() {
    return await this.fetchUtil.sendGet(`/analytics/top-hits`);
  }

  async fetchDailyHits({ start, end }) {
    return await this.fetchUtil.sendGet(
      `/analytics/hits-by-day?start=${start}&end=${end}`
    );
  }

  async fetchMonthlyHits() {
    return await this.fetchUtil.sendGet(`/analytics/hits-by-month`);
  }

  async fetchDailyOrders({ start, end }) {
    return await this.fetchUtil.sendGet(
      `/analytics/orders-by-day?start=${start}&end=${end}`
    );
  }

  async fetchMonthlyOrders() {
    return await this.fetchUtil.sendGet(`/analytics/orders-by-month`);
  }

  __PLACEHOLDER_METHOD__() {}
}
