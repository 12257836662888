import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const billsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_BEGIN:
      return {
        ...state,
        isFetchingBills: true
      };

    case types.FETCH_BILLS_COMPLETE:
      return {
        ...state,
        isFetchingBills: false,
        bills: action.payload.bills
      };

    case types.FETCH_BILLS_ERROR:
      return {
        ...state,
        isFetchingBills: false,
        errors: {
          ...state.errors,
          [constants.FETCH_BILLS]: action.payload.error.message
        }
      };

    case types.FETCH_BILL_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingBillDetail: true
      };

    case types.FETCH_BILL_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingBillDetail: false,
        billDetail: action.payload.billDetail
      };

    case types.FETCH_BILL_DETAIL_ERROR:
      return {
        ...state,
        isFetchingBillDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_BILL_DETAIL]: action.payload.error.message
        }
      };

    case types.CHANGE_BILL_STATUS_BEGIN:
      return {
        ...state,
        isChangingBillStatus: true
      };

    case types.CHANGE_BILL_STATUS_COMPLETE:
      return {
        ...state,
        isChangingBillStatus: false
      };

    case types.CHANGE_BILL_STATUS_ERROR:
      return {
        ...state,
        isChangingBillStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_BILL_STATUS]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    default:
      return state;
  }
};

export default billsReducer;
