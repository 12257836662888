import core from "core";
import bills from "app/bills";
import shops from "app/shops";
import users from "app/users";
import orders from "app/orders";
import admins from "app/admins";
import issues from "app/issues";
import products from "app/products";
import messages from "app/messages";
import channels from "app/channels";
import dashboard from "app/dashboard";
import customers from "app/customers";
import activities from "app/activities";
import settlements from "app/settlements";
import transactions from "app/transactions";
import payments from "app/payments";
import notifications from "app/notifications";
import contracts from "app/contracts";
import modules from "app/modules";
import campaigns from "app/campaigns";
import ad_sellers from "app/ad/sellers";
import withdrawals from "app/ad/withdrawals";
import ad_campaigns from "app/ad/ad-campaigns";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [core.constants.NAME]: core.reducer,
  [bills.constants.NAME]: bills.reducer,
  [shops.constants.NAME]: shops.reducer,
  [users.constants.NAME]: users.reducer,
  [orders.constants.NAME]: orders.reducer,
  [admins.constants.NAME]: admins.reducer,
  [issues.constants.NAME]: issues.reducer,
  [messages.constants.NAME]: messages.reducer,
  [products.constants.NAME]: products.reducer,
  [channels.constants.NAME]: channels.reducer,
  [dashboard.constants.NAME]: dashboard.reducer,
  [customers.constants.NAME]: customers.reducer,
  [activities.constants.NAME]: activities.reducer,
  [settlements.constants.NAME]: settlements.reducer,
  [notifications.constants.NAME]: notifications.reducer,
  [transactions.constants.NAME]: transactions.reducer,
  [payments.constants.NAME]: payments.reducer,
  [contracts.constants.NAME]: contracts.reducer,
  [modules.constants.NAME]: modules.reducer,
  [campaigns.constants.NAME]: campaigns.reducer,
  [ad_sellers.constants.NAME]: ad_sellers.reducer,
  [withdrawals.constants.NAME]: withdrawals.reducer,
  [ad_campaigns.constants.NAME]: ad_campaigns.reducer,
};
