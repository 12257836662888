import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CAMPAIGNS_BEGIN:
      return {
        ...state,
        isFetchingCampaigns: true,
      };

    case types.FETCH_CAMPAIGNS_COMPLETE:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaigns: action.payload.campaigns,
      };

    case types.FETCH_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetchingCampaigns: false,
        errors: {
          ...state.errors,
          [constants.FETCH_CAMPAIGNS]: action.payload.error.message,
        },
      };

    case types.FETCH_CAMPAIGN_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingCampaignDetail: true,
      };

    case types.FETCH_CAMPAIGN_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingCampaignDetail: false,
        campaignDetail: action.payload.campaignDetail,
      };

    case types.FETCH_CAMPAIGN_DETAIL_ERROR:
      return {
        ...state,
        isFetchingCampaignDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_CAMPAIGN_DETAIL]: action.payload.error.message,
        },
      };

    case types.ADD_CAMPAIGN_BEGIN:
      return {
        ...state,
        isAddingCampaigns: true,
      };

    case types.ADD_CAMPAIGN_COMPLETE:
      return {
        ...state,
        isAddingCampaigns: false,
      };

    case types.ADD_CAMPAIGN_ERROR:
      return {
        ...state,
        isAddingCampaigns: false,
        errors: {
          ...state.errors,
          [constants.ADD_CAMPAIGN]: action.payload.error.message,
        },
      };

    case types.UPDATE_CAMPAIGN_BEGIN:
      return {
        ...state,
        isUpdatingCampaigns: true,
      };

    case types.UPDATE_CAMPAIGN_COMPLETE:
      return {
        ...state,
        isUpdatingCampaigns: false,
      };

    case types.UPDATE_CAMPAIGN_ERROR:
      return {
        ...state,
        isUpdatingCampaigns: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_CAMPAIGN]: action.payload.error.message,
        },
      };

    case types.REMOVE_CAMPAIGN_BEGIN:
      return {
        ...state,
        isRemovingCampaigns: true,
      };

    case types.REMOVE_CAMPAIGN_COMPLETE:
      return {
        ...state,
        isRemovingCampaigns: false,
      };

    case types.REMOVE_CAMPAIGN_ERROR:
      return {
        ...state,
        isRemovingCampaigns: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_CAMPAIGN]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default campaignsReducer;
