import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";

function Table({ rows = [], limit, currentPage }) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-orders-list mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 120 }}>Төлөв</th>
            <th style={{ width: 120 }}>Нэр</th>
            <th style={{ width: 120 }}>Мерчант</th>
            <th style={{ width: 150 }}>Хандалт</th>
            <th style={{ width: 150 }}>Захиалгын дүн</th>
            <th style={{ width: 150 }}>Захиалгын тоо</th>
            <th style={{ width: 200 }}>Нөлөөлөгчид</th>
            <th style={{ width: 150 }}>Огноо</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={`tr_${row.id}`}
              className={classNames("", {
                unread: !row.is_read,
              })}
            >
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "published",
                    "badge-warning": row.status === "pending",
                    "badge-danger": row.status === "cancelled",
                  })}
                >
                  {StatusMap[row.status]}
                </span>
              </td>
              <td>
                <a href="#">{row.name}</a>
              </td>
              <td>{row.merchant_name}</td>
              <td className="text-right">
                {!row.total_pageviews
                  ? "-"
                  : numeral(row.total_pageviews).format("0,0")}
              </td>
              <td className="text-right">
                {!row.total_order_volume
                  ? "-"
                  : numeral(row.total_order_volume).format("0,0")}
              </td>
              <td className="text-right">
                {!row.total_order_count
                  ? "-"
                  : numeral(row.total_order_count).format("0,0")}
              </td>
              <td>
                <ul className="nav">
                  {(row.sellers || []).map((seller) => (
                    <li key={`${seller.id}_${seller.campaign_id}`}>
                      <a
                        target={
                          row.status === "published" &&
                          seller.status === "approved"
                            ? "_blank"
                            : ""
                        }
                        href={
                          row.status === "published" &&
                          seller.status === "approved"
                            ? `https://sharely.mn/r/${seller.referral_code}`
                            : "#"
                        }
                        rel="noreferrer"
                      >
                        {seller.seller_name || "default"} /{" "}
                        {seller.referral_code} /
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: "Хүлээгдэж буй",
  published: "Идэвхтэй",
  cancelled: "Цуцлагдсан",
};

export default Table;
