import omit from "lodash/omit";
export default class AdminsService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchAdmins({ filter, options }) {
    const {data: admins} = await this.fetchUtil.sendGet("/admins/list");

    return { admins }
  }

  async fetchAdminDetail({ id }) {
    const { data : admin } = await this.fetchUtil.sendGet(`/admins/detail/${id}`);
    return admin;
  }

  async addAdmin({ admin }) {
    await this.fetchUtil.sendPost("/admins/create", admin);
  }

  async addManager({ admin }) {
    const response = await this.fetchUtil.sendPost("/manager/create", admin);
    return response
  }
  async addRoles({ admin }) {
    const response = await this.fetchUtil.sendPost("/manager/add/roles", admin);
    return response
  }

  async updateAdmin({ id, admin }) {
    await this.fetchUtil.sendPost("/admins/update", { ...admin, id });
  }

  async removeAdmin({ id }) {
    await this.fetchUtil.sendPost("/admins/remove", { id });
  }

  __PLACEHOLDER_METHOD__() {}
}
