import { connect } from "react-redux";
import { push } from "connected-react-router";
import { formValueSelector } from "redux-form";

import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();
const formSelector = formValueSelector("contractForm");

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  isFetching: loading(state[constants.NAME], constants.FETCH_CONTRACT_DETAIL),
  initialValues: !match.params.id
    ? {}
    : selectors.getContractDetail(state[constants.NAME]),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchContractDetail({ id: params.id }));
    }
  },
  onSubmit(id) {
    console.log(id);
  },
  resetErrors() {},
  clearForm() {},
  goBackList() {
    dispatch(push("/contracts"));
  },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
