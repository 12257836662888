export const RESET_ERROR = "@@core/RESET_ERROR";

export const LOGIN_BEGIN = "@@core/LOGIN_BEGIN";
export const LOGIN_COMPLETE = "@@core/LOGIN_COMPLETE";
export const LOGIN_ERROR = "@@core/LOGIN_ERROR";

export const LOGOUT_BEGIN = "@@core/LOGOUT_BEGIN";
export const LOGOUT_COMPLETE = "@@core/LOGOUT_COMPLETE";
export const LOGOUT_ERROR = "@@core/LOGOUT_ERROR";

export const INIT_BEGIN = "@@core/INIT_BEGIN";
export const INIT_COMPLETE = "@@core/INIT_COMPLETE";
export const INIT_ERROR = "@@core/INIT_ERROR";
export const SUGGEST_SHOP_BEGIN = "@@core/SUGGEST_SHOP_BEGIN";
export const SUGGEST_SHOP_COMPLETE = "@@core/SUGGEST_SHOP_COMPLETE";
export const SUGGEST_SHOP_ERROR = "@@core/SUGGEST_SHOP_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
