import { connect } from "react-redux";
import { push } from "connected-react-router";

import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  addError: error(state[constants.NAME], constants.ADD_CAMPAIGN),
  isAdding: loading(state[constants.NAME], constants.ADD_CAMPAIGN),
  updateError: error(state[constants.NAME], constants.UPDATE_CAMPAIGN),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_CAMPAIGN),
  isFetching: loading(state[constants.NAME], constants.FETCH_CAMPAIGN_DETAIL),
  shops: selectors.getCampaignDetail(state[constants.NAME]).shops || [],
  initialValues: !match.params.id
    ? {}
    : selectors.getCampaignDetail(state[constants.NAME]),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchCampaignDetail({ id: params.id }));
    }
  },
  onSubmit(campaign) {
    if (params.id) {
      campaign.status = campaign.status.id;
      dispatch(actions.updateCampaign({ id: params.id, campaign }));
    } else {
      dispatch(actions.addCampaign({ campaign }));
    }
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.ADD_CAMPAIGN }));
    dispatch(actions.resetError({ name: constants.UPDATE_CAMPAIGN }));
  },
  clearForm() {},
  goBackList() {
    dispatch(push("/campaigns"));
  },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
