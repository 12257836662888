import omit from "lodash/omit";
export default class OrderService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchOrders({ filter, options }) {
    const { data: orders, total: totalCount } = await this.fetchUtil.sendGet(
      `/orders/list${this.fetchUtil.qs({
        filter,
        options,
      })}`
    );

    return { orders, totalCount, }
  }

  async fetchOrderDetail({ id }) {
    const { data: order } = await this.fetchUtil.sendGet(`/orders/detail/${id}`);

    return order;
  }

  async changeStatus({ id, status }) {}

  __PLACEHOLDER_METHOD__() {}
}
