import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  
  switch (featureName) {
    case constants.FETCH_PAYMENTS:
      return state.isFetchingPayments;
      break;

    case constants.FETCH_PAYMENT_DETAIL:
      return state.isFetchingPaymentDetail;
      break;

    case constants.CHANGE_STATUS:
      return state.isChangingStatus;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getPayments = createSelector(
  [state => state.payments],
  payments => payments
);
export const getPaymentDetail = createSelector(
  [state => state.paymentDetail],
  paymentDetail => paymentDetail
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [state => state.filter],
  filter => filter
);
