import screens from './screens';

export default [
  {
    path: '/',
    exact: true,
    component: screens.HomeScreen,
  },
  {
    path: '/change-password',
    exact: true,
    component: screens.ChangePassword,
  },
];