import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import React, { Fragment, useEffect } from "react";
import Summary from "../OrderDetail/partials/summary";
import core from "core";
import enhancers from "./enhancers";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function OrderDetail({ isFetching,order, fetchDetail,isLoading }) {
  
  useEffect(() => fetchDetail(), []);

  //   useEffect(
  //     () => {
  //       if (
  //         order.status === 'pending' &&
  //         order.ordertype === 'partial'
  //       ) {
  //         fetchShops(currentPage, { status: 'active', ...filter })
  //       }
  //     },
  //     [currentPage, filter, order]
  //   );

  return (
    <Fragment>
      <PageTitle title="Захиалгын дэлгэрэнгүй" />

      {isFetching === undefined || isFetching === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-3">
            <Summary
                order={order}
            />
          </div>
          <div className="col-lg-9">
            {/* <p className={classNames('alert', {
            'alert-success': broadcast.status === 'done',
            'alert-info': broadcast.status === 'in_progress',
            'alert-warning': broadcast.status === 'pending',
          })}>
            { broadcast.status === 'done' && 'Захиалгын амжилттай илгээсэн.' }
            { broadcast.status === 'in_progress' && 'Захиалгын илгээж байна.' }
            { broadcast.status === 'pending' && 'Та зүүн талд байгаа "ИЛГЭЭХ" товчин дээр дарж зарлалаа илгээнэ үү.' }
          </p> */}
          </div>
        </div>
      )}
    </Fragment>
  );
}

const orderDetailWithState = enhancers.redux(OrderDetail);
export default orderDetailWithState;
