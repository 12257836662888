import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchUsers({ payload: { filter, options } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.FETCH_USERS_URL, {
      filter,
      options
    });
    yield put({
      type: types.FETCH_USERS_COMPLETE,
      payload: {
        users: result
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: types.FETCH_USERS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchUserDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_USER_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_USER_DETAIL_COMPLETE,
      payload: {
        userDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_USER_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* addUser({ payload: { user } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_USER_URL, {
      user
    });
    yield put({
      type: types.ADD_USER_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.ADD_USER_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* updateUser({ payload: { id, user } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.UPDATE_USER_URL, {
      id,
      user
    });
    yield put({
      type: types.UPDATE_USER_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_USER_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* removeUser({ payload: { id } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.REMOVE_USER_URL, {
      id
    });
    yield put({
      type: types.REMOVE_USER_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_USER_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_USERS_BEGIN, fetchUsers);
  yield takeLatest(types.FETCH_USER_DETAIL_BEGIN, fetchUserDetail);
  yield takeLatest(types.ADD_USER_BEGIN, addUser);
  yield takeLatest(types.UPDATE_USER_BEGIN, updateUser);
  yield takeLatest(types.REMOVE_USER_BEGIN, removeUser);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
