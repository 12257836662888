import React, { PureComponent, Fragment } from 'react';
import ReactSelect from 'react-select';

import { transformOptToVal, transformValToOpt } from '../../helpers';

export default class SelectField extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <Fragment>
        <div style={{ float: 'left', width: '100%' }} > 
          <ReactSelect
            clearable={true}
            isMulti={this.props.multi}
            options={this.props.options}
            required={this.props.required}
            isLoading={this.props.isLoading}
            placeholder={this.props.placeholder || 'Сонгох...'}
            value={transformValToOpt(
              this.props.input.value,
              this.props.multi,
            )}
            isDisabled={this.props.disabled}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: !state.isFocused ? '#dee2e6' : '#c1c9d0',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#919ca7',
              }),
              ...this.props.styles,
            }}
            onChange={option =>
              this.props.input.onChange(transformOptToVal(
                option,
                this.props.multi
              ))
            }
          />
        </div>
        <div style={{ width: 0, height: 0 }} >
            {
              !this.props.disabled && (
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{ display: 'block', width: 0, opacity: 0, height: 0 }}
                  value={this.props.input.value}
                  required={this.props.required}
                />
              )
            }
        </div>
      </Fragment>
    );
  }
}
