import React from "react";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";

function Filter({ newUrl, handleSubmit, clearFilter, filter }) {
  return (
    <div className="row mb-2">
      <div className="col-lg-8">
        <form className="form-inline" onSubmit={handleSubmit}>
          <div className="form-group mb-2 mr-2">
            <Field
              component="input"
              type="text"
              className="form-control"
              id="product-name-field"
              placeholder="Нэр"
              name="name"
            />
          </div>
          <div className="form-group mb-2 mr-2">
            <Field
              component="input"
              type="number"
              className="form-control"
              id="product-price-field"
              placeholder="Утас"
              name="phone"
            />
          </div>
          <div className="form-group mb-2">
            <button className="btn btn-primary text-bold">ХАЙХ</button>{" "}
            {!!(filter && !isEqual(filter, {})) && (
              <button
                onClick={() => clearFilter()}
                className="btn btn-danger ml-2 text-bold"
              >
                <i className="uil-times-square"></i>
              </button>
            )}
          </div>
        </form>
      </div>
      {!!newUrl && (
        <div className="col-lg-4 text-right">
          <Link to={newUrl} className="btn btn-primary mb-2 mr-2">
            <i className="mdi mdi-plus-circle mr-1" /> Нэмэх
          </Link>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "usersFilterForm",
  enableReinitialize: true,
})(Filter);
