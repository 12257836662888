import React, { Fragment, useEffect } from "react";
import core from "core";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function ModulesList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  list,
  limit,
  pages,
  updateQueryString,
  changeStatus,
  isChangingStatus,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);
  useEffect(() => {
    if (isChangingStatus === false) {
      list(currentPage, filter);
    }
  }, [isChangingStatus]);
  console.log("modules ", fetchResult.modules);
  return (
    <Fragment>
      <PageTitle title="Модулиуд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/modules/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["name"]);
                if (formData.status) {
                  theFilter.status = formData.status.id;
                }
                if (formData.module_uid) {
                  theFilter.module_uid = formData.module_uid.id;
                }
                if (formData.phone) {
                  theFilter.phone = formData.phone;
                }
                if (formData.email) {
                  theFilter.email = formData.email;
                }
                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Fragment>
              <Table
                limit={limit}
                changeStatus={changeStatus}
                isChangingStatus={isChangingStatus}
                currentPage={currentPage}
                rows={fetchResult.modules}
              />
              <Pagination
                url="/modules"
                pages={pages}
                currentPage={currentPage}
              />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const ModulesListWithState = enhancers.redux(ModulesList);
export default ModulesListWithState;
