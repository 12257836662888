import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function (state, featureName) {
  switch (featureName) {
    case constants.FETCH_CONTRACTS:
      return state.isFetchingContracts;
      break;

    case constants.FETCH_CONTRACT_DETAIL:
      return state.isFetchingContractDetail;
      break;

    case constants.CHANGE_STATUS:
      return state.isChangingStatus;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getContracts = createSelector(
  [(state) => state.contracts],
  (contracts) => contracts
);
export const getContractDetail = createSelector(
  [(state) => state.contractDetail],
  (contractDetail) => contractDetail
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
