import React from "react";
import classNames from "classnames";

function OrderSummary({ transaction }) {
  return (
    <div className="card text-center">
      <div className="card-body">
        {/* <p
          className={classNames("alert", {
            "alert-success": transaction.response.message === "done",
            "alert-info": transaction.response.message === "in_progress",
            "alert-warning": transaction.response.message === "pending",
          })}
        >
          {transaction.response.message === "done" &&
            "Төлбөрийн амжилттай илгээсэн."}
          {transaction.response.message === "in_progress" &&
            "Төлбөрийн илгээж байна."}
          {transaction.response.message === "pending" &&
            "Төлбөрийн Хүлээгдэж байгаа."}
        </p> */}

        <div className="text-left mt-3 mb-2">
          <h4 className="font-13 text-uppercase">Ерөнхий мэдээлэл</h4>
        
          <p className="text-muted font-14">Төлбөрийн дугаар: {transaction.body.order_no}</p>
          <p className="text-muted font-14">Т.X: {transaction.provider}</p>
          
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
