export default class AdCampaignService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchAdCampaigns({ filter, options }) {
    const { data, total: totalCount } = await this.fetchUtil.sendGet(
      `/ad_campaigns${this.fetchUtil.qs({ filter, options })}`
    );
    return { adCampaigns: data, totalCount };
  }

  async fetchAdCampaignDetail({ id }) {
    const { data: bills, total: totalCount } = await this.fetchUtil.sendGet(
      `/ad_campaigns/${id}`
    );
    return { bills, totalCount };
  }
}
