import React from "react";
import classNames from "classnames";
import { setShopIds } from "../../../actions";

function BroadcastSummary({ broadcast, sendBroadcast, shopIds }) {
  return (
    <div className="card text-center">
      <div className="card-body">
        <h4 className="mb-0 mt-2">{broadcast.title}</h4>
        <p className="font-14 mt-1">
          <span
            className={classNames("badge p-1", {
              "badge-success": broadcast.status === "done",
              "badge-warning": broadcast.status === "pending",
              "badge-info": broadcast.status === "in_progress",
            })}
          >
            {StatusMap[broadcast.status]}
          </span>
        </p>
        <div className="text-left mt-3">
          <h4 className="font-13 text-uppercase">Зурвас</h4>
          <p>{broadcast.body}</p>
        </div>
        <div className="text-left mt-2">
          {shopIds.length > 0 && (
            <p>
              <strong>{shopIds.length}</strong> мерчант сонгогдсон байна.
            </p>
          )}
          {broadcast.status === "pending" && (
            <button
              className="btn btn-primary"
              // disabled={shopIds.length === 0}
              onClick={() => {
                if (window.confirm("Та итгэлтэй байна уу?")) {
                  sendBroadcast(shopIds);
                }
              }}
            >
              Илгээх
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const StatusMap = {
  done: "Илгээсэн",
  pending: "Хүлээгдэж буй",
  in_progress: "Илгээж буй",
};

export default BroadcastSummary;
