import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_BEGIN:
      return {
        ...state,
        isFetchingOrders: true,
      };

    case types.FETCH_ORDERS_COMPLETE:
      return {
        ...state,
        isFetchingOrders: false,
        orders: action.payload.orders,
      };

    case types.FETCH_ORDERS_ERROR:
      return {
        ...state,
        isFetchingOrders: false,
        errors: {
          ...state.errors,
          [constants.FETCH_ORDERS]: action.payload.error.message,
        },
      };

    case types.FETCH_ORDER_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingOrderDetail: true,
      };

    case types.FETCH_ORDER_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingOrderDetail: false,
        orderDetail: action.payload.orderDetail,
      };

    case types.FETCH_ORDER_DETAIL_ERROR:
      return {
        ...state,
        isFetchingOrderDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_ORDER_DETAIL]: action.payload.error.message,
        },
      };

    case types.CHANGE_STATUS_BEGIN:
      return {
        ...state,
        isChangingStatus: true,
      };

    case types.CHANGE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingStatus: false,
      };

    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isChangingStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_STATUS]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default ordersReducer;
