import React, { Fragment, useEffect } from "react";
import core from "core";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function ContractList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  list,
  limit,
  pages,
  updateQueryString,
  changeStatus,
  isChangingStatus,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);
  useEffect(() => {
    if (isChangingStatus === false) {
      list(currentPage, filter);
    }
  }, [isChangingStatus]);
  console.log("contracts ", fetchResult.contracts);
  return (
    <Fragment>
      <PageTitle title="Гэрээнүүд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/contracts/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["name"]);
                if (formData.status) {
                  theFilter.status = formData.status.id;
                }
                if (formData.phone) {
                  theFilter.phone = formData.phone;
                }
                if (formData.email) {
                  theFilter.email = formData.email;
                }
                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Fragment>
              <Table
                limit={limit}
                changeStatus={changeStatus}
                isChangingStatus={isChangingStatus}
                currentPage={currentPage}
                rows={fetchResult.contracts}
              />
              <Pagination
                url="/contracts"
                pages={pages}
                currentPage={currentPage}
              />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const ContractListWithState = enhancers.redux(ContractList);
export default ContractListWithState;
