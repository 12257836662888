import { reset } from "redux-form";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import core from "core";
import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

import * as shopActions from "app/shops/actions";
import * as shopConstants from "app/shops/constants";
import * as shopSelectors from "app/shops/selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();
const shopsLoading = shopSelectors.makeLoadingSelector();

export const mapStateToProps = (state, { location: { search } }) => ({
  isFetching: loading(state[constants.NAME], constants.FETCH_ORDER_DETAIL),
  order: selectors.getOrderDetail(state[constants.NAME]) || {},
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    dispatch(actions.fetchOrderDetail({ id: params.id }));
  },
  //   setFilter(filter) {
  //     dispatch(actions.setShopFilter({ filter }));
  //   },
  //   clearFilter() {
  //     dispatch(reset('broadcastShopsFilterForm'));
  //     dispatch(actions.setShopFilter({ filter: null }));
  //   },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
