import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchIssues({ payload: { filter, options } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.FETCH_ISSUES_URL, {
      filter,
      options
    });
    yield put({
      type: types.FETCH_ISSUES_COMPLETE,
      payload: {
        issues: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_ISSUES_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchIssueDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_ISSUE_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_ISSUE_DETAIL_COMPLETE,
      payload: {
        issueDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_ISSUE_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* addIssue({ payload: { issue } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_ISSUE_URL, {
      issue
    });
    yield put({
      type: types.ADD_ISSUE_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.ADD_ISSUE_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* updateIssue({ payload: { id, issue } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.UPDATE_ISSUE_URL, {
      id,
      issue
    });
    yield put({
      type: types.UPDATE_ISSUE_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_ISSUE_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* removeIssue({ payload: { id } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.REMOVE_ISSUE_URL, {
      id
    });
    yield put({
      type: types.REMOVE_ISSUE_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_ISSUE_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* changeStatus({ payload: { id, status } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_STATUS_URL,
      {
        id,
        status
      }
    );
    yield put({
      type: types.CHANGE_STATUS_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_ISSUES_BEGIN, fetchIssues);
  yield takeLatest(types.FETCH_ISSUE_DETAIL_BEGIN, fetchIssueDetail);
  yield takeLatest(types.ADD_ISSUE_BEGIN, addIssue);
  yield takeLatest(types.UPDATE_ISSUE_BEGIN, updateIssue);
  yield takeLatest(types.REMOVE_ISSUE_BEGIN, removeIssue);
  yield takeLatest(types.CHANGE_STATUS_BEGIN, changeStatus);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
