import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

import core from 'core';
const Loading = core.components.Loading;
const Pagination = core.components.Pagination;

function Shops({
  rows = [],
  limit = 50,
  currentPage,
  totalCount,
  handleSubmit,
  filter,
  clearFilter,
  isFetching,
  shopIds,
  setShopIds,
  shops,
  pages,
  broadcast_id,
}) {

  const toggleShopId = (event, id) => {
    if(event.target.checked) {
      setShopIds([ ...shopIds, id ]);
    } else {
      setShopIds(without(shopIds, id));
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-lg-12">
            <form
              onSubmit={handleSubmit}
              className="form-inline shop-filter-form"
            >
              <div className="form-group mr-2">
                <Field
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="Нэр..."
                  name="name"
                />
              </div>
              <div className="form-group mr-2">
                <Field
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="Утас..."
                  name="phone"
                />
              </div>
              <div className="form-group">
                <button
                  className="btn btn-primary text-bold"
                >
                  ХАЙХ
                </button> {
                  !!(filter && !isEqual(filter, {})) && <button
                  onClick={() => clearFilter()}
                  className="btn btn-danger ml-2 text-bold"
                >
                  <i className="uil-times-square"></i>
                </button>}
              </div>
            </form>                            
          </div>
        </div>
        {isFetching === undefined || isFetching === true ? <Loading /> :
          <>
          <div className="table-responsive">
            <table className="shops-table table table-centered mb-0">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: 20, paddingRight: 0 }} ></th>
                  <th style={{ width: 20 }} >#</th>
                  <th>Төлөв</th>
                  <th>Нэр</th>
                  <th>Утас</th>
                  <th style={{ width: 100 }} >Огноо</th>
                </tr>
              </thead>
              <tbody>
                {
                  rows.map((row, index) =>
                    <tr key={`tr_${row.id}`} >
                      <td style={{ paddingRight: 0 }} >
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={shopIds.indexOf(row.id) > -1}

                            id={`shop_selector_${row.id}`}

                            onChange={event => toggleShopId(event, row.id)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`shop_selector_${row.id}`}
                          />
                        </div>
                      </td>
                      <td>{ core.helpers.getTableIndex(currentPage, limit, index) }</td>
                      <td style={{ width: 100 }}>
                        <span className={classNames('badge p-1', {
                          'badge-success': row.is_subscribed === true,
                          'badge-warning': !row.is_subscribed && row.expired,
                          'badge-info': !row.is_subscribed && !row.expired,
                        })}>
                          { !!row.is_subscribed && 'идэвхтэй' }
                          { (!row.is_subscribed && row.expired) && 'идэвхгүй' }
                          { (!row.is_subscribed && !row.expired) && 'шинэ' }
                        </span>
                      </td>
                      <td className="name" >{ row.name }</td>
                      <td>{ row.phone }</td>
                      <td>{ moment(row.created_at).format('YYYY-MM-DD') }</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            url={`/broadcasts/detail/${broadcast_id}`}
          />
          </>
        }
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'broadcastShopsFilterForm',
  enableReinitialize: true,
})(Shops);
