import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import core from 'core';

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-orders-list mb-0">
        <thead className="thead-light">
          <tr>
            <th rowSpan="2" style={{ width: 20 }} >#</th>
            <th rowSpan="2" style={{ width: 120 }} >Төлөв</th>
            <th rowSpan="2" style={{ width: 120 }} >Суваг</th>
            <th rowSpan="2" style={{ width: 200 }} >Дэлгүүр</th>
            <th rowSpan="2" style={{ width: 100 }} >Код</th>
            <th rowSpan="2" style={{ width: 150 }} className="text-right" >Дүн</th>
            <th rowSpan="2" style={{ width: 100 }} >T.Х</th>
            <th className="text-center" colSpan="3" >Харилцагч</th>
            <th rowSpan="2" style={{ width: 150 }} >Огноо</th>
          </tr>
          <tr>
            <th>Утас</th>
            <th>Нэр</th>
            <th>Хаяг</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) =>
              <tr
                key={`tr_${row.id}`}
                className={classNames('', {
                  unread: !row.is_read
                })}
              >
                <td>{ core.helpers.getTableIndex(currentPage, limit, index) }</td>
                <td>
                  <span className={classNames('badge p-1', {
                    'badge-success': ['verified', 'delivered'].indexOf(row.status) > -1,
                    'badge-warning': row.status === 'pending',
                    'badge-danger': row.status === 'cancelled',
                  })}>
                    { StatusMap[row.status] }
                  </span>
                </td>
                <td>{ row.channel }</td>
                <td>
                  <Link to={`/shops/d/${row.shop_id}`} >
                    { row.shop_name }
                  </Link>
                </td>
                <td>{ row.code }</td>
                <td className="text-right" >{ numeral(row.total_price).format('0,0') }</td>
                <td>{ row.payment_type }</td>
                <td>{ row.customer_phone }</td>
                <td>{ row.customer_full_name || `${row.customer_last_name} ${row.customer_first_name}` }</td>
                <td style={{ width: 200 }} >{ row.customer_address }</td>
                <td>{ moment(row.created_at).format('YYYY-MM-DD HH:mm') }</td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: 'Хүлээгдэж буй',
  verified: 'Батаалгаажсан',
  delivered: 'Хүргэгдсэн',
  cancelled: 'Цуцлагдсан',
};

export default Table;
