import screens from './screens';

export default [
  {
    exact: true,
    path: "/issues",
    component: screens.IssueList,
  },
  {
    exact: true,
    path: "/issues/new",
    component: screens.IssueForm,
  },
  {
    exact: true,
    path: "/issues/edit/:id",
    component: screens.IssueForm,
  },
];