import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
  currentUser,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 80, textAlign: "center" }} >Зураг</th>
            <th rowSpan="2" style={{ width: 80 }} >Төлөв</th>
            <th style={{ width: 150 }} >Дэлгүүр</th>
            <th>Нэр</th>
            <th style={{ width: 100 }}>Үнэ</th>
            <th  style={{ width: 150 }}>Мастер ангилал</th>
            <th  style={{ width: 150 }}>Б.Суваг</th>
            <th style={{ width: 150 }}>Огноо</th>
            <th style={{ width: 60 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <img height="40" src={getImageThumb(row.images)} alt="" />
              </td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "enabled",
                    "badge-danger": row.status === "disabled",
                  })}
                >
                  {StatusMap[row.status]}
                </span>
              </td>
              <td>{row.shop_name}</td>
              <td>{row.name}</td>
              <td>{ numeral(row.price).format("0,0") }</td>
              <td>
                { (row.main_category_codes || []).length === 0 && '-' } 
                { (row.main_category_codes || []).length > 0 && (row.main_category_codes || []).map(code => core.helpers.MainCategoryMap[code]).join(", ") }
              </td>
              <td>
                { row.sale_channels.length === 0  && "-" }
                { (row.sale_channels || []).join(", ") }
              </td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td style={{ width: 60 }}>
                  <Link className="action-icon" to={`/products/edit/${row.id}?page=${currentPage}`}>
                    <i className="mdi mdi-square-edit-outline" />
                  </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function getImageThumb(rawImages) {
  let url = "/images/placeholder.jpeg";
  try {
    const images = JSON.parse(rawImages);

    if (images && images.length) {
      url = (images[0].url || "")
        .replace(".png", "_t250.png")
        .replace(".jpg", "_t250.jpg")
        .replace(".jpeg", "_t250.jpeg")
        .replace(".gif", "_t250.gif")
        .replace(".webp", "_t250.webp");
    }
  } catch(err) {
  }

  return url;
}

const StatusMap = {
  enabled: "Идэвхтэй",
  disabled: "Идэвхгүй",
};

export default Table;
