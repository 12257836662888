import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect } from "react";
import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";
const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function UserList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  fetchUsers,
  currentUser,
  limit,
  pages,
  updateQueryString,
  isRemoving,
  remove,
}) {
  useEffect(() => {
    fetchUsers(currentPage, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filter, isRemoving]);

  return (
    <Fragment>
      <PageTitle title="Хэрэглэгчид" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/users/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["name", "phone"]);

                if (formData.start) {
                  theFilter.start = formData.start.toISOString();
                }

                if (formData.end) {
                  theFilter.end = formData.end.toISOString();
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined ||
          isFetching === true ||
          isRemoving === true ? (
            <Loading />
          ) : (
            <>
              <Table
                currentPage={currentPage}
                limit={limit}
                rows={fetchResult.users}
                currentUser={currentUser}
                remove={remove}
                isRemoving={isRemoving}
              />
              <Pagination
                url="/users"
                pages={pages}
                currentPage={currentPage}
              />
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const UserListWithState = enhancers.redux(UserList);
export default UserListWithState;
