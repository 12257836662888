import { connect } from 'react-redux';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = state => ({
  loginError: error(state[constants.NAME], constants.LOGIN),
  isLogging: loading(state[constants.NAME], constants.LOGIN),
});
export const mapDispatchToProps = dispatch => ({
  onChange() {
    dispatch(actions.resetError({ name: constants.LOGIN }));
  },
  onSubmit({ username, password }) {
    dispatch(actions.resetError({ name: constants.LOGIN }));
    dispatch(actions.login({ username, password }));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
