import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// import registerServiceWorker from './registerServiceWorker';
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
  <App />, document.getElementById('__app__root')
);

// registerServiceWorker();
