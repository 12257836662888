export default class ProductService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchProducts({ filter, options }) {
    const { data: products, total: totalCount } = await this.fetchUtil.sendGet(
      `/products/list${this.fetchUtil.qs({
        filter,
        options
      })}`
    );

    return { products, totalCount };
  }

  async fetchProductDetail({ id }) {
    const { data : product } = await this.fetchUtil.sendGet(`/products/detail/${id}`);

    if (product) {
      product.price = parseInt(product.price, 10);
      product.status = {
        id: product.status,
        name: product.status === "enabled" ? "Идэвхтэй" : "Идэвхгүй"
      };
      product.main_category_codes = (product.main_category_codes || []).map(
        category => ({
          id: category,
          name: CategoryMap[category]
        })
      );
      product.sale_channels = (product.sale_channels || []).map(channel => ({
        id: channel,
        name: channel
      }));
    }

    return product;
  }

  async updateProduct({ id, product }) {
    await this.fetchUtil.sendPost(`/products/update`, {
      id,
      ...product
    });
  }

  async fetchCategories({ filter, options }) {
    const { data: categories } = await this.fetchUtil.sendGet(
      `/product-categories/list${this.fetchUtil.qs({
        filter,
        options
      })}`
    );

    return { categories };
  }

  async fetchCategoryDetail({ id }) {
    const { data : category } = await this.fetchUtil.sendGet(`/product-categories/detail/${id}`);

    if (category) {
      category.main_category_codes = (category.main_category_codes || []).map(
        category => ({
          id: category,
          name: CategoryMap[category]
        })
      );
      category.sale_channels = (category.sale_channels || []).map(channel => ({
        id: channel,
        name: channel
      }));
    }

    return category;
  }

  async updateCategory({ id, category }) {
    await this.fetchUtil.sendPost(`/product-categories/update`, {
      id,
      ...category
    });
  }
}

const CategoryMap = {
  A5: "Гоо сайхан",
  A3: "Спорт",
  A4: "Хүнс",
  A9: "Бичиг хэрэг",
  A2: "Эрэгтэй",
  A0: "Гэр ахуй",
  A1: "Эмэгтэй",
  A7: "Үнэт эдлэл",
  A6: "Хүүхдийн",
  A8: "Электрон бараа",
  A11: "Авто",
  A12: "Хувцас",
  A13: "Амьтан",
  A15: "Хөгжим",
  A14: "Эрүүл мэнд",
  A16: "Үйлчилгээ ",
  A17: "Сургалт ",
  A18: "Цүнх ",
  A19: "Гутал ",
  A20: "Бусад "
};
