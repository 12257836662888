import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const contractsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CONTRACTS_BEGIN:
      return {
        ...state,
        isFetchingContracts: true,
      };

    case types.FETCH_CONTRACTS_COMPLETE:
      return {
        ...state,
        isFetchingContracts: false,
        contracts: action.payload.contracts,
      };

    case types.FETCH_CONTRACTS_ERROR:
      return {
        ...state,
        isFetchingContracts: false,
        errors: {
          ...state.errors,
          [constants.FETCH_CONTRACTS]: action.payload.error.message,
        },
      };

    case types.FETCH_CONTRACT_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingContractDetail: true,
      };

    case types.FETCH_CONTRACT_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingContractDetail: false,
        ContractDetail: action.payload.contractDetail,
      };

    case types.FETCH_CONTRACT_DETAIL_ERROR:
      return {
        ...state,
        isFetchingContractDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_CONTRACT_DETAIL]: action.payload.error.message,
        },
      };

    case types.CHANGE_STATUS_BEGIN:
      return {
        ...state,
        isChangingStatus: true,
      };

    case types.CHANGE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingStatus: false,
      };

    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isChangingStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_STATUS]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default contractsReducer;
