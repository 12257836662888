/* global toastr */
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import enhancers from './enhancers';

export class Login extends PureComponent {
  componentDidMount() {}
  componentDidUpdate(prevProps) {

    if (prevProps.isLogging === true && this.props.isLogging === false) {
      if (this.props.loginError) {
        toastr.error(this.props.loginError, 'Алдаа');
      }
    }
  }

  render() {
    const { isLogging, handleSubmit, loginError } = this.props;

    return (
      <div style={{ marginTop: '12%' }} className="account-pages mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header pt-3 pb-3 text-center bg-primary">
                  <a>
                    <span><img src="/images/logo-light-login.png" alt="" height={35} /></span>
                  </a>
                </div>

                <div className="card-body p-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="emailaddress">Нэвтрэх нэр</label>
                      <Field
                        required
                        disabled={isLogging}
                        name="username"
                        type="text"
                        component="input"
                        className="form-control"
                        placeholder="Нэвтрэх нэр"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Нууц үг</label>
                      <Field
                        required
                        disabled={isLogging}
                        name="password"
                        type="password"
                        component="input"
                        className="form-control"
                        placeholder="Нууц үг"
                      />
                    </div>
                    <div className="form-group mb-0 text-center">
                      <button className="btn btn-primary" type="submit"> Нэвтрэх </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const LoginWithForm = enhancers.form(Login);
const LoginWithState = enhancers.redux(LoginWithForm);

export default LoginWithState;
