export const NAME = "campaigns";
export const FETCH_CAMPAIGNS = "fetch_campaigns";
export const FETCH_CAMPAIGNS_URL = "campaigns/fetchCampaigns";
export const FETCH_CAMPAIGN_DETAIL = "fetch_campaign_detail";
export const FETCH_CAMPAIGN_DETAIL_URL = "campaigns/fetchCampaignDetail";
export const ADD_CAMPAIGN = "add_campaign";
export const ADD_CAMPAIGN_URL = "campaigns/addCampaign";
export const UPDATE_CAMPAIGN = "update_campaign";
export const UPDATE_CAMPAIGN_URL = "campaigns/updateCampaign";
export const REMOVE_CAMPAIGN = "remove_campaign";
export const REMOVE_CAMPAIGN_URL = "campaigns/removeCampaign";
const __PLACEHOLDER__ = null; // ignore this.
