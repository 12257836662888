import * as types from "./actionTypes";
export const fetchChannels = ({ filter, options }) => ({
  type: types.FETCH_CHANNELS_BEGIN,
  payload: {
    filter,
    options,
  },
});
// export const fetchChannelDetail = ({ id }) => ({
//   type: types.FETCH_CHANNEL_DETAIL_BEGIN,
//   payload: {
//     id,
//   },
// });
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
