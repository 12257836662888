import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import { Link, withRouter } from 'react-router-dom';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants'; 

const mapStateToProps = state => ({
  currentUser: selectors.getCurrentUser(state[constants.NAME]),
});

const mapDispatchToProps = dispatch => ({
  onLogout(event) {
    event.preventDefault();
    if (window.confirm('Та итгэлтэй байна уу')) {
      dispatch(actions.logout());
      dispatch(push('/'));
    }
  }
})

/*
 *
 * Main navigation bar.
 * 
 * @component
 */
function NavBar({ currentUser, onLogout }) {
  return !currentUser ? null : (
    <div className="navbar-custom topnav-navbar topnav-navbar-dark">
      <div className="container-fluid">
        <Link to="/" className="topnav-logo">
          <span className="topnav-logo-lg">
            <img src="/images/logo-light.png" alt="" height={40} />
          </span>
          <span className="topnav-logo-sm">
            <img src="/images/logo_sm_dark.png" alt="" height={16} />
          </span>
        </Link>
        <ul className="list-unstyled topbar-right-menu float-right mb-0">
          {/*
            <li className="dropdown notification-list">
              <a className="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" id="topbar-notifydrop" role="button" aria-haspopup="true" aria-expanded="false">
                <i className="dripicons-bell noti-icon" />
                <span className="noti-icon-badge" />
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg" aria-labelledby="topbar-notifydrop">
                <div className="dropdown-item noti-title">
                  <h5 className="m-0">
                    <span className="float-right">
                      <a href="javascript: void(0);" className="text-dark">
                        <small>Clear All</small>
                      </a>
                    </span>Notification
                  </h5>
                </div>
                <div style={{maxHeight: '230px'}} data-simplebar>
                  <a href="#" className="dropdown-item notify-item">
                    <div className="notify-icon bg-primary">
                      <i className="mdi mdi-comment-account-outline" />
                    </div>
                    <p className="notify-details">Caleb Flakelar commented on Admin
                      <small className="text-muted">1 min ago</small>
                    </p>
                  </a>
                </div>
                <a href="#" className="dropdown-item text-center text-primary notify-item notify-all">
                  View All
                </a>
              </div>
            </li>
          */}
          <li className="dropdown notification-list">
            <a className="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" id="topbar-userdrop" href="#" role="button" aria-haspopup="true" aria-expanded="false">
              <span className="account-user-avatar"> 
                <img src="/images/users/user-avatar.png" alt="user-image" className="rounded-circle" />
              </span>
              <span>
                <span className="account-user-name">
                  { currentUser.last_name } { currentUser.first_name }
                </span>
                <span className="account-position">Админ</span>
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop">
              <div className=" dropdown-header noti-title">
                <h6 className="text-overflow m-0">Тавтай морил !</h6>
              </div>
              <Link to="/change-password" className="dropdown-item notify-item">
                <i className="mdi mdi-lock-outline mr-1" />
                <span>Нууц үг солих</span>
              </Link>
              <a
                href="#"
                className="dropdown-item notify-item"

                onClick={onLogout}
              >
                <i className="mdi mdi-logout mr-1" />
                <span>Гарах</span>
              </a>
            </div>
          </li>
        </ul>
        <a className="navbar-toggle" data-toggle="collapse" data-target="#topnav-menu-content">
          <div className="lines">
            <span />
            <span />
            <span />
          </div>
        </a>
      </div>
    </div>
  );
}

const NavBarWithState = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavBar)
);

export default NavBarWithState;
