import screens from "./screens";

export default [
  {
    exact: true,
    path: "/modules",
    component: screens.ModulesList,
  },
  {
    exact: true,
    path: "/modules/edit/:id",
    component: screens.ModuleDetail,
  },
];
