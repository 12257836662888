import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchCampaigns({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CAMPAIGNS_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_CAMPAIGNS_COMPLETE,
      payload: {
        campaigns: result,
      },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: types.FETCH_CAMPAIGNS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchCampaignDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CAMPAIGN_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_CAMPAIGN_DETAIL_COMPLETE,
      payload: {
        campaignDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CAMPAIGN_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addCampaign({ payload: { campaign } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_CAMPAIGN_URL, {
      campaign,
    });
    yield put({
      type: types.ADD_CAMPAIGN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.ADD_CAMPAIGN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* updateCampaign({ payload: { id, campaign } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.UPDATE_CAMPAIGN_URL,
      {
        id,
        campaign,
      }
    );
    yield put({
      type: types.UPDATE_CAMPAIGN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_CAMPAIGN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* removeCampaign({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.REMOVE_CAMPAIGN_URL,
      {
        id,
      }
    );
    yield put({
      type: types.REMOVE_CAMPAIGN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_CAMPAIGN_ERROR,
      payload: {
        error,
      },
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_CAMPAIGNS_BEGIN, fetchCampaigns);
  yield takeLatest(types.FETCH_CAMPAIGN_DETAIL_BEGIN, fetchCampaignDetail);
  yield takeLatest(types.ADD_CAMPAIGN_BEGIN, addCampaign);
  yield takeLatest(types.UPDATE_CAMPAIGN_BEGIN, updateCampaign);
  yield takeLatest(types.REMOVE_CAMPAIGN_BEGIN, removeCampaign);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
