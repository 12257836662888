import React from "react";
import isEqual from "lodash/isEqual";
import { reduxForm, Field } from "redux-form";

import core from "core";

function Filter({ newUrl, filter, clearFilter, handleSubmit }) {
  return (
    <div className="row">
      <div className="col-lg-12">
        <form className="form-inline" onSubmit={handleSubmit}>
          <div style={{ width: 180 }} className="form-group mr-2 mb-2">
            <Field
              name="status"
              placeholder="Төлөв сонгох.."
              options={[
                { value: "pending", label: "Хүлээгдэж байгаа" },
                { value: "paid", label: "Төлөгдсөн" },
                { value: "error", label: "Алдаа гарсан" },
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div style={{ width: 180 }} className="form-group mr-2 mb-2">
            <Field
              name="channel"
              placeholder="Төлбөрийн хэрэгсэл.."
              options={[
                { value: "qpay", label: "QPay" },
                { value: "monpay", label: "Monpay" },
                { value: "hipay", label: "Hipay" },
                { value: "lendpay", label: "Lendpay" },
                { value: "card", label: "Card" },
                { value: "socialpay", label: "Socialpay" },
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div className="form-group mr-2 mb-2">
            <Field
              component="input"
              type="text"
              className="form-control"
              placeholder="Захиалгын код"
              name="code"
            />
          </div>
          <div className="form-group mr-2 mb-2">
            <Field
              component="input"
              type="text"
              className="form-control"
              placeholder="Утас"
              name="phone"
            />
          </div>
          <div className="form-group mr-2 mb-2">
            <Field
              name="start"
              showTimeSelect
              placeholder="Огноо эхлэх"
              dateFormat="yyyy-MM-dd HH:mm"
              component={core.components.DateTimePicker}
            />
          </div>
          <div className="form-group mr-2 mb-2">
            <Field
              name="end"
              showTimeSelect
              dateFormat="yyyy-MM-dd HH:mm"
              placeholder="төгсөх"
              component={core.components.DateTimePicker}
            />
          </div>
          <div className="form-group mr-2 mb-2">
            <Field
              component="input"
              type="number"
              className="form-control"
              placeholder="Дүн"
              name="price"
            />
          </div>
          <div className="form-group mb-2">
            <button className="btn btn-primary text-bold">ХАЙХ</button>{" "}
            {!!(filter && !isEqual(filter, {})) && (
              <button
                onClick={() => clearFilter()}
                className="btn btn-danger ml-2 text-bold"
              >
                <i className="uil-times-square"></i>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "transactionFilterForm",
  enableReinitialize: true,
})(Filter);
