import React from "react";
import classNames from "classnames";

function OrderSummary({ transaction }) {
  return (
    <div className="card text-center">
      <div className="card-body">
        
        <p
          className={classNames("alert", {
            "alert-success": transaction.invoice.status === "done",
            "alert-info": transaction.invoice.status === "in_progress",
            "alert-warning": transaction.invoice.status === "pending",
          })}
        >
          {transaction.invoice.status === "done" &&
            "Гүйлгээ амжилттай илгээсэн."}
          {transaction.invoice.status === "in_progress" &&
            "Гүйлгээ илгээж байна."}
          {transaction.invoice.status === "pending" &&
            "Гүйлгээ Хүлээгдэж байгаа."}
        </p>
        {transaction.invoice.error_message && 
        <p
          className={classNames("alert", {
            "alert-warning": transaction.invoice.error_message
          })}
        >
          {transaction.invoice.error_message}
        </p>
}
        <div className="text-left mt-3 mb-2">
          <h4 className="font-13 text-uppercase">Ерөнхий мэдээлэл</h4>
          <p className="text-muted font-14">Утасны Дугаар: {transaction.invoice.phone}</p>
          <p className="text-muted font-14">Гүйлгээний дугаар: {transaction.invoice.order_code}</p>
          <p className="text-muted font-14">Т.X: {transaction.invoice.provider}</p>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
