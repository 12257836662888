import React from 'react';

function FormGroup({ label, children, required, classNames='' }) {
  return (
    <div className={`form-group ${classNames}`}>
      <label>{ label } {!!required && <span className="text-danger">*</span>}</label>
      { children }
    </div>
  );
}

export default FormGroup;