import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
  isChangingStatus,
  changeStatus,
}) {
  return (
    <div className="table-responsive">
      <table className="shops-table table table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th>Төлөв</th>
            <th>Модуль</th>
            <th>Мерчант</th>
            <th>Модулийн төрөл</th>
            <th style={{ width: 150 }}>Хэрэглэгч</th>
            <th style={{ width: 150 }}>Модуль дуусах</th>
            <th style={{ width: 150 }}>Үүссэн</th>
            <th className="text-center" style={{ width: 120 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td style={{ width: 100 }}>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "enabled",
                    "badge-warning": row.status === "pending",
                    "badge-danger": row.status === "cancelled",
                  })}
                >
                  {row.status === "enabled" && "баталгаажсан"}
                  {row.status === "pending" && "хүлээгдэж буй"}
                  {row.status === "cancelled" && "буцаагдсан"}
                </span>
              </td>
              <td>
                <strong>{row.module_uid}</strong>
              </td>
              <td className="name">
                <div className="media">
                  <div className="media-body">
                    <strong>{row.name}</strong>
                    <div>{row.phone}</div>
                  </div>
                </div>
              </td>
              <td>{row.module_type}</td>
              <td>{row.full_name}</td>
              <td>{row.expire_at}</td>
              <td>{row.created_at}</td>
              <td style={{ width: 125 }}>
                {/* {row.status === "pending" && (
                  <a
                    href="#"
                    className="action-icon"
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        changeStatus(row.id, "resolved");
                      }
                    }}
                  >
                    <i className="uil-check-circle" />
                  </a>
                )} */}
                <Link to={`/modules/edit/${row.id}`} className="action-icon">
                  {" "}
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
