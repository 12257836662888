import React from 'react';

function UserInfo({ user = {}, title }) {
  return (
    <div className="card text-center">
      <div className="card-body">
        <img
          src={user.avatar || '/images/user-large.png'}
          alt={`${user.last_name} ${user.first_name}`}
          className="rounded-circle avatar-lg img-thumbnail"
        />

        <h4 className="mb-0 mt-2">{ user.first_name } {  user.last_name  }</h4>
        <p className="text-muted font-14">{ title }</p>
        <div className="text-left mt-3">
          <h4 className="font-13 text-uppercase">Товч мэдээлэл</h4>
          <p className="text-muted mb-2 font-13">
            <strong>Утасны дугаар:</strong>
            <span className="ml-2">{ user.phone }</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;