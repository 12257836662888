import screens from "./screens";

export default [
  {
    path: "/transactions",
    component: screens.TransactionList,
  },
  {
    path: "/transactions/detail/:id",
    component: screens.TransactionDetail,
  },
];
