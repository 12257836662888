export const NAME = "modules";
export const FETCH_MODULES = "fetch_modules";
export const FETCH_MODULES_URL = "modules/fetchModules";
export const FETCH_MODULE_DETAIL = "fetch_module_detail";
export const FETCH_MODULE_DETAIL_URL = "modules/fetchModuleDetail";
export const CHANGE_STATUS = "change_status";
export const CHANGE_STATUS_URL = "modules/changeStatus";
export const CHANGE_DISABLE_STATUS = "change_disable_status";
export const CHANGE_DISABLE_STATUS_URL = "modules/changeDisableStatus";
const __PLACEHOLDER__ = null; // ignore this.
