import createSagaMiddleware from "redux-saga";
import { createBrowserHistory as createHistory } from "history";

import { reducer as formReducer } from "redux-form";
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";

class ReduxClient {
  static getInstance(reducers) {
    if (!(ReduxClient._instance instanceof ReduxClient)) {
      if (!reducers) {
        throw new Error("Missing root reducers.");
      }

      ReduxClient._instance = new ReduxClient(reducers);
    }

    return ReduxClient._instance;
  }

  constructor(reducers) {
    this.history = createHistory();
    this.sagaMiddleware = createSagaMiddleware();
    this.historyMiddleware = routerMiddleware(this.history);

    this.store = this._initStore(reducers);
  }

  getStore() {
    return this.store;
  }

  getHistory() {
    return this.history;
  }

  runSaga(rootSaga) {
    this.sagaMiddleware.run(rootSaga);
  }

  _initStore(reducers) {
    const _store = createStore(
      combineReducers({
        ...reducers,
        form: formReducer,
        router: connectRouter(this.history),
      }),
      applyMiddleware(this.sagaMiddleware, this.historyMiddleware)
    );

    return _store;
  }
}

export default ReduxClient;
