import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchAdCampaigns({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_AD_CAMPAIGNS_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_AD_CAMPAIGNS_COMPLETE,
      payload: {
        adCampaigns: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_AD_CAMPAIGNS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchAdCampaignDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_AD_CAMPAIGN_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_AD_CAMPAIGN_DETAIL_COMPLETE,
      payload: {
        adCampaign: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_AD_CAMPAIGN_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* watchSagas() {
  yield takeLatest(types.FETCH_AD_CAMPAIGNS_BEGIN, fetchAdCampaigns);
  yield takeLatest(types.FETCH_AD_CAMPAIGN_DETAIL_BEGIN, fetchAdCampaignDetail);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [watchSagas()];
