import screens from "./screens";

export default [
  {
    exact: true,
    path: "/contracts",
    component: screens.ContractList,
  },
  {
    exact: true,
    path: "/contracts/detail/:id",
    component: screens.ContractForm,
  },
];
