import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as helpers from "./helpers";
import * as constants from "./constants";

export function* login({ payload: { username, password } }) {
  try {
    const currentUser = yield call(ServiceFactory.call, constants.LOGIN_URL, {
      username,
      password
    });
    yield put({
      type: types.LOGIN_COMPLETE,
      payload: {
        currentUser
      }
    });
  } catch (error) {
    yield put({
      type: types.LOGIN_ERROR,
      payload: {
        error
      }
    });
  }
}

export function* logout() {
  try {
    yield call(ServiceFactory.call, constants.LOGOUT_URL, {});
    yield put({
      type: types.LOGOUT_COMPLETE
    });
  } catch (error) {
    console.log(error.message);
    yield put({
      type: types.LOGOUT_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* init() {
  try {
    const currentUser = yield call(ServiceFactory.call, constants.INIT_URL, {});
    yield put({
      type: types.INIT_COMPLETE,
      payload: {
        currentUser
      }
    });
  } catch (error) {
    yield put({
      type: types.INIT_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* suggestShop({ payload: { name } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.SUGGEST_SHOP_URL, {
      name
    });
    yield put({
      type: types.SUGGEST_SHOP_COMPLETE,
      payload: {
        suggestedShop: result
      }
    });
  } catch (error) {
    yield put({
      type: types.SUGGEST_SHOP_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.INIT_BEGIN, init);
  yield takeLatest(types.LOGIN_BEGIN, login);
  yield takeLatest(types.LOGOUT_BEGIN, logout);
  yield takeLatest(types.SUGGEST_SHOP_BEGIN, suggestShop);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
