import screens from "./screens";

export default [
  {
    path: "/payments",
    component: screens.TransactionList,
  },
  {
    path: "/payments/detail/:id",
    component: screens.TransactionDetail,
  },
];
