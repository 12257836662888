import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.FETCH_USERS:
      return state.isFetchingUsers;

    case constants.FETCH_USER_DETAIL:
      return state.isFetchingUserDetail;

    case constants.ADD_USER:
      return state.isAddingUsers;

    case constants.UPDATE_USER:
      return state.isUpdatingUsers;

    case constants.REMOVE_USER:
      return state.isRemovingUsers;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getUsers = createSelector([state => state.users], users => users);
export const getUserDetail = createSelector(
  [state => state.userDetail],
  userDetail => userDetail || {}
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [state => state.filter],
  filter => filter
);
