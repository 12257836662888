import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

function Pagination({ pages = [], currentPage, url, suffix }) {
  return <div className="row d-print-none">
    <div className="col-sm-12 col-md-12">
      <div
        id="products-datatable_paginate"
        className="dataTables_paginate paging_simple_numbers"

        style={{ marginTop: 20 }}
      >
        <ul className="pagination pagination-rounded" style={{ justifyContent: 'flex-end' }} >
          {
            pages.map(page =>
              <li
              key={`pagination=${page}`}
              className={classNames('paginate_button page-item', {
                active: page === currentPage
              })} >
                {
                  page === -1 && <a className="page-link" >..</a>
                }

                {
                  page !== -1 && <Link className="page-link" to={`${url}?page=${page}${suffix || ''}`}>{ page }</Link>
                }
              </li>
            )
          }
        </ul>
      </div>
    </div>
  </div>
}

export  default Pagination;