
export default class SettlementService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchSettlements({ filter, options }) {
    const { data : settlements, total: totalCount } = await this.fetchUtil.sendGet(
      `/settlements/list${this.fetchUtil.qs({
        filter,
        options
      })}`
    );

    return { settlements, totalCount };
  }

  async fetchSettlementDetail({ id }) {
    const { broadcast } = await this.fetchUtil.sendGet(
      `/settlements/detail?id=${id}`
    );

    return broadcast;
  }

  async retry({ id }) {}

  __PLACEHOLDER_METHOD__() {}
}
