import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const adCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_AD_CAMPAIGNS_BEGIN:
      return {
        ...state,
        isFetchingAdCampaigns: true,
      };

    case types.FETCH_AD_CAMPAIGNS_COMPLETE:
      return {
        ...state,
        isFetchingAdCampaigns: false,
        adCampaigns: action.payload.adCampaigns,
      };

    case types.FETCH_AD_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetchingAdCampaigns: false,
        errors: {
          ...state.errors,
          [constants.FETCH_AD_CAMPAIGNS]: action.payload.error.message,
        },
      };

    case types.FETCH_AD_CAMPAIGN_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingAdCampaignDetail: true,
      };

    case types.FETCH_AD_CAMPAIGN_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingAdCampaignDetail: false,
        adCampaign: action.payload.adCampaign,
      };

    case types.FETCH_AD_CAMPAIGN_DETAIL_ERROR:
      return {
        ...state,
        isFetchingAdCampaignDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_AD_CAMPAIGN_DETAIL]: action.payload.error.message,
        },
      };

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default adCampaignReducer;
