import { connect } from "react-redux";
import { push } from "connected-react-router";

import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  addError: error(state[constants.NAME], constants.ADD_BROADCAST),
  isAdding: loading(state[constants.NAME], constants.ADD_BROADCAST),
  updateError: error(state[constants.NAME], constants.UPDATE_BROADCAST),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_BROADCAST),
  isFetching: loading(state[constants.NAME], constants.FETCH_BROADCAST_DETAIL),
  createUpdateId: selectors.getCreateUpdateId(state[constants.NAME]),
  initialValues: !match.params.id
    ? {}
    : selectors.getBroadcastDetail(state[constants.NAME]),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchBroadcastDetail({ id: params.id }));
    }
  },
  onSubmit(broadcast) {
    broadcast.broadcast_type = broadcast.broadcast_type.id;
    // broadcast.channels = broadcast.channels.map(ch => ch.id);

    if (params.id) {
      dispatch(actions.updateBroadcast({ id: params.id, broadcast }));
    } else {
      dispatch(actions.addBroadcast({ broadcast }));
    }
  },
  clearCreateUpdateId() {
    dispatch(actions.clearCreateUpdateId());
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.ADD_BROADCAST }));
    dispatch(actions.resetError({ name: constants.UPDATE_BROADCAST }));
  },
  clearForm() {},
  goBackList(id) {
    dispatch(push(`/broadcasts/detail/${id}`));
  },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
