export const NAME = "notifications";
export const FETCH_BROADCASTS = "fetch_broadcasts";
export const FETCH_BROADCASTS_URL = "notifications/fetchBroadcasts";
export const FETCH_BROADCAST_DETAIL = "fetch_broadcast_detail";
export const FETCH_BROADCAST_DETAIL_URL = "notifications/fetchBroadcastDetail";
export const ADD_BROADCAST = "add_broadcast";
export const ADD_BROADCAST_URL = "notifications/addBroadcast";
export const UPDATE_BROADCAST = "update_broadcast";
export const UPDATE_BROADCAST_URL = "notifications/updateBroadcast";
export const REMOVE_BROADCAST = "remove_broadcast";
export const REMOVE_BROADCAST_URL = "notifications/removeBroadcast";
export const SEND_BROADCAST = "send_broadcast";
export const SEND_BROADCAST_URL = "notifications/sendBroadcast";
const __PLACEHOLDER__ = null; // ignore this.
