import queryString from 'query-string';

import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import core from 'core';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { location: { search } }) => ({
  isFetching: loading(state[constants.NAME], constants.FETCH_SHOPS),
  isDownloadingShops: loading(state[constants.NAME], constants.DOWNLOAD_SHOPS),
  isArchivingShop: loading(state[constants.NAME], constants.ARCHIVE_SHOP),
  isChangingStatus: loading(state[constants.NAME], constants.CHANGE_STATUS),
  fetchResult: selectors.getShops(state[constants.NAME]) || { shops: [] },
  filter: selectors.getFilter(state[constants.NAME]),
  shopType: (queryString.parse(search) || {}).shopType || 'all',
  ...core.selectors.getPaginationProps(
    selectors.getShops(state[constants.NAME]) || { totalCount: 0 },
    search,
  ),
  currentUser: core.selectors.getCurrentUser(
    state[core.constants.NAME]
  ) || {},
});
export const mapDispatchToProps = dispatch => ({
  list(page, filter) {
    dispatch(actions.fetchShops({ options: { page }, filter }));
  },
  downloadShops(filter) {
    dispatch(actions.downloadShops({ filter }));
  },
  archive(id) {
    dispatch(actions.archiveShop({ id }));
  },
  changeStatus(id, status) {
    dispatch(actions.changeStatus({ id, status }));
  }, 
  setFilter(filter) {
    dispatch(actions.setFilter({ filter }));
  },
  clearFilter() {
    dispatch(reset('shopsFilterForm'));
    dispatch(actions.setFilter({ filter: null }));
  },
  updateQueryString(page, shopType) {
    dispatch(push(`/shops?page=${page || 1}&shopType=${shopType}`));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
