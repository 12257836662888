import React from "react";
import moment from "moment";
import classNames from "classnames";
import { reduxForm, Field } from "redux-form";
import core from "core";

function ModuleTable({ module = {}, changeStatus, disableStatus }) {
  const _handleChangeStatus = (event, shop_id, module_uid) => {
    event.preventDefault();
    if (window.confirm("Та итгэлтэй байна уу?")) {
      changeStatus(shop_id, module_uid);
    }
  };

  const _disableStatus = (event, shop_id, module_uid) => {
    event.preventDefault();
    if (window.confirm("Та итгэлтэй байна уу?")) {
      disableStatus(shop_id, module_uid);
    }
  };
  return (
    <div id="shop-admins" className="tab-pane fade show active" role="tabpanel">
      <div className="table-responsive">
        <table className="shops-table table table-centered mb-0">
          <thead className="thead-light">
            <tr>
              <th>Модулийн нэр</th>
              <th style={{ width: 100 }}>Модулийн төрөл</th>
              <th style={{ width: 150 }}>Үүссэн</th>
              <th style={{ width: 150 }}>Төлөв</th>
              <th className="text-center" style={{ width: 50 }} />
            </tr>
          </thead>
          <tbody>
            <tr key={`tr_${module.id}`}>
              <td className="name">{`${module.module_uid}`}</td>
              <td>{module.module_type}</td>
              <td>{moment(module.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td className="text-center" style={{ width: 50 }}>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": module.status === "enabled",
                    "badge-warning": module.status === "pending",
                    "badge-danger": module.status === "disabled",
                  })}
                >
                  {module.status === "enabled" && "баталгаажсан"}
                  {module.status === "pending" && "хүлээгдэж буй"}
                  {module.status === "disabled" && "буцаагдсан"}
                </span>
              </td>
              <td>
                {module.status === "pending" && (
                  <a
                    href="#"
                    className={classNames("action-icon", {
                      "text-danger": module.status === "enabled",
                      "text-success": !module.status === "pending",
                    })}
                    onClick={(event) =>
                      _handleChangeStatus(
                        event,
                        module.shop_id,
                        module.module_uid
                      )
                    }
                  >
                    <i
                      className={classNames("mdi", {
                        "mdi-checkbox-marked-circle-outline":
                          module.status === "pending",
                        "mdi-cancel": module.status === "enabled",
                      })}
                    />
                  </a>
                )}
                {module.status === "enabled" && (
                  <a
                    href="#"
                    className="action-icon text-danger"
                    onClick={(event) =>
                      _disableStatus(event, module.shop_id, module.module_uid)
                    }
                  >
                    <i className="uil-archive-alt" />
                  </a>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "moduleDetailForm",
  enableReinitialize: true,
})(ModuleTable);
