import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";
const Pagination = core.components.Pagination;

function Table({
  rows = [],
  limit,
  currentPage,
  totalCount,
  isRemoving,
  remove,
  isChangingStatus,
  changeStatus,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 120 }}>Төлөв</th>
            <th style={{ width: 80 }}>Дугаар</th>
            <th>Гарчиг</th>
            <th style={{ width: 150 }}>Төрөл</th>
            <th style={{ width: 200 }}>Мерчант</th>
            <th style={{ width: 150 }}>Огноо</th>
            <th style={{ width: 125 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-warning": row.status === "pending",
                    "badge-success":
                      row.status === "resolved" || row.issue_type === "qpay2",
                    "badge-danger":
                      ["stuck", "cancelled", "error"].indexOf(row.status) > -1,
                  })}
                >
                  {StatusMap[row.status]}
                </span>
              </td>
              <td>#{row.code}</td>
              <td>{row.title}</td>
              <td>{row.issue_type}</td>
              <td>{row.merchant_name || "-"}</td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
              <td style={{ width: 125 }}>
                {row.status === "pending" && (
                  <a
                    href="#"
                    className="action-icon"
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        changeStatus(row.id, "resolved");
                      }
                    }}
                  >
                    <i className="uil-check-circle" />
                  </a>
                )}
                <Link to={`/issues/edit/${row.id}`} className="action-icon">
                  {" "}
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
                <a
                  href="#"
                  className="action-icon"
                  onClick={(event) => {
                    event.preventDefault();
                    if (window.confirm("Та итгэлтэй байна уу?")) {
                      if (!isRemoving) {
                        remove(row.id);
                      }
                    }
                  }}
                >
                  <i className="mdi mdi-delete" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  error: "Алдаа гарсан",
  pending: "Хүлээгдэж буй",
  resolved: "Шийдэгдсэн",
  stuck: "Гацсан",
  cancelled: "Цуцлагдсан",
};

export default Table;
