import * as types from "./actionTypes";
export const fetchMessages = ({ filter, options }) => ({
  type: types.FETCH_MESSAGES_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchMessageDetail = ({ id }) => ({
  type: types.FETCH_MESSAGE_DETAIL_BEGIN,
  payload: {
    id,
  },
});
export const changeStatus = ({ id, status }) => ({
  type: types.CHANGE_STATUS_BEGIN,
  payload: {
    id,
    status,
  },
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
