import omit from "lodash/omit";
export default class ActivitiesService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchActivities({ filter, options }) {}

  async fetchActivityDetail({ id }) {}

  __PLACEHOLDER_METHOD__() {}
}
