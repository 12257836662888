import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchProducts({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_PRODUCTS_URL,
      {
        filter,
        options
      }
    );
    yield put({
      type: types.FETCH_PRODUCTS_COMPLETE,
      payload: {
        products: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PRODUCTS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchProductCategories({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_PRODUCT_CATEGORIES_URL,
      {
        filter,
        options
      }
    );
    yield put({
      type: types.FETCH_PRODUCT_CATEGORIES_COMPLETE,
      payload: {
        admins: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PRODUCT_CATEGORIES_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* disableProduct({ payload: { id } }) {
  try {
    yield call(ServiceFactory.call, constants.DISABLE_PRODUCT_URL, {
      id
    });
    yield put({
      type: types.DISABLE_PRODUCT_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.DISABLE_PRODUCT_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* assignMainCategory({ payload: { id, codes, is_category } }) {
  try {
    yield call(ServiceFactory.call, constants.ASSIGN_MAIN_CATEGORY_URL, {
      id,
      codes,
      is_category
    });
    yield put({
      type: types.ASSIGN_MAIN_ATTRIBUTE_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.ASSIGN_MAIN_CATEGORY_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* assignMainAttribute({ payload: { id, codes, is_category } }) {
  try {
    yield call(ServiceFactory.call, constants.ASSIGN_MAIN_ATTRIBUTE_URL, {
      id,
      codes,
      is_category
    });
    yield put({
      type: types.ASSIGN_MAIN_ATTRIBUTE_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.ASSIGN_MAIN_ATTRIBUTE_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* addProduct({ payload: { product } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_PRODUCT_URL, {
      product
    });
    yield put({
      type: types.ADD_PRODUCT_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.ADD_PRODUCT_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* removeProduct({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.REMOVE_PRODUCT_URL,
      {
        id
      }
    );
    yield put({
      type: types.REMOVE_PRODUCT_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_PRODUCT_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* updateProduct({ payload: { id, product } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.UPDATE_PRODUCT_URL,
      {
        id,
        product
      }
    );
    yield put({
      type: types.UPDATE_PRODUCT_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_PRODUCT_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchProductDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_PRODUCT_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_PRODUCT_DETAIL_COMPLETE,
      payload: {
        productDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PRODUCT_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchCategories({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CATEGORIES_URL,
      {
        filter,
        options
      }
    );
    yield put({
      type: types.FETCH_CATEGORIES_COMPLETE,
      payload: {
        categories: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CATEGORIES_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchCategoryDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CATEGORY_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_CATEGORY_DETAIL_COMPLETE,
      payload: {
        categoryDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CATEGORY_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* updateCategory({ payload: { id, category } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.UPDATE_CATEGORY_URL,
      {
        id,
        category
      }
    );
    yield put({
      type: types.UPDATE_CATEGORY_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_CATEGORY_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_PRODUCTS_BEGIN, fetchProducts);
  yield takeLatest(types.DISABLE_PRODUCT_BEGIN, disableProduct);
  yield takeLatest(types.ASSIGN_MAIN_CATEGORY_BEGIN, assignMainCategory);
  yield takeLatest(types.ASSIGN_MAIN_ATTRIBUTE_BEGIN, assignMainAttribute);
  yield takeLatest(
    types.FETCH_PRODUCT_CATEGORIES_BEGIN,
    fetchProductCategories
  );
  yield takeLatest(types.ADD_PRODUCT_BEGIN, addProduct);
  yield takeLatest(types.REMOVE_PRODUCT_BEGIN, removeProduct);
  yield takeLatest(types.UPDATE_PRODUCT_BEGIN, updateProduct);
  yield takeLatest(types.FETCH_PRODUCT_DETAIL_BEGIN, fetchProductDetail);
  yield takeLatest(types.FETCH_CATEGORIES_BEGIN, fetchCategories);
  yield takeLatest(types.FETCH_CATEGORY_DETAIL_BEGIN, fetchCategoryDetail);
  yield takeLatest(types.UPDATE_CATEGORY_BEGIN, updateCategory);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
