import * as types from "./actionTypes";
export const fetchModules = ({ filter, options }) => ({
  type: types.FETCH_MODULES_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchModuleDetail = ({ id }) => ({
  type: types.FETCH_MODULE_DETAIL_BEGIN,
  payload: {
    id,
  },
});
export const changeStatus = ({ shop_id, module_uid }) => ({
  type: types.CHANGE_STATUS_BEGIN,
  payload: {
    shop_id,
    module_uid,
  },
});
export const disableStatus = ({ shop_id, module_uid }) => ({
  type: types.CHANGE_DISABLE_STATUS_BEGIN,
  payload: {
    shop_id,
    module_uid,
  },
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
