export const RESET_ERROR = "@@channels/RESET_ERROR";
export const SET_FILTER = "@@channels/SET_FILTER";
export const FETCH_CHANNELS_BEGIN = "@@channels/FETCH_CHANNELS_BEGIN";
export const FETCH_CHANNELS_COMPLETE = "@@channels/FETCH_CHANNELS_COMPLETE";
export const FETCH_CHANNELS_ERROR = "@@channels/FETCH_CHANNELS_ERROR";
export const FETCH_CHANNEL_DETAIL_BEGIN =
  "@@channels/FETCH_CHANNEL_DETAIL_BEGIN";
export const FETCH_CHANNEL_DETAIL_COMPLETE =
  "@@channels/FETCH_CHANNEL_DETAIL_COMPLETE";
export const FETCH_CHANNEL_DETAIL_ERROR =
  "@@channels/FETCH_CHANNEL_DETAIL_ERROR";

const __PLACEHOLDER__ = null; // ignore this.
