import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";

import core from "core";

function Table({ rows = [], limit, currentPage }) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-orders-list mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th style={{ width: 120 }}>Төлөв</th>
            <th style={{ width: 120 }}>Нөлөөлөгч</th>
            <th style={{ width: 150 }}>Дүн</th>
            <th style={{ width: 150 }}>Шилжүүлэх дүн</th>
            <th style={{ width: 150 }}>Нийт урамшуулал</th>
            <th style={{ width: 150 }}>Огноо</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={`tr_${row.id}`}
              className={classNames("", {
                unread: !row.is_read,
              })}
            >
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.status === "published",
                    "badge-warning": row.status === "pending",
                    "badge-danger": row.status === "cancelled",
                  })}
                >
                  {StatusMap[row.status]}
                </span>
              </td>
              <td>{row.seller_name}</td>
              <td className="text-right">
                {!row.total_amount
                  ? "-"
                  : numeral(row.total_amount).format("0,0")}
              </td>
              <td className="text-right">
                {!row.transfer_amount
                  ? "-"
                  : numeral(row.transfer_amount).format("0,0")}
              </td>
              <td className="text-right">
                {!row.total_commision
                  ? "-"
                  : numeral(row.total_commision).format("0,0")}
              </td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: "Хүлээгдэж буй",
  published: "Идэвхтэй",
  cancelled: "Цуцлагдсан",
};

export default Table;
