export const NAME = "users";
export const FETCH_USERS = "fetch_users";
export const FETCH_USERS_URL = "users/fetchUsers";
export const FETCH_USER_DETAIL = "fetch_user_detail";
export const FETCH_USER_DETAIL_URL = "users/fetchUserDetail";
export const ADD_USER = "add_user";
export const ADD_USER_URL = "users/addUser";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_URL = "users/updateUser";
export const REMOVE_USER = "remove_user";
export const REMOVE_USER_URL = "users/removeUser";
const __PLACEHOLDER__ = null; // ignore this.
