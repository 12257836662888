import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const shopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SHOPS_BEGIN:
      return {
        ...state,
        isFetchingShops: true,
      };

    case types.FETCH_SHOPS_COMPLETE:
      return {
        ...state,
        isFetchingShops: false,
        shops: action.payload.shops,
      };

    case types.FETCH_SHOPS_ERROR:
      return {
        ...state,
        isFetchingShops: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SHOPS]: action.payload.error.message,
        },
      };

    case types.FETCH_SHOP_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingShopDetail: true,
      };

    case types.FETCH_SHOP_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingShopDetail: false,
        shopDetail: action.payload.shopDetail,
      };

    case types.FETCH_SHOP_DETAIL_ERROR:
      return {
        ...state,
        isFetchingShopDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SHOP_DETAIL]: action.payload.error.message,
        },
      };

    case types.FETCH_CATEGORIES_BEGIN:
      return {
        ...state,
        isFetchingShopCategories: true,
      };

    case types.FETCH_CATEGORIES_COMPLETE:
      return {
        ...state,
        isFetchingShopCategories: false,
        shopCategories: action.payload.shopCategories,
      };

    case types.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingShopCategories: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SHOP_DETAIL]: action.payload.error.message,
        },
      };

    case types.CHANGE_STATUS_BEGIN:
      return {
        ...state,
        isChangingStatus: true,
      };

    case types.CHANGE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingStatus: false,
      };

    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isChangingStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_STATUS]: action.payload.error.message,
        },
      };

    case types.UPDATE_SHOP_BEGIN:
      return {
        ...state,
        isUpdatingShop: true,
      };

    case types.UPDATE_SHOP_COMPLETE:
      return {
        ...state,
        isUpdatingShop: false,
      };

    case types.UPDATE_SHOP_ERROR:
      return {
        ...state,
        isUpdatingShop: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_SHOP]: action.payload.error.message,
        },
      };

    case types.ADD_OPTION_BEGIN:
      return {
        ...state,
        isAddingOptions: true,
      };

    case types.ADD_OPTION_COMPLETE:
      return {
        ...state,
        isAddingOptions: false,
      };

    case types.ADD_OPTION_ERROR:
      return {
        ...state,
        isAddingOptions: false,
        errors: {
          ...state.errors,
          [constants.ADD_OPTION]: action.payload.error.message,
        },
      };

    case types.REMOVE_OPTION_BEGIN:
      return {
        ...state,
        isRemovingOption: true,
      };

    case types.REMOVE_OPTION_COMPLETE:
      return {
        ...state,
        isRemovingOption: false,
      };

    case types.REMOVE_OPTION_ERROR:
      return {
        ...state,
        isRemovingOption: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_OPTION]: action.payload.error.message,
        },
      };

    case types.ADD_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        isAddingPM: true,
      };

    case types.ADD_PAYMENT_METHOD_COMPLETE:
      return {
        ...state,
        isAddingPM: false,
      };

    case types.ADD_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        isAddingPM: false,
        errors: {
          ...state.errors,
          [constants.ADD_PAYMENT_METHOD]: action.payload.error.message,
        },
      };

    case types.REMOVE_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        isRemovingPM: true,
      };

    case types.REMOVE_PAYMENT_METHOD_COMPLETE:
      return {
        ...state,
        isRemovingPM: false,
      };

    case types.REMOVE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        isRemovingPM: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_PAYMENT_METHOD]: action.payload.error.message,
        },
      };

    case types.LINK_DOMAIN_BEGIN:
      return {
        ...state,
        isLinkingDomain: true,
      };

    case types.LINK_DOMAIN_COMPLETE:
      return {
        ...state,
        isLinkingDomain: false,
      };

    case types.LINK_DOMAIN_ERROR:
      return {
        ...state,
        isLinkingDomain: false,
        errors: {
          ...state.errors,
          [constants.LINK_DOMAIN]: action.payload.error.message,
        },
      };

    case types.UPDATE_MERCHANT_MODULE_BEGIN:
      return {
        ...state,
        isUpdatingBalance: true,
      };

    case types.UPDATE_MERCHANT_MODULE_COMPLETE:
      return {
        ...state,
        isUpdatingBalance: false,
      };

    case types.UPDATE_MERCHANT_MODULE_ERROR:
      return {
        ...state,
        isUpdatingBalance: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_MERCHANT_MODULE]: action.payload.error.message,
        },
      };

    case types.ADJUST_PLAN_MODULE_BEGIN:
      return {
        ...state,
        isAdjustingPlanModule: true,
      };

    case types.ADJUST_PLAN_MODULE_COMPLETE:
      return {
        ...state,
        isAdjustingPlanModule: false,
      };

    case types.ADJUST_PLAN_MODULE_ERROR:
      return {
        ...state,
        isAdjustingPlanModule: false,
        errors: {
          ...state.errors,
          [constants.ADJUST_PLAN_MODULE]: action.payload.error.message,
        },
      };

    case types.CHECK_DOMAIN_BEGIN:
      return {
        ...state,
        isCheckingDomain: true,
      };

    case types.CHECK_DOMAIN_COMPLETE:
      return {
        ...state,
        isCheckingDomain: false,
      };

    case types.CHECK_DOMAIN_ERROR:
      return {
        ...state,
        isCheckingDomain: false,
        errors: {
          ...state.errors,
          [constants.LINK_DOMAIN]: action.payload.error.message,
        },
      };

    case types.REMOVE_DOMAIN_BEGIN:
      return {
        ...state,
        isRemovingDomain: true,
      };

    case types.REMOVE_DOMAIN_COMPLETE:
      return {
        ...state,
        isRemovingDomain: false,
      };

    case types.REMOVE_DOMAIN_ERROR:
      return {
        ...state,
        isRemovingDomain: false,
        errors: {
          ...state.errors,
          [constants.LINK_DOMAIN]: action.payload.error.message,
        },
      };

    case types.FETCH_SHOP_HITS_BEGIN:
      return {
        ...state,
        isFetchingShopHits: true,
        shopHits: {
          days: [],
          months: [],
        },
      };

    case types.FETCH_SHOP_HITS_COMPLETE:
      return {
        ...state,
        isFetchingShopHits: false,
        shopHits: action.payload.shopHits,
      };

    case types.FETCH_SHOP_HITS_ERROR:
      return {
        ...state,
        isFetchingShopHits: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SHOP_HITS]: action.payload.error.message,
        },
      };

    case types.FETCH_SHOP_ORDER_STAT_BEGIN:
      return {
        ...state,
        isFetchingShopOrderStat: true,
        shopOrderStat: {
          days: [],
          months: [],
        },
      };

    case types.FETCH_SHOP_ORDER_STAT_COMPLETE:
      return {
        ...state,
        isFetchingShopOrderStat: false,
        shopOrderStat: action.payload.shopOrderStat,
      };

    case types.FETCH_SHOP_ORDER_STAT_ERROR:
      return {
        ...state,
        isFetchingShopOrderStat: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SHOP_ORDER_STAT]: action.payload.error.message,
        },
      };

    case types.ARCHIVE_SHOP_BEGIN:
      return {
        ...state,
        isArchivingShop: true,
      };

    case types.ARCHIVE_SHOP_COMPLETE:
      return {
        ...state,
        isArchivingShop: false,
      };

    case types.ARCHIVE_SHOP_ERROR:
      return {
        ...state,
        isArchivingShop: false,
        errors: {
          ...state.errors,
          [constants.ARCHIVE_SHOP]: action.payload.error.message,
        },
      };

    case types.DOWNLOAD_SHOPS_BEGIN:
      return {
        ...state,
        isDownloadingShops: true,
      };

    case types.DOWNLOAD_SHOPS_COMPLETE:
      return {
        ...state,
        isDownloadingShops: false,
      };

    case types.DOWNLOAD_SHOPS_ERROR:
      return {
        ...state,
        isDownloadingShops: false,
        errors: {
          ...state.errors,
          [constants.DOWNLOAD_SHOPS]: action.payload.error.message,
        },
      };

    case types.LINK_USER_BEGIN:
      return {
        ...state,
        isLinkingUser: true,
      };

    case types.LINK_USER_COMPLETE:
      return {
        ...state,
        isLinkingUser: false,
      };

    case types.LINK_USER_ERROR:
      return {
        ...state,
        isLinkingUser: false,
        errors: {
          ...state.errors,
          [constants.LINK_USER]: action.payload.error.message,
        },
      };

    case types.UNLINK_USER_BEGIN:
      return {
        ...state,
        isUnlinkingUser: true,
      };

    case types.UNLINK_USER_COMPLETE:
      return {
        ...state,
        isUnlinkingUser: false,
      };

    case types.UNLINK_USER_ERROR:
      return {
        ...state,
        isUnlinkingUser: false,
        errors: {
          ...state.errors,
          [constants.UNLINK_USER]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default shopsReducer;
