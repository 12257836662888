import omit from "lodash/omit";
export default class DomainService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchDomains({ filter, options }) {}

  async fetchDomainDetail({ id }) {}

  async addDomain({ domain }) {}

  async updateDomain({ id, domain }) {}

  async removeDomain({ id }) {}

  __PLACEHOLDER_METHOD__() {}
}
