import { Field } from "redux-form";
import React, { Fragment, useEffect, useState } from "react";

import core from "core";
import enhancers from "./enhancers";
import axios from "axios";
import Select from "react-select";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function ManagerForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  getResult,
}) {
  // useEffect(() => fetchDetail(), []);
  // useEffect(() => () => clearForm(), []);

  const [adminId, setAdminId] = useState();
  const [options, setOptions] = useState();
  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors
  );
  const isSaving = isAdding || isUpdating;

  useEffect(() => {
    setAdminId(getResult);
  }, [getResult]);

  const handleChange = async (e) => {
    const filtered = Array.from(e, (e) => e.value);

    setOptions(filtered);
    const token = await localStorage.getItem("zm__access_token");

    const headers = {
      "Content-Type": "application/json",
      "access-token": token,
    };

    const data = {
      roles: filtered,
      manager_id: adminId.id,
    };
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/manager/add/roles`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <PageTitle title={`Админ ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Овог">
                    <Field
                      required
                      name="last_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Овог"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Нэр">
                    <Field
                      required
                      name="first_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэр"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Нэвтрэх нэр">
                    <Field
                      required
                      name="username"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэвтрэх нэр"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Утас">
                    <Field
                      required
                      name="phone"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Утас"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup
                    required={!isEdit ? true : undefined}
                    label="Нууц үг"
                  >
                    <Field
                      required={!isEdit ? true : undefined}
                      name="password"
                      type="password"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нууц үг"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup label="Регистрийн дугаар">
                    <Field
                      name="regno"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Регистрийн дугаар"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Үргэжлүүлэх</button>
                </div>
              </div>
            </form>
          )}
          {adminId && (
            <div className="mt-3">
              <span>Менежэр ийн эрх сонгох</span>
              <Select
                onChange={handleChange}
                options={[
                  { value: "shop_view", label: "Дэлгүүр үзэх" },
                  { value: "shop_manage", label: "Дэлгүүр удирдах" },
                  { value: "user_view", label: "Хэрэглэгч үзэх" },
                  { value: "user_manage", label: "Хэрэглэгч удирдах" },
                  { value: "order_view", label: "Захиалга үзэх" },
                  { value: "order_manage", label: "Захиалга удирдах" },
                  {
                    value: "settlement_view",
                    label: "Өндөрлөгөө үзэх",
                  },
                  {
                    value: "settlement_manage",
                    label: "Өндөрлөгөө удирдах",
                  },
                  { value: "product_view", label: "Бүтээгдэхүүн үзэх" },
                  {
                    value: "product_manage",
                    label: "Бүтээгдэхүүн удирдах",
                  },
                  {
                    value: "product_category_view",
                    label: "Бүтээгдэхүүн ангилал үзэх",
                  },
                  {
                    value: "product_category_manage",
                    label: "Бүтээгдэхүүн ангилалal удирдах",
                  },
                  { value: "broadcast_view", label: "Зарлал үзэх" },
                  {
                    value: "broadcast_manage",
                    label: "Зарлал удирдах",
                  },
                  { value: "issue_view", label: "Хүсэлт үзэх" },
                  { value: "issue_manage", label: "Хүсэлт удирдах" },
                  { value: "seller_view", label: "socialpay" },
                  { value: "seller_manage", label: "socialpay" },
                ]}
                isMulti
                name="roles"
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const AdminFormWithForm = enhancers.form(ManagerForm);
const AdminFormWithState = enhancers.redux(AdminFormWithForm);

export default AdminFormWithState;
