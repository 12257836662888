export const NAME = "issues";
export const FETCH_ISSUES = "fetch_issues";
export const FETCH_ISSUES_URL = "issues/fetchIssues";
export const FETCH_ISSUE_DETAIL = "fetch_issue_detail";
export const FETCH_ISSUE_DETAIL_URL = "issues/fetchIssueDetail";
export const ADD_ISSUE = "add_issue";
export const ADD_ISSUE_URL = "issues/addIssue";
export const UPDATE_ISSUE = "update_issue";
export const UPDATE_ISSUE_URL = "issues/updateIssue";
export const REMOVE_ISSUE = "remove_issue";
export const REMOVE_ISSUE_URL = "issues/removeIssue";
export const CHANGE_STATUS = "change_status";
export const CHANGE_STATUS_URL = "issues/changeStatus";
const __PLACEHOLDER__ = null; // ignore this.
