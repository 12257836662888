import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchChannels({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CHANNELS_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_CHANNELS_COMPLETE,
      payload: {
        channels: result,
      },
    });
  } catch (error) {
    console.error(error)
    yield put({
      type: types.FETCH_CHANNELS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchChannelDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CHANNEL_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_CHANNEL_DETAIL_COMPLETE,
      payload: {
        channelDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CHANNEL_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}

const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_CHANNELS_BEGIN, fetchChannels);
  yield takeLatest(types.FETCH_CHANNEL_DETAIL_BEGIN, fetchChannelDetail);

  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
