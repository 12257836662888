import * as constants from "./constants";
import { createSelector } from "reselect";

const loadingSelector = function (state, featureName) {
  switch (featureName) {
    case constants.FETCH_AD_CAMPAIGNS:
      return state.isFetchingAdCampaigns;

    case constants.FETCH_AD_CAMPAIGN_DETAIL:
      return state.isFetchingAdCampaignDetail;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getCampaigns = createSelector(
  [(state) => state.adCampaigns],
  (adCampaigns) => adCampaigns
);
export const getCampaignDetail = createSelector(
  [(state) => state.adCampaign],
  (adCampaign) => adCampaign
);

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
