import sort from "lodash/sortBy";
import React, { Fragment, useEffect } from "react";
import { Field } from "redux-form";

import core from "core";
import enhancers from "./enhancers";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function ContractForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearForm(), []);

  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    goBackList
  );

  const isSaving = isAdding || isUpdating;
  return (
    <Fragment>
      <PageTitle title={`Гэрээнүүд`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Овог">
                    <Field
                      required
                      name="last_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Овог"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Нэр">
                    <Field
                      required
                      name="first_name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэр"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Утас">
                    <Field
                      required
                      name="phone"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Утас"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup
                    required={!isEdit ? true : undefined}
                    label="Нууц үг"
                  >
                    <Field
                      required={!isEdit ? true : undefined}
                      name="password"
                      type="password"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нууц үг"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const ContractFormWithForm = enhancers.form(ContractForm);
const ContractFormWithState = enhancers.redux(ContractFormWithForm);

export default ContractFormWithState;
