export default class ContractsService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchContracts({ filter, options }) {
    const { data: contracts, total: totalCount } = await this.fetchUtil.sendGet(
      `/merchant-contracts/list${this.fetchUtil.qs({ filter, options })}`
    );
    return { contracts, totalCount };
  }

  async fetchContractDetail({ id }) {
    const { data: contract } = await this.fetchUtil.sendGet(
      `/merchant-contracts/detail/${id}`
    );
    return contract;
  }

  async changeStatus({ id, shop_id, status }) {
    const { data: contract } = await this.fetchUtil.sendPost(
      `/merchant-contracts/change-status`,
      {
        id,
        merchant_id: shop_id,
        status,
      }
    );
    return contract;
  }
}
