import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function BroadcastList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  limit,
  currentPage,
  fetchResult,
  remove,
  isRemoving,
  list,
  updateQueryString,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);
  useEffect(
    () => {
      if (isRemoving === false) {
        list(currentPage, filter);
      }
    },
    [isRemoving]
  );
  return (
    <Fragment>
      <PageTitle title="Зарлалууд" />
      <div className="card">
        <div className="card-body">

          <Filter
            newUrl="/broadcasts/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}

            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ['name']);

                if (formData.status) {
                  theFilter.status = formData.status.id;
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Table
              limit={limit}
              remove={remove}
              isRemoving={isRemoving}
              currentPage={currentPage}
              rows={fetchResult.broadcasts}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
const BroadcastListWithState = enhancers.redux(BroadcastList);
export default BroadcastListWithState;
