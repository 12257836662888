import * as types from "./actionTypes";
export const fetchWithdrawals = ({ filter, options }) => ({
  type: types.FETCH_WITHDRAWALS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchWithdrawalDetail = ({ id }) => ({
  type: types.FETCH_WITHDRAWAL_DETAIL_BEGIN,
  payload: {
    id,
  },
});

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
