import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchShops({ payload: { filter, options } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.FETCH_SHOPS_URL, {
      filter,
      options,
    });
    yield put({
      type: types.FETCH_SHOPS_COMPLETE,
      payload: {
        shops: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SHOPS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchShopDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_SHOP_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_SHOP_DETAIL_COMPLETE,
      payload: {
        shopDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SHOP_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchCategories() {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_CATEGORIES_URL,
      {}
    );
    yield put({
      type: types.FETCH_CATEGORIES_COMPLETE,
      payload: {
        shopCategories: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CATEGORIES_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* changeStatus({ payload: { id, status } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_STATUS_URL,
      {
        id,
        status,
      }
    );
    yield put({
      type: types.CHANGE_STATUS_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* updateShop({ payload: { id, shop } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.UPDATE_SHOP_URL, {
      id,
      shop,
    });
    yield put({
      type: types.UPDATE_SHOP_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_SHOP_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addOption({ payload: { id, key, value } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_OPTION_URL, {
      id,
      key,
      value,
    });
    yield put({
      type: types.ADD_OPTION_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.ADD_OPTION_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* removeOption({ payload: { id, key } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.REMOVE_OPTION_URL,
      {
        id,
        key,
      }
    );
    yield put({
      type: types.REMOVE_OPTION_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_OPTION_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addPaymentMethod({
  payload: { id, provider, account_number, account_holder, bank },
}) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.ADD_PAYMENT_METHOD_URL,
      {
        id,
        bank,
        provider,
        account_number,
        account_holder,
      }
    );
    yield put({
      type: types.ADD_PAYMENT_METHOD_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.ADD_PAYMENT_METHOD_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* removePaymentMethod({ payload: { id, provider } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.REMOVE_PAYMENT_METHOD_URL,
      {
        id,
        provider,
      }
    );
    yield put({
      type: types.REMOVE_PAYMENT_METHOD_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_PAYMENT_METHOD_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* linkDomain({ payload: { id, domain, theme } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.LINK_DOMAIN_URL, {
      id,
      domain,
      theme,
    });
    yield put({
      type: types.LINK_DOMAIN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.LINK_DOMAIN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* updateMerchantModule({ payload: { shop_id, balance } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.UPDATE_MERCHANT_MODULE_URL,
      {
        shop_id,
        balance,
      }
    );
    yield put({
      type: types.UPDATE_MERCHANT_MODULE_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_MERCHANT_MODULE_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* adjustPlanModule({
  payload: { shop_id, plan_uid, quantity },
}) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.ADJUST_PLAN_MODULE_URL,
      {
        shop_id,
        plan_uid,
        quantity,
      }
    );
    yield put({
      type: types.ADJUST_PLAN_MODULE_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.ADJUST_PLAN_MODULE_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* checkDomain({ payload: { id, domain } }) {
  try {
    yield call(ServiceFactory.call, constants.CHECK_DOMAIN_URL, {
      id,
      domain,
    });
    yield put({
      type: types.CHECK_DOMAIN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_DOMAIN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* removeDomain({ payload: { id } }) {
  try {
    yield call(ServiceFactory.call, constants.REMOVE_DOMAIN_URL, {
      id,
    });
    yield put({
      type: types.REMOVE_DOMAIN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_DOMAIN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchShopHits({ payload: { shop_id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_SHOP_HITS_URL,
      {
        shop_id,
      }
    );
    yield put({
      type: types.FETCH_SHOP_HITS_COMPLETE,
      payload: {
        shopHits: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SHOP_HITS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchShopOrderStat({ payload: { shop_id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_SHOP_ORDER_STAT_URL,
      {
        shop_id,
      }
    );
    yield put({
      type: types.FETCH_SHOP_ORDER_STAT_COMPLETE,
      payload: {
        shopOrderStat: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SHOP_ORDER_STAT_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* archiveShop({ payload: { id } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ARCHIVE_SHOP_URL, {
      id,
    });
    yield put({
      type: types.ARCHIVE_SHOP_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.ARCHIVE_SHOP_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* downloadShops({ payload: { filter } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.DOWNLOAD_SHOPS_URL,
      {
        filter,
      }
    );
    yield put({
      type: types.DOWNLOAD_SHOPS_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.DOWNLOAD_SHOPS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* linkUser({ payload: { id, phone } }) {
  try {
    yield call(ServiceFactory.call, constants.LINK_USER_URL, {
      id,
      phone,
    });
    yield put({
      type: types.LINK_USER_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.LINK_USER_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* unlinkUser({ payload: { id, shop_id } }) {
  try {
    yield call(ServiceFactory.call, constants.UNLINK_USER_URL, {
      id,
      shop_id,
    });
    yield put({
      type: types.UNLINK_USER_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.UNLINK_USER_ERROR,
      payload: {
        error,
      },
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_SHOPS_BEGIN, fetchShops);
  yield takeLatest(types.FETCH_SHOP_DETAIL_BEGIN, fetchShopDetail);
  yield takeLatest(types.FETCH_CATEGORIES_BEGIN, fetchCategories);
  yield takeLatest(types.CHANGE_STATUS_BEGIN, changeStatus);
  yield takeLatest(types.UPDATE_SHOP_BEGIN, updateShop);
  yield takeLatest(types.ADD_OPTION_BEGIN, addOption);
  yield takeLatest(types.REMOVE_OPTION_BEGIN, removeOption);
  yield takeLatest(types.ADD_PAYMENT_METHOD_BEGIN, addPaymentMethod);
  yield takeLatest(types.REMOVE_PAYMENT_METHOD_BEGIN, removePaymentMethod);
  yield takeLatest(types.LINK_DOMAIN_BEGIN, linkDomain);
  yield takeLatest(types.CHECK_DOMAIN_BEGIN, checkDomain);
  yield takeLatest(types.UPDATE_MERCHANT_MODULE_BEGIN, updateMerchantModule);
  yield takeLatest(types.ADJUST_PLAN_MODULE_BEGIN, adjustPlanModule);
  yield takeLatest(types.REMOVE_DOMAIN_BEGIN, removeDomain);
  yield takeLatest(types.FETCH_SHOP_HITS_BEGIN, fetchShopHits);
  yield takeLatest(types.FETCH_SHOP_ORDER_STAT_BEGIN, fetchShopOrderStat);
  yield takeLatest(types.ARCHIVE_SHOP_BEGIN, archiveShop);
  yield takeLatest(types.DOWNLOAD_SHOPS_BEGIN, downloadShops);
  yield takeLatest(types.LINK_USER_BEGIN, linkUser);
  yield takeLatest(types.UNLINK_USER_BEGIN, unlinkUser);
  // eslint-disable-next-line no-unused-vars
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
