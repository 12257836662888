import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
  saveError: error(state[constants.NAME], constants.CHANGE_PASSWORD),
  isSaving: loading(state[constants.NAME], constants.CHANGE_PASSWORD),
});
export const mapDispatchToProps = dispatch => ({
  onSubmit({ newPassword, currentPassword, confirm }) {
    if (newPassword != confirm) {
      return window.alert('Шинэ нууц үгээ дахиж зөв оруулна уу!');
    }

    dispatch(actions.changePassword({ newPassword, currentPassword }));
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.CHANGE_PASSWORD }));
  },
  clearForm() {},
  goBackList() {
    dispatch(push('/'));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
