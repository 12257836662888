export const RESET_ERROR = "@@campaigns/RESET_ERROR";
export const SET_FILTER = "@@campaigns/SET_FILTER";
export const FETCH_CAMPAIGNS_BEGIN = "@@campaigns/FETCH_CAMPAIGNS_BEGIN";
export const FETCH_CAMPAIGNS_COMPLETE = "@@campaigns/FETCH_CAMPAIGNS_COMPLETE";
export const FETCH_CAMPAIGNS_ERROR = "@@campaigns/FETCH_CAMPAIGNS_ERROR";
export const FETCH_CAMPAIGN_DETAIL_BEGIN =
  "@@campaigns/FETCH_CAMPAIGN_DETAIL_BEGIN";
export const FETCH_CAMPAIGN_DETAIL_COMPLETE =
  "@@campaigns/FETCH_CAMPAIGN_DETAIL_COMPLETE";
export const FETCH_CAMPAIGN_DETAIL_ERROR =
  "@@campaigns/FETCH_CAMPAIGN_DETAIL_ERROR";
export const ADD_CAMPAIGN_BEGIN = "@@campaigns/ADD_CAMPAIGN_BEGIN";
export const ADD_CAMPAIGN_COMPLETE = "@@campaigns/ADD_CAMPAIGN_COMPLETE";
export const ADD_CAMPAIGN_ERROR = "@@campaigns/ADD_CAMPAIGN_ERROR";
export const UPDATE_CAMPAIGN_BEGIN = "@@campaigns/UPDATE_CAMPAIGN_BEGIN";
export const UPDATE_CAMPAIGN_COMPLETE = "@@campaigns/UPDATE_CAMPAIGN_COMPLETE";
export const UPDATE_CAMPAIGN_ERROR = "@@campaigns/UPDATE_CAMPAIGN_ERROR";
export const REMOVE_CAMPAIGN_BEGIN = "@@campaigns/REMOVE_CAMPAIGN_BEGIN";
export const REMOVE_CAMPAIGN_COMPLETE = "@@campaigns/REMOVE_CAMPAIGN_COMPLETE";
export const REMOVE_CAMPAIGN_ERROR = "@@campaigns/REMOVE_CAMPAIGN_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
