import React, { Fragment } from "react";

function Tabs({ tabIndex, setTabIndex }) {
  return (
    <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
      <li className="nav-item">
        <a
          href="#shop-admins"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link rounded-0 active"
        >
          Модулийн хүсэлт
        </a>
      </li>
    </ul>
  );
}

export default Tabs;
