export const RESET_ERROR = "@@transactions/RESET_ERROR";
export const SET_FILTER = "@@transactions/SET_FILTER";
export const FETCH_TRANSACTIONS_BEGIN = "@@transactions/FETCH_TRANSACTIONS_BEGIN";
export const FETCH_TRANSACTIONS_COMPLETE = "@@transactions/FETCH_TRANSACTIONS_COMPLETE";
export const FETCH_TRANSACTIONS_ERROR = "@@transactions/FETCH_TRANSACTIONS_ERROR";
export const FETCH_TRANSACTION_DETAIL_BEGIN = "@@transactions/FETCH_TRANSACTION_DETAIL_BEGIN";
export const FETCH_TRANSACTION_DETAIL_COMPLETE =
  "@@transactions/FETCH_TRANSACTION_DETAIL_COMPLETE";
export const FETCH_TRANSACTION_DETAIL_ERROR = "@@transactions/FETCH_TRANSACTION_DETAIL_ERROR";
export const CHANGE_STATUS_BEGIN = "@@transactions/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@transactions/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@transactions/CHANGE_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
