export const RESET_ERROR = "@@payments/RESET_ERROR";
export const SET_FILTER = "@@payments/SET_FILTER";
export const FETCH_PAYMENTS_BEGIN = "@@payments/FETCH_PAYMENTS_BEGIN";
export const FETCH_PAYMENTS_COMPOLETE = "@@payments/FETCH_PAYMENTS_COMPOLETE";
export const FETCH_PAYMENTS_ERROR = "@@payments/FETCH_PAYMENTS_ERROR";
export const FETCH_PAYMENT_DETAIL_BEGIN = "@@payments/FETCH_PAYMENT_DETAIL_BEGIN";
export const FETCH_PAYMENT_DETAIL_COMPLETE =
  "@@payments/FETCH_PAYMENT_DETAIL_COMPLETE";
export const FETCH_PAYMENT_DETAIL_ERROR = "@@payments/FETCH_PAYMENT_DETAIL_ERROR";
export const CHANGE_STATUS_BEGIN = "@@payments/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@payments/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@payments/CHANGE_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
