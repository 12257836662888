import { Field } from "redux-form";
import React, { Fragment, useEffect } from "react";

import core from "core";
import enhancers from "./enhancers";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function ProductForm({
  isEdit,
  handleSubmit,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  currentPage,
}) {
  useEffect(() => fetchDetail(), []);
  useEffect(() => () => clearForm(), []);

  const onSubmit = useFormSubmit(
    isUpdating,
    updateError,
    handleSubmit,
    resetErrors,
    () => goBackList(currentPage),
  );

  return (
    <Fragment>
      <PageTitle title={`Бүтээгдэхүүн ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <FormGroup required label="Нэр">
                    <Field
                      required
                      name="name"
                      type="text"
                      component="input"
                      disabled={isUpdating}
                      className="form-control"
                      placeholder="Нэр"
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-4">
                  <FormGroup required label="Үнэ">
                    <Field
                      required
                      name="price"
                      type="number"
                      component="input"
                      disabled={isUpdating}
                      className="form-control"
                      placeholder="Үнэ"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup required label="Төлөв">
                    <Field
                      required
                      name="status"
                      disabled={isUpdating}
                      className="form-control"
                      placeholder="Сонгох..."
                      options={[
                        { label: 'Идэвхтэй', value: 'enabled' },
                        { label: 'Идэвхгүй', value: 'disabled' },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <FormGroup label="Мастер ангилал">
                    <Field
                      multi={true}
                      name="main_category_codes"
                      placeholder="Үндсэн ангилал.."
                      options={[
                        { value: "A5", label: "Гоо сайхан" },
                        { value: "A3", label: "Спорт" },
                        { value: "A4", label: "Хүнс" },
                        { value: "A9", label: "Бичиг хэрэг" },
                        { value: "A2", label: "Эрэгтэй" },
                        { value: "A0", label: "Гэр ахуй" },
                        { value: "A1", label: "Эмэгтэй" },
                        { value: "A7", label: "Үнэт эдлэл" },
                        { value: "A6", label: "Хүүхдийн" },
                        { value: "A8", label: "Электрон бараа" },
                        { value: "A11", label: "Авто" },
                        { value: "A12", label: "Хувцас" },
                        { value: "A13", label: "Амьтан" },
                        { value: "A15", label: "Хөгжим" },
                        { value: "A14", label: "Эрүүл мэнд" },
                        { value: "A16", label: "Үйлчилгээ" },
                        { value: "A17", label: "Сургалт" },
                        { value: "A18", label: "Цүнх" },
                        { value: "A19", label: "Гутал" },
                        { value: "A20", label: "Бусад"}
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                <div className="col-xl-6">
                  <FormGroup label="Борлуулалтын суваг">
                    <Field
                      multi={true}
                      name="sale_channels"
                      placeholder="Борлуулалтын суваг.."
                      options={[
                        { value: "supersale", label: "supersale.mn" },
                        { value: "moodroom", label: "moodroom.mn" },
                        { value: "hipay", label: "hipay" },
                        { value: "monpay", label: "monpay" },
                        { value: "socialpay", label: "socialpay" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const ProductFormWithForm = enhancers.form(ProductForm);
const ProductFormWithState = enhancers.redux(ProductFormWithForm);

export default ProductFormWithState;
