import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  addError: error(state[constants.NAME], constants.ADD_USER),
  isAdding: loading(state[constants.NAME], constants.ADD_USER),
  updateError: error(state[constants.NAME], constants.UPDATE_USER),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_USER),
  isFetching: loading(state[constants.NAME], constants.FETCH_USER_DETAIL),
  shops: selectors.getUserDetail(
    state[constants.NAME],
  ).shops || [],
  initialValues: !match.params.id ? {} : selectors.getUserDetail(
    state[constants.NAME],
  ),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    if (params.id) {
      dispatch(actions.fetchUserDetail({ id: params.id }));  
    }
  },
  onSubmit(user) {
    if (params.id) {
      dispatch(actions.updateUser({ id: params.id, user }));
    } else {
      dispatch(actions.addUser({ user }));
    }
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.ADD_USER }));
    dispatch(actions.resetError({ name: constants.UPDATE_USER }));
  },
  clearForm() {},
  goBackList() {
    dispatch(push('/users'));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
