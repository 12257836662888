export default class ModulesService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchModules({ filter, options }) {
    const { data: modules, total: totalCount } = await this.fetchUtil.sendGet(
      `/module-subscriptions/list${this.fetchUtil.qs({ filter, options })}`
    );
    return { modules, totalCount };
  }

  async fetchModuleDetail({ id }) {
    const { data: module } = await this.fetchUtil.sendGet(
      `/module-subscriptions/detail/${id}`
    );
    return module;
  }

  async changeStatus({ shop_id, module_uid }) {
    await this.fetchUtil.sendPost(`/module-subscriptions/enable`, {
      shop_id,
      module_uid,
    });
  }

  async changeDisableStatus({ shop_id, module_uid }) {
    await this.fetchUtil.sendPost(`/module-subscriptions/disable`, {
      shop_id,
      module_uid,
    });
  }
}
