import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";

function Table({ rows = [], limit, currentPage }) {
  
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-orders-list mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>
              #
            </th>
            <th style={{ width: 120 }}>
              Төлөв
            </th>
            <th style={{ width: 120 }}>
              Гүйлгээний дугаар
            </th>
            <th style={{ width: 200 }}>
              Захиалгын код
            </th>
            <th style={{ width: 150 }} className="text-right">
              Дүн
            </th>
            <th style={{ width: 100 }}>
              T.Х
            </th>
            <th style={{ width: 150 }}>
              Огноо
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={`tr_${row.id}`}
            >
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>
                <span
                  className={classNames("badge p-1", {
                    "badge-success":
                      ["verified", "paid"].indexOf(row.status) > -1,
                    "badge-warning": row.status === "pending",
                    "badge-danger": row.status === "error",
                  })}
                >
                  {StatusMap[row.status]}
                </span>
              </td>
              <td>{row.invoiceno}</td>
              <td>
                <Link to={`/transactions/detail/${row.id}`}>
                  {row.order_code}
                </Link>
              </td>

              <td className="text-right">
                {numeral(row.amount).format("0,0")}
              </td>
              <td>{row.provider}</td>
              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: "Хүлээгдэж буй",
  paid: "Батаалгаажсан",
  verified: "Батаалгаажсан",
  delivered: "Хүргэгдсэн",
  cancelled: "Цуцлагдсан",
  error: "Алдаа гарсан",
};

export default Table;
