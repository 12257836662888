import moment from "moment";
import download from "downloadjs";
import decodeb64 from "b64-to-blob";
export default class ShopService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchShops({ filter, options }) {
    const { data: shops, total: totalCount } = await this.fetchUtil.sendGet(
      `/shops/list${this.fetchUtil.qs({
        filter,
        options,
      })}`
    );

    return { shops, totalCount };
  }

  async downloadShops({ filter }) {
    const { data } = await this.fetchUtil.sendGet(
      `/shops/export-as-xlsx${this.fetchUtil.qs({
        filter,
        options: {},
      })}`
    );
    return download(decodeb64(data), "shops.xlsx");
  }

  async fetchShopDetail({ id }) {
    const { data: shop } = await this.fetchUtil.sendGet(`/shops/detail/${id}`);

    if (shop) {
      shop.expire_at = new Date(shop.expire_at);

      for (const option of shop.options) {
        shop[option.key] = option.value;
      }

      if (!shop.order_sms_template_1) {
        shop.order_sms_template_1 = `Tani zahialga burtgegdlee.{{new_line}}Guilgeenii utga: {{code}}{{new_line}}{{bank_accounts}}{{new_line}}Tuluh dun: {{total_price}}MNT.`;
      }

      if (!shop.order_sms_template_2) {
        shop.order_sms_template_2 = `Tani {{code}} dugaartai zahialga amjilttai batalgaajlaa.{{new_line}}Zahialgiin dun: {{total_price}}MNT.`;
      }
    }

    return shop;
  }

  async fetchCategories() {
    const { data: categories } = await this.fetchUtil.sendGet(
      `/shops/list-categories`
    );
    return categories || [];
  }

  async changeStatus({ id, status }) {
    await this.fetchUtil.sendPost(`/shops/${status}`, {
      id,
    });
  }

  async addOption({ id, key, value }) {
    await this.fetchUtil.sendPost(`/shops/add-option`, {
      id,
      key,
      value,
    });
  }

  async removeOption({ id, key }) {
    await this.fetchUtil.sendPost(`/shops/remove-option`, {
      id,
      key,
    });
  }

  async addPaymentMethod({
    id,
    bank,
    provider,
    account_number,
    account_holder,
  }) {
    await this.fetchUtil.sendPost(`/shops/add-payment-method`, {
      id,
      provider,
      account_number,
      account_holder,
      bank,
    });
  }

  async removePaymentMethod({ id, provider }) {
    await this.fetchUtil.sendPost(`/shops/remove-payment-method`, {
      id,
      provider,
    });
  }

  async linkDomain({ id, domain, theme }) {
    const { success, description } = await this.fetchUtil.sendPost(
      `/domains/link-domain`,
      {
        merchant_id: id,
        domain,
        theme,
      }
    );

    if (!success && description) {
      throw new Error(description);
    }
  }

  async checkDomain({ id, domain }) {
    await this.fetchUtil.sendPost(`/domains/check-domain`, {
      merchant_id: id,
      domain,
    });
  }

  async removeDomain({ id }) {
    await this.fetchUtil.sendPost(`/domains/remove`, {
      id,
    });
  }

  async fetchShopHits({ shop_id }) {
    const { start, end } = this.get_range();
    const { data: months } = await this.fetchUtil.sendGet(
      `/analytics/hits-by-month?merchant_id=${shop_id}`
    );
    const { data: days } = await this.fetchUtil.sendGet(
      `/analytics/hits-by-day?start=${start}&end=${end}&merchant_id=${shop_id}`
    );
    return {
      months,
      days,
    };
  }

  async fetchShopOrderStat({ shop_id }) {
    const { start, end } = this.get_range();
    const { data: months } = await this.fetchUtil.sendGet(
      `/analytics/orders-by-month?merchant_id=${shop_id}`
    );
    const { data: days } = await this.fetchUtil.sendGet(
      `/analytics/orders-by-day?start=${start}&end=${end}&merchant_id=${shop_id}`
    );
    return {
      months,
      days,
    };
  }

  async archiveShop({ id }) {
    await this.fetchUtil.sendPost("/shops/archive", {
      id,
    });
  }

  async updateShop({ id, shop }) {
    if (shop.enabled_delivery_companies) {
      shop.enabled_delivery_companies = JSON.stringify(
        shop.enabled_delivery_companies
      );
    }

    await this.fetchUtil.sendPost("/shops/update", {
      id,
      ...shop,
    });
  }

  async linkUser({ id, phone }) {
    await this.fetchUtil.sendPost("/shops/link-user", {
      id,
      phone,
    });
  }

  async unlinkUser({ id, shop_id }) {
    await this.fetchUtil.sendPost("/shops/unlink-user", {
      user_id: id,
      shop_id,
    });
  }

  async fetchMerchantModules({ shop_id }) {
    const { data: merchant_module } = await this.fetchUtil.sendGet(
      `/merchant-modules/detail${shop_id}`
    );

    return { merchant_module };
  }

  async updateBalance({ shop_id, balance }) {
    await this.fetchUtil.sendPost("/merchant-modules/update-balance", {
      shop_id,
      balance,
    });
  }

  async adjustPlanModule({ shop_id, plan_uid, quantity }) {
    await this.fetchUtil.sendPost("/shops/adjust-plan", {
      shop_id,
      plan_uid,
      quantity,
    });
  }

  __PLACEHOLDER_METHOD__() {}

  get_range() {
    return {
      end: moment().endOf("day").toISOString(),
      start: moment().subtract(30, "days").startOf("day").toISOString(),
    };
  }
}
