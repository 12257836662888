import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import React, { PureComponent, Fragment } from 'react';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants'; 
import { transformOptToVal, transformValToOpt } from '../../helpers';

const loading = selectors.makeLoadingSelector();

const mapStateToProps = state => ({
  shops: selectors.getSuggestedShops(state[constants.NAME]) || [] ,
  isLoading: loading(state[constants.NAME], constants.SUGGEST_SHOP),
});

const mapDispatchToProps = dispatch => ({
  suggestShop(name) {
    dispatch(actions.suggestShop({ name }));
  },
})

class ShopSelector extends PureComponent {
  render() {
    return (
      <Fragment>
        <div style={{ float: 'left', width: '100%' }} > 
          <ReactSelect
            options={[
              ...this.props.shops.map(shop => ({
                value: shop.id,
                label: shop.name,
              }))
            ]}
            isMulti={this.props.multi}
            required={this.props.required}
            isLoading={this.props.isLoading}
            placeholder={this.props.placeholder || 'Дэлгүүр...'}
            value={transformValToOpt(
              this.props.input.value,
              this.props.multi,
            )}
            isDisabled={this.props.disabled}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: !state.isFocused ? '#dee2e6' : '#c1c9d0',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#919ca7',
              }),
              ...this.props.styles,
            }}
            onChange={option =>
              this.props.input.onChange(transformOptToVal(
                option,
                this.props.multi
              ))
            }
            onInputChange={debounce(name => this.props.suggestShop(name), 400)}
            isClearable={true}
            noOptionsMessage={() => "Илэрцгүй..."}
          />
        </div>     
      </Fragment>
    );
  }
}

const ShopSelectorWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopSelector)

export default ShopSelectorWithState;
