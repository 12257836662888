export const RESET_ERROR = "@@ad_campaigns/RESET_ERROR";
export const SET_FILTER = "@@ad_campaigns/SET_FILTER";
export const FETCH_AD_CAMPAIGNS_BEGIN =
  "@@ad_campaigns/FETCH_AD_CAMPAIGNS_BEGIN";
export const FETCH_AD_CAMPAIGNS_COMPLETE =
  "@@ad_campaigns/FETCH_AD_CAMPAIGNS_COMPLETE";
export const FETCH_AD_CAMPAIGNS_ERROR =
  "@@ad_campaigns/FETCH_AD_CAMPAIGNS_ERROR";
export const FETCH_AD_CAMPAIGN_DETAIL_BEGIN =
  "@@ad_campaigns/FETCH_AD_CAMPAIGN_DETAIL_BEGIN";
export const FETCH_AD_CAMPAIGN_DETAIL_COMPLETE =
  "@@ad_campaigns/FETCH_AD_CAMPAIGN_DETAIL_COMPLETE";
export const FETCH_AD_CAMPAIGN_DETAIL_ERROR =
  "@@ad_campaigns/FETCH_AD_CAMPAIGN_DETAIL_ERROR";
