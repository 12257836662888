export const NAME = "products";
export const FETCH_PRODUCTS = "fetch_products";
export const FETCH_PRODUCTS_URL = "products/fetchProducts";
export const DISABLE_PRODUCT = "disable_product";
export const DISABLE_PRODUCT_URL = "products/disable";
export const ASSIGN_MAIN_CATEGORY = "assign_main_category";
export const ASSIGN_MAIN_CATEGORY_URL = "products/assignMainCategory";
export const ASSIGN_MAIN_ATTRIBUTE = "assign_main_category";
export const ASSIGN_MAIN_ATTRIBUTE_URL = "products/assignMainAttribute";
export const FETCH_PRODUCT_CATEGORIES = "fetch_product_categories";
export const FETCH_PRODUCT_CATEGORIES_URL = "products/fetchProductCategories";
export const ADD_PRODUCT = "add_product";
export const ADD_PRODUCT_URL = "products/addProduct";
export const REMOVE_PRODUCT = "remove_product";
export const REMOVE_PRODUCT_URL = "products/removeProduct";
export const UPDATE_PRODUCT = "update_product";
export const UPDATE_PRODUCT_URL = "products/updateProduct";
export const FETCH_PRODUCT_DETAIL = "fetch_product_detail";
export const FETCH_PRODUCT_DETAIL_URL = "products/fetchProductDetail";
export const FETCH_CATEGORIES = "fetch_categories";
export const FETCH_CATEGORIES_URL = "products/fetchCategories";
export const FETCH_CATEGORY_DETAIL = "fetch_category_detail";
export const FETCH_CATEGORY_DETAIL_URL = "products/fetchCategoryDetail";
export const UPDATE_CATEGORY = "update_category";
export const UPDATE_CATEGORY_URL = "products/updateCategory";
const __PLACEHOLDER__ = null; // ignore this.
