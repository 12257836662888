import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
// import { fetchTransactionDetail } from "./actions";


export function* fetchTransactions({ payload: { filter, options } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.FETCH_TRANSACTIONS_URL, {
      filter,
      options
    });
    
    yield put({
      type: types.FETCH_TRANSACTIONS_COMPLETE,
      payload: {
        transactions: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_TRANSACTIONS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchTransactionDetail({ payload: { id } }) {
  
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_TRANSACTION_DETAIL_URL,
      {
        id
      }
    );
    yield put({
      type: types.FETCH_TRANSACTION_DETAIL_COMPLETE,
      payload: {
        transactionDetail: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_TRANSACTION_DETAIL_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* changeStatus({ payload: { id, status } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_STATUS_URL,
      {
        id,
        status
      }
    );
    yield put({
      type: types.CHANGE_STATUS_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_TRANSACTIONS_BEGIN, fetchTransactions);
  yield takeLatest(types.FETCH_TRANSACTION_DETAIL_BEGIN, fetchTransactionDetail);
  yield takeLatest(types.CHANGE_STATUS_BEGIN, changeStatus);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
