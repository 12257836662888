import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import core from 'core';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

import * as shopActions from 'app/shops/actions';
import * as shopConstants from 'app/shops/constants';
import * as shopSelectors from 'app/shops/selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();
const shopsLoading = shopSelectors.makeLoadingSelector();

export const mapStateToProps = (state, { location: { search } }) => ({
  isSending: loading(state[constants.NAME], constants.SEND_BROADCAST),
  isFetching: loading(state[constants.NAME], constants.FETCH_BROADCAST_DETAIL),
  shops: (shopSelectors.getShops(state[shopConstants.NAME]) || { shops: [] }).shops,
  isFetchingShops: shopsLoading(
    state[shopConstants.NAME],
    shopConstants.FETCH_SHOPS
  ),
  filter: selectors.getShopFilter(state[constants.NAME]),
  shopIds: selectors.getShopIds(state[constants.NAME]) || [],
  broadcast: selectors.getBroadcastDetail(state[constants.NAME]) || {},
  status: core.helpers.parseQueryString(search).status,
  ...core.selectors.getPaginationProps(
    shopSelectors.getShops(state[shopConstants.NAME]) || { totalCount: 0 },
    search,
  ),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchShops(page, filter) {
    dispatch(shopActions.fetchShops({ options: { page }, filter }));
  },
  sendBroadcast(shop_ids) {
    dispatch(actions.sendBroadcast({ id: params.id, shop_ids }));
  },
  fetchDetail() {
    dispatch(actions.fetchBroadcastDetail({ id: params.id }));
  },
  setFilter(filter) {
    dispatch(actions.setShopFilter({ filter }));
  },
  setShopIds(ids) {
    dispatch(actions.setShopIds({ ids }));
  },
  clearFilter() {
    dispatch(reset('broadcastShopsFilterForm'));
    dispatch(actions.setShopFilter({ filter: null }));
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
