import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.CHANGE_PASSWORD:
      return state.isChangingPassword;
      break;

    case constants.FETCH_SUMMARY:
      return state.isFetchingSummary;
      break;

    case constants.FETCH_TOP_HITS:
      return state.isFetchingTopHits;
      break;

    case constants.FETCH_DAILY_HITS:
      return state.isFetchingDailyHits;
      break;

    case constants.FETCH_MONTHLY_HITS:
      return state.isFetchingMonthlyHits;
      break;

    case constants.FETCH_DAILY_ORDERS:
      return state.isFetchingDailyOrders;
      break;

    case constants.FETCH_MONTHLY_ORDERS:
      return state.isFetchingMonthlyOrders;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getFilter = createSelector(
  [state => state.filter],
  filter => filter
);
export const getDashboardSummary = createSelector(
  [state => state.dashboardSummary],
  dashboardSummary => dashboardSummary
);
export const getTopHits = createSelector(
  [state => state.topHits],
  topHits => topHits
);
export const getDailyHits = createSelector(
  [state => state.dailtyHits],
  dailtyHits => dailtyHits
);
export const getMonthlyHits = createSelector(
  [state => state.monthlyHits],
  monthlyHits => monthlyHits
);
export const getDailyOrders = createSelector(
  [state => state.dailyOrders],
  dailyOrders => dailyOrders
);
export const getMonthlyOrders = createSelector(
  [state => state.monthlyOrders],
  monthlyOrders => monthlyOrders
);
const __PLACEHOLDER__ = null; // ignore this.
