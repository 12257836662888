import screens from "./screens";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/ad/sellers",
    component: screens.AdSellerList,
  },
  // {
  //   path: "/ad/campaigns/:id",
  //   component: screens.OrderDetail,
  // },
];
