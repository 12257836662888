import { withRouter } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';

import Login from '../Login';
import Footer from '../Footer';
import Navbar from '../Navbar';
import MainMenu from '../MainMenu';
import enhancers from './enhancers';

export class MainLayout extends PureComponent {
  render() {
    const { currentUser } = this.props;

    return <Fragment>
      {
        !!currentUser && <div className="content-page">
          <div className={`content${!currentUser.is_observer ? '' : ' content-observer'}`}>
            <Navbar currentUser={currentUser} />
            {
              (!currentUser.is_observer && !currentUser.is_exec) &&
              <MainMenu currentUser={currentUser} />
            }

            <div className="container-fluid cnt">
              <div className="row cnt">
                <div className="col-12 cnt">
                  { this.props.children }
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      }

      {
        !currentUser && <Login />
      }
    </Fragment>
  }
}

const MainLayoutWithState = withRouter(enhancers.redux(MainLayout));
export default MainLayoutWithState;
