/* global toastr */
import { useEffect, useState } from 'react';

export function useFormSubmit(
  loading,
  error,
  handleSubmit,
  resetError,
  goBackList,
  message,
) {
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!loading && !error && submitting) {
      goBackList();
    }

    if (!loading && error && submitting) {
      toastr.error(`${message || 'Алдаа гарлаа'}. ${error}`, "Алдаа");
      resetError();
      setSubmitting(false);
    }
  }, [loading]);

  return (event) => {
    resetError();
    setSubmitting(true);
    handleSubmit(event);
  }
}