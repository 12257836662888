import * as types from "./actionTypes";
export const fetchUsers = ({ filter, options }) => ({
  type: types.FETCH_USERS_BEGIN,
  payload: {
    filter,
    options
  }
});
export const fetchUserDetail = ({ id }) => ({
  type: types.FETCH_USER_DETAIL_BEGIN,
  payload: {
    id
  }
});
export const addUser = ({ user }) => ({
  type: types.ADD_USER_BEGIN,
  payload: {
    user
  }
});
export const updateUser = ({ id, user }) => ({
  type: types.UPDATE_USER_BEGIN,
  payload: {
    id,
    user
  }
});
export const removeUser = ({ id }) => ({
  type: types.REMOVE_USER_BEGIN,
  payload: {
    id
  }
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter
  }
});
