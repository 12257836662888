import React from "react";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";

function Table({ rows = [], limit, currentPage }) {

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-orders-list mb-0">
        <thead className="thead-light">
          <tr>
            <th rowSpan="2" style={{ width: 20 }}>
              #
            </th>
            <th rowSpan="2" style={{ width: 120 }}>
              Төлөв
            </th>

            <th rowSpan="2" style={{ width: 200 }}>
            Захиалгын Код
            </th>
            <th rowSpan="2" style={{ width: 120 }}>
              Хүсэлт
            </th>
            <th rowSpan="2" style={{ width: 200 }}>
              Үйлдэл
            </th>
            <th rowSpan="2" style={{ width: 100 }}>
              T.Х
            </th>
            <th rowSpan="2" style={{ width: 150 }}>
              Огноо
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={`tr_${row.id}`}
            >
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td>{row.status}</td>

              <td>
                <Link to={`/payments/detail/${row.id}`}>
                  {row.order_id}
                </Link>
              </td>
              <td>{row.api_method}</td>
              <td>{row.action}</td>

              <td>{row.provider}</td>

              <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StatusMap = {
  pending: "Хүлээгдэж буй",
  verified: "Батаалгаажсан",
  delivered: "Хүргэгдсэн",
  cancelled: "Цуцлагдсан",
};

export default Table;
