import * as types from "./actionTypes";
export const fetchAdmins = ({ filter, options }) => ({
  type: types.FETCH_ADMINS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchAdminDetail = ({ id }) => ({
  type: types.FETCH_ADMIN_DETAIL_BEGIN,
  payload: {
    id,
  },
});
export const addAdmin = ({ admin }) => ({
  type: types.ADD_ADMIN_BEGIN,
  payload: {
    admin,
  },
});
export const addRoles = ({ admin }) => ({
  type: types.ADD_ROLES_BEGIN,
  payload: {
    admin,
  },
});
export const addManager = ({ admin }) => ({
  type: types.ADD_MANAGER_BEGIN,
  payload: {
    admin,
  },
});
export const updateAdmin = ({ id, admin }) => ({
  type: types.UPDATE_ADMIN_BEGIN,
  payload: {
    id,
    admin,
  },
});
export const removeAdmin = ({ id }) => ({
  type: types.REMOVE_ADMIN_BEGIN,
  payload: {
    id,
  },
});

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
