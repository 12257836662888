import { connect } from "react-redux";
import { push } from "connected-react-router";

import core from "core";
import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
  isFetching: loading(state[constants.NAME], constants.FETCH_MODULE_DETAIL),
  module: selectors.getModuleDetail(state[constants.NAME]) || {},
  changeStatusError: error(state[constants.NAME], constants.CHANGE_STATUS),
  isChangingStatus: loading(state[constants.NAME], constants.CHANGE_STATUS),
  changeDisableStatusError: error(
    state[constants.NAME],
    constants.CHANGE_DISABLE_STATUS
  ),
  isChangingDisableStatus: loading(
    state[constants.NAME],
    constants.CHANGE_DISABLE_STATUS
  ),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchModule() {
    if (params.id) {
      dispatch(actions.fetchModuleDetail({ id: params.id }));
    }
  },
  changeStatus(shop_id, module_uid) {
    dispatch(actions.changeStatus({ shop_id, module_uid }));
  },
  disableStatus(shop_id, module_uid) {
    dispatch(actions.disableStatus({ shop_id, module_uid }));
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.FETCH_MODULE_DETAIL }));
  },
  clearForm() {},
  goBackList() {
    dispatch(push("/modules"));
  },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
