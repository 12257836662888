import React from "react";
import moment from "moment";
import classNames from "classnames";
import { reduxForm, Field } from "redux-form";

import core from "core";

function ModulesPanel({ handleSubmit, merchantModules, isUpdatingBalance }) {
  return (
    <div id="shop-modules" className="tab-pane fade" role="tabpanel">
      {
        <div className="row mb-2">
          <div className="col-lg-12">
            <form
              onSubmit={handleSubmit}
              className="form-inline shop-filter-form"
            >
              <div style={{ width: 250 }} className="form-group mr-2">
                <Field
                  style={{ width: 250 }}
                  required
                  disabled={isUpdatingBalance}
                  component="input"
                  type="number"
                  className="form-control"
                  placeholder="Нэмэх утгаа энд бичнэ үү"
                  name="balance"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  // disabled={isLinkingUser || isUnlinkingUser}
                  className="btn btn-primary text-bold"
                >
                  Цэнэглэх
                </button>
              </div>
            </form>
            <p className="alert alert-info mt-2">
              <strong>САНАМЖ!</strong> Уг харилцагч урьд нь огт SMS цэнэглэж
              байгаагүй бол доорх талбар хоосон гарч ирнэ. Хэрэв харилцагч SMS
              үлдэгдэлтэй байвал таны оруулсан тоо өмнөх үлдэгдэл дээр нэмэгдэж
              орно. Та цэнэглэх утгаа нягталж байгаад оруулна уу!
            </p>
          </div>
        </div>
      }
      {
        <div className="table-responsive">
          <table className="shops-table table table-centered mb-0">
            <thead className="thead-light">
              <tr>
                <th style={{ width: 20 }}>#</th>
                <th>Модуль</th>
                <th style={{ width: 150 }}>Дуусах хугацаа</th>
                <th style={{ width: 150 }}>Үлдэгдэл эрх</th>
                <th className="text-center" style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {merchantModules && (
                <tr key={`tr_${merchantModules.id}`}>
                  <td>1</td>
                  <td className="module">{merchantModules.module}</td>
                  <td>
                    {!merchantModules.expire_at
                      ? "-"
                      : moment(merchantModules.expire_at).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                  </td>
                  <td>
                    <b>{merchantModules.balance}</b>{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default reduxForm({
  form: "modulesPanel",
  enableReinitialize: true,
})(ModulesPanel);
