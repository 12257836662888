import * as types from "./actionTypes";
import { push } from "connected-react-router";

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const init = () => ({
  type: types.INIT_BEGIN
});
export const login = ({ username, password }) => ({
  type: types.LOGIN_BEGIN,
  payload: {
    username,
    password
  }
});
export const logout = () => ({
  type: types.LOGOUT_BEGIN
});
export const goToHome = () => push("/");
export const goToPath = path => push(path);
export const suggestShop = ({ name }) => ({
  type: types.SUGGEST_SHOP_BEGIN,
  payload: {
    name
  }
});
const __PLACEHOLDER__ = null; // ignore this.
