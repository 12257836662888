export default class ActivitiesService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchWithdrawals({ filter, options }) {
    const { data: withdrawals, total: totalCount } =
      await this.fetchUtil.sendGet(
        `/withdrawals${this.fetchUtil.qs({ filter, options })}`
      );
    return { withdrawals, totalCount };
  }

  async fetchWithdrawalDetail({ id }) {
    const { data: withdrawal, total: totalCount } =
      await this.fetchUtil.sendGet(`/withdrawals/${id}`);
    return { withdrawal, totalCount };
  }
}
