import screens from "./screens";

export default [
  {
    exact: true,
    path: "/campaigns",
    component: screens.CampaignList,
  },
  {
    exact: true,
    path: "/campaigns/new",
    component: screens.CampaignForm,
  },
  {
    exact: true,
    path: "/campaigns/edit/:id",
    component: screens.CampaignForm,
  },
];
