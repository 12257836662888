import numeral from 'numeral';
import classNames from 'classnames';
import React, { Fragment, useEffect } from "react";

function Summary({ summary }) {
  return <div className="card widget-inline mt-3">
    <div className="card-body p-0">
      <div className="row no-gutters">
        <SummaryItem
          icon="uil-postcard"
          title={summary.shopsCount ? `${summary.shopsSubscribedCount} / ${summary.shopsCount}`  : '-'}
          desc="Нийт дэлгүүр"
          isFirst={true}
        />
        <SummaryItem
          icon="uil-users-alt"
          title={summary.customersCount || '-'}
          desc="Нийт харилцагчид"
        />
        <SummaryItem
          icon="uil-chart-growth"
          title={!summary.ordersCount ? '-' : `${numeral(summary.ordersAmount).format('0,0')} / ${summary.ordersCount}`}
          desc="Нийт захиалга"
        />
        <SummaryItem
          icon="uil-user-plus"
          title={summary.productsCount || '-'}
          desc="Нийт бүтээгдэхүүн"
        />
      </div>
    </div>
  </div>;
}

function SummaryItem({ icon, title, desc, isFirst=false }) {
  return <div className="col-sm-6 col-xl-3">
    <div className={classNames('card shadow-none m-0', {
      "border-left": !isFirst
    })}>
      <div className="card-body text-center">
        <i className={icon} style={{ fontSize: 25 }} />
        <h3><span>{ title }</span></h3>
        <p className="text-muted font-15 mb-0">
          { desc }
        </p>
      </div>
    </div>
  </div>
}

export default Summary;