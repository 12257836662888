import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MODULES_BEGIN:
      return {
        ...state,
        isFetchingModules: true,
      };

    case types.FETCH_MODULES_COMPLETE:
      return {
        ...state,
        isFetchingModules: false,
        modules: action.payload.modules,
      };

    case types.FETCH_MODULES_ERROR:
      return {
        ...state,
        isFetchingModules: false,
        errors: {
          ...state.errors,
          [constants.FETCH_MODULES]: action.payload.error.message,
        },
      };

    case types.FETCH_MODULE_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingModuleDetail: true,
      };

    case types.FETCH_MODULE_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingModuleDetail: false,
        moduleDetail: action.payload.moduleDetail,
      };

    case types.FETCH_MODULE_DETAIL_ERROR:
      return {
        ...state,
        isFetchingModuleDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_MODULE_DETAIL]: action.payload.error.message,
        },
      };

    case types.CHANGE_STATUS_BEGIN:
      return {
        ...state,
        isChangingStatus: true,
      };

    case types.CHANGE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingStatus: false,
      };

    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isChangingStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_STATUS]: action.payload.error.message,
        },
      };

    case types.CHANGE_DISABLE_STATUS_BEGIN:
      return {
        ...state,
        isChangingdDisableStatus: true,
      };

    case types.CHANGE_DISABLE_STATUS_COMPLETE:
      return {
        ...state,
        isChangingDisableStatus: false,
      };

    case types.CHANGE_DISABLE_STATUS_ERROR:
      return {
        ...state,
        isChangingDisableStatus: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_DISABLE_STATUS]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default modulesReducer;
