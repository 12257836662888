export const RESET_ERROR = "@@users/RESET_ERROR";
export const SET_FILTER = "@@users/SET_FILTER";
export const FETCH_USERS_BEGIN = "@@users/FETCH_USERS_BEGIN";
export const FETCH_USERS_COMPLETE = "@@users/FETCH_USERS_COMPLETE";
export const FETCH_USERS_ERROR = "@@users/FETCH_USERS_ERROR";
export const FETCH_USER_DETAIL_BEGIN = "@@users/FETCH_USER_DETAIL_BEGIN";
export const FETCH_USER_DETAIL_COMPLETE = "@@users/FETCH_USER_DETAIL_COMPLETE";
export const FETCH_USER_DETAIL_ERROR = "@@users/FETCH_USER_DETAIL_ERROR";
export const ADD_USER_BEGIN = "@@users/ADD_USER_BEGIN";
export const ADD_USER_COMPLETE = "@@users/ADD_USER_COMPLETE";
export const ADD_USER_ERROR = "@@users/ADD_USER_ERROR";
export const UPDATE_USER_BEGIN = "@@users/UPDATE_USER_BEGIN";
export const UPDATE_USER_COMPLETE = "@@users/UPDATE_USER_COMPLETE";
export const UPDATE_USER_ERROR = "@@users/UPDATE_USER_ERROR";
export const REMOVE_USER_BEGIN = "@@users/REMOVE_USER_BEGIN";
export const REMOVE_USER_COMPLETE = "@@users/REMOVE_USER_COMPLETE";
export const REMOVE_USER_ERROR = "@@users/REMOVE_USER_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
