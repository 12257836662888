import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.FETCH_PRODUCTS:
      return state.isFetchingProducts;

    case constants.FETCH_PRODUCT_CATEGORIES:
      return state.isFetchingProductCategories;

    case constants.DISABLE_PRODUCT:
      return state.isDisablingProduct;

    case constants.ASSIGN_MAIN_CATEGORY:
      return state.isAssigningCategory;

    case constants.ASSIGN_MAIN_ATTRIBUTE:
      return state.isAssigningAttribute;

    case constants.ADD_PRODUCT:
      return state.isAddingProduct;

    case constants.REMOVE_PRODUCT:
      return state.isRemovingProduct;

    case constants.UPDATE_PRODUCT:
      return state.isUpdatingProduct;

    case constants.FETCH_PRODUCT_DETAIL:
      return state.isFetchingProductDetail;

    case constants.FETCH_CATEGORIES:
      return state.isFetchingCategories;

    case constants.FETCH_CATEGORY_DETAIL:
      return state.isFetchingCategoryDetail;

    case constants.UPDATE_CATEGORY:
      return state.isUpdatingCategory;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getProducts = createSelector(
  [state => state.products],
  products => products
);
export const getProductDetail = createSelector(
  [state => state.productDetail],
  productDetail => productDetail
);
export const getCategories = createSelector(
  [state => state.categories],
  categories => categories
);
export const getCategoryDetail = createSelector(
  [state => state.categoryDetail],
  categoryDetail => categoryDetail
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [state => state.filter],
  filter => filter
);
