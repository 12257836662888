export const RESET_ERROR = "@@withdrawals/RESET_ERROR";
export const SET_FILTER = "@@withdrawals/SET_FILTER";
export const FETCH_WITHDRAWALS_BEGIN = "@@withdrawals/FETCH_WITHDRAWALS_BEGIN";
export const FETCH_WITHDRAWALS_COMPLETE =
  "@@withdrawals/FETCH_WITHDRAWALS_COMPLETE";
export const FETCH_WITHDRAWALS_ERROR = "@@withdrawals/FETCH_WITHDRAWALS_ERROR";
export const FETCH_WITHDRAWAL_DETAIL_BEGIN =
  "@@withdrawals/FETCH_WITHDRAWAL_DETAIL_BEGIN";
export const FETCH_WITHDRAWAL_DETAIL_COMPLETE =
  "@@withdrawals/FETCH_WITHDRAWAL_DETAIL_COMPLETE";
export const FETCH_WITHDRAWAL_DETAIL_ERROR =
  "@@withdrawals/FETCH_WITHDRAWAL_DETAIL_ERROR";
