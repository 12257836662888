export const RESET_ERROR = "@@messages/RESET_ERROR";
export const SET_FILTER = "@@messages/SET_FILTER";
export const FETCH_MESSAGES_BEGIN = "@@messages/FETCH_MESSAGES_BEGIN";
export const FETCH_MESSAGES_COMPLETE = "@@messages/FETCH_MESSAGES_COMPLETE";
export const FETCH_MESSAGES_ERROR = "@@messages/FETCH_MESSAGES_ERROR";
export const FETCH_MESSAGE_DETAIL_BEGIN =
  "@@messages/FETCH_MESSAGE_DETAIL_BEGIN";
export const FETCH_MESSAGE_DETAIL_COMPLETE =
  "@@messages/FETCH_MESSAGE_DETAIL_COMPLETE";
export const FETCH_MESSAGE_DETAIL_ERROR =
  "@@messages/FETCH_MESSAGE_DETAIL_ERROR";
export const CHANGE_STATUS_BEGIN = "@@messages/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@messages/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@messages/CHANGE_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
