import { toUpper } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import PlanAdjustion from "./plan-adjuster";

function Summary({ shop, isAdjustingPlanModule, adjustPlanModule }) {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <div className="card text-center">
      <span className="badge-success">
        <b>
          {shop.is_trial
            ? "ТУРШИЛТЫН ХУГАЦААНД ЯВЖ БУЙ ДЭЛГҮҮР"
            : `${shop.plan.toUpperCase()} БАГЦ /ДУУСАХ: ${formatDate(
                shop.expire_at
              )}/`}
        </b>
      </span>
      <div className="card-body">
        <a href={`https://${shop.uid}.zochil.shop`} target="_blank">
          <div
            style={{
              display: "inline-block",
              backgroundImage: `url('${shop.logo}')`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="rounded-circle avatar-lg img-thumbnail"
          />
        </a>

        <h4 className="mb-0 mt-2 mb-2">
          <a
            style={{ color: "#919ca7" }}
            target="_blank"
            href={`https://${shop.uid}.zochil.shop`}
          >
            {shop.name}
          </a>

          <Link
            to={`/shops/edit/${shop.id}`}
            style={{ marginLeft: 10, padding: `4px 5px` }}
            className="btn btn-xs btn-primary"
          >
            Засах
          </Link>
        </h4>
        <p className="text-muted font-14">{shop.phone}</p>
        <div className="text-left mt-3 mb-2">
          <h4 className="font-13 text-uppercase">Товч танилцуулга</h4>
          <p>{shop.description}</p>
        </div>
        <PlanAdjustion
          plan={(shop || {}).plan}
          isAdjustingPlanModule={isAdjustingPlanModule}
          onSubmit={({ plan_uid, quantity }) => {
            if (window.confirm("Зөв оруулсан эсэхээ шалгана уу!!")) {
              adjustPlanModule(shop.id, plan_uid.id, quantity);
            }
          }}
        />
      </div>
    </div>
  );
}

export default Summary;
