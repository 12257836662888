import React from "react";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import core from "core";

function Summary({ module }) {
  return (
    <div className="card text-center">
      <div className="card-body">
        <a href={`https://${module.uid}.zochil.shop`} target="_blank">
          <div
            style={{
              display: "inline-block",
              backgroundImage: `url('${module.logo}')`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="rounded-circle avatar-lg img-thumbnail"
          />
        </a>

        <h4 className="mb-0 mt-2 mb-2">
          <a
            style={{ color: "#919ca7" }}
            target="_blank"
            href={`https://zochil.mn`}
          >
            {module.name}
          </a>
        </h4>
        <Link
          to={`/shops/edit/${module.id}`}
          style={{ marginLeft: 10, padding: `4px 5px` }}
          className="btn btn-xs btn-primary"
        >
          Дэлгүүрийн дэлгэрэнгүй
        </Link>
        <p className="text-muted font-14">{module.phone}</p>
      </div>
    </div>
  );
}

export default Summary;
