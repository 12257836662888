import numeral from 'numeral';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export default function Chart({
  data,
  title,
  height,
  barColor,
  defaultDataKey,
  hideChooser = false,
}) {
  const [width, setWidth] = useState(0);
  const [range, setRange] = useState('days');
  const [dataKey, setDataKey] = useState(defaultDataKey || 'total_amount');

  useEffect(() => {
    setWidth(document.getElementById("chart-container").offsetWidth);
  }, []);

  return <div className="card status-reporting">
    <div className="card-body">
      <div className="btn-group p-0 float-right mb-3">
        <button
          type="button"
          onClick={() => setRange('days')}
          className={classNames('btn btn-light', { active : range === 'days' })}
        >
          Өдрөөр
        </button>
        <button
          type="button"
          onClick={() => setRange('months')}
          className={classNames('btn btn-light', { active : range === 'months' })}
        >
          Жилээр
        </button>
      </div>

      {
        hideChooser === false &&
        <div className="btn-group p-0 float-right mb-3 mr-3">
          <button
            type="button"
            onClick={() => setDataKey('total_amount')}
            className={classNames('btn btn-light', { active : dataKey === 'total_amount' })}
          >
            Дүн
          </button>
          <button
            type="button"
            onClick={() => setDataKey('total_count')}
            className={classNames('btn btn-light', { active : dataKey === 'total_count' })}
          >
            Тоо
          </button>
        </div>
      }

      <h4 className="header-title mt-2">
        { title } 
      </h4>

      <div id="chart-container">      
        <BarChart
          data={data[range]}
          width={width}
          height={height || 450}

        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey={range === 'days' ? 'day': 'month'} />
          <YAxis tickFormatter={numberFormatter} />
          <Tooltip formatter={numberFormatter} />
          <Legend />
          <Bar
            stackId="a"
            fill={barColor || "#44badc"}
            name={dataKey === 'total_amount' ? 'Дүн' : 'Тоо'}
            dataKey={dataKey}
          />
        </BarChart>
      </div>
    </div>
  </div>;
}

function numberFormatter(value) {
  if (value >= 1000000) {
    return numeral(value/1000000).format('0,0.0') + ' сая';
  } else  if (value > 1000) {
    return numeral(value/1000).format('0,0.0') + ' мя';
  } else {
    return value;
  }
}