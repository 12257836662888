import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {},
};

const adminsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ADMINS_BEGIN:
      return {
        ...state,
        isFetchingAdmins: true,
      };

    case types.FETCH_ADMINS_COMPLETE:
      return {
        ...state,
        isFetchingAdmins: false,
        admins: action.payload.admins,
      };

    case types.FETCH_ADMINS_ERROR:
      return {
        ...state,
        isFetchingAdmins: false,
        errors: {
          ...state.errors,
          [constants.FETCH_ADMINS]: action.payload.error.message,
        },
      };

    case types.FETCH_ADMIN_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingAdminDetail: true,
      };

    case types.FETCH_ADMIN_DETAIL_COMPLETE:
      return {
        ...state,
        isFetchingAdminDetail: false,
        adminDetail: action.payload.adminDetail,
      };

    case types.FETCH_ADMIN_DETAIL_ERROR:
      return {
        ...state,
        isFetchingAdminDetail: false,
        errors: {
          ...state.errors,
          [constants.FETCH_ADMIN_DETAIL]: action.payload.error.message,
        },
      };

    case types.ADD_ADMIN_BEGIN:
      return {
        ...state,
        isAddingAdmins: true,
      };

    case types.ADD_ADMIN_COMPLETE:
      return {
        ...state,
        isAddingAdmins: false,
      };

    case types.ADD_ADMIN_ERROR:
      return {
        ...state,
        isAddingAdmins: false,
        errors: {
          ...state.errors,
          [constants.ADD_ADMIN]: action.payload.error.message,
        },
      };

    case types.ADD_MANAGER_BEGIN:
      return {
        ...state,
        isAddingManager: true,
      };
    case types.ADD_MANAGER_COMPLETE:
      return {
        ...state,
        isAddingManager: false,
        admin: action.payload.admin,
      };
    case types.ADD_MANAGER_ERROR:
      return {
        ...state,
        isAddingManager: false,
        errors: {
          ...state.errors,
        },
      };
    case types.ADD_ROLES_BEGIN:
      return {
        ...state,
        isAddingRoles: true,
      };
    case types.ADD_ROLES_COMPLETE:
      return {
        ...state,
        isAddingRoles: false,
        result: action.payload.result,
      };
    case types.ADD_ROLES_ERROR:
      return {
        ...state,
        isAddingRoles: false,
        errors: {
          ...state.errors,
        },
      };

    case types.UPDATE_ADMIN_BEGIN:
      return {
        ...state,
        isUpdatingAdmins: true,
      };

    case types.UPDATE_ADMIN_COMPLETE:
      return {
        ...state,
        isUpdatingAdmins: false,
      };

    case types.UPDATE_ADMIN_ERROR:
      return {
        ...state,
        isUpdatingAdmins: false,
        errors: {
          ...state.errors,
          [constants.UPDATE_ADMIN]: action.payload.error.message,
        },
      };

    case types.REMOVE_ADMIN_BEGIN:
      return {
        ...state,
        isRemovingAdmins: true,
      };

    case types.REMOVE_ADMIN_COMPLETE:
      return {
        ...state,
        isRemovingAdmins: false,
      };

    case types.REMOVE_ADMIN_ERROR:
      return {
        ...state,
        isRemovingAdmins: false,
        errors: {
          ...state.errors,
          [constants.REMOVE_ADMIN]: action.payload.error.message,
        },
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name),
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default adminsReducer;
