export const RESET_ERROR = "@@activities/RESET_ERROR";
export const SET_FILTER = "@@activities/SET_FILTER";
export const FETCH_ACTIVITIES_BEGIN = "@@activities/FETCH_ACTIVITIES_BEGIN";
export const FETCH_ACTIVITIES_COMPLETE =
  "@@activities/FETCH_ACTIVITIES_COMPLETE";
export const FETCH_ACTIVITIES_ERROR = "@@activities/FETCH_ACTIVITIES_ERROR";
export const FETCH_ACTIVITY_DETAIL_BEGIN =
  "@@activities/FETCH_ACTIVITY_DETAIL_BEGIN";
export const FETCH_ACTIVITY_DETAIL_COMPLETE =
  "@@activities/FETCH_ACTIVITY_DETAIL_COMPLETE";
export const FETCH_ACTIVITY_DETAIL_ERROR =
  "@@activities/FETCH_ACTIVITY_DETAIL_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
