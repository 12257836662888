import React, { Fragment, useEffect, useState } from "react";
import { Field } from "redux-form";

import core from "core";
import BarChart from "core/components/BarChart";

import enhancers from "./enhancers";
import Tabs from "./partials/tabs";
import Summary from "./partials/summary";
import ModuleTable from "./partials/module-detail";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;

export function ModuleDetail({
  isChangingStatus,
  isChangingDisableStatus,
  isFetching,
  fetchModule,
  module,
  changeStatus,
  disableStatus,
}) {
  useEffect(() => fetchModule(), {});

  useEffect(() => {
    if (fetchModule === false) {
      fetchModule();
    }
  }, {});

  useEffect(() => {
    if (isChangingStatus === false) {
      fetchModule();
    }
  }, [isChangingStatus]);

  useEffect(() => {
    if (isChangingDisableStatus === false) {
      fetchModule();
    }
  }, [isChangingDisableStatus]);

  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Fragment>
      <PageTitle
        title={`Модулийн / Мерчантийн дэлгэрэнгүй`}
        links={[{ url: "/shops", title: "Дэлгүүр" }]}
      />
      {isFetching === undefined || isFetching === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <Summary module={module} />
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} />

                  <ModuleTable
                    module={module || {}}
                    changeStatus={changeStatus}
                    disableStatus={disableStatus}
                  />
                  <div
                    role="tabpanel"
                    id="shop-domain"
                    className="tab-pane fade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const ModuleDetailWithState = enhancers.redux(ModuleDetail);
export default ModuleDetailWithState;
