import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function (state, featureName) {
  switch (featureName) {
    case constants.FETCH_ORDERS:
      return state.isFetchingOrders;

    case constants.FETCH_ORDER_DETAIL:
      return state.isFetchingOrderDetail;

    case constants.CHANGE_STATUS:
      return state.isChangingStatus;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getOrders = createSelector(
  [(state) => state.orders],
  (orders) => orders
);
export const getOrderDetail = createSelector(
  [(state) => state.orderDetail],
  (orderDetail) => orderDetail
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
