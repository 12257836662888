import Navbar from './Navbar';
import Footer from './Footer';
import Loading from './Loading';
import UserInfo from './UserInfo';
import PageTitle from './PageTitle';
import FormGroup from './FormGroup';
import Pagination from './Pagination';
import FileUpload from './FileUpload';
import MainLayout from './MainLayout';
import SelectField from './SelectField';
import ShopSelector from './ShopSelector';
import DateTimePicker from './DateTimePicker';

export default {
  Navbar,
  Footer,
  Loading,
  UserInfo,
  PageTitle,
  FormGroup,
  FileUpload,
  Pagination,
  MainLayout,
  SelectField,
  ShopSelector,
  DateTimePicker,
};
