import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import React, { Fragment, useEffect } from "react";
import Summary from "./partials/summary";
import core from "core";
import enhancers from "./enhancers";
import ReactJson from "react-json-view";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function TransactionDetail({ isFetching, fetchDetail, transaction }) {
  useEffect(() => fetchDetail(), []);

  //   useEffect(
  //     () => {
  //       if (
  //         order.status === 'pending' &&
  //         order.ordertype === 'partial'
  //       ) {
  //         fetchShops(currentPage, { status: 'active', ...filter })
  //       }
  //     },
  //     [currentPage, filter, order]
  //   );

  

  return (
    <Fragment>
      <PageTitle title="Гүйлгээний дэлгэрэнгүй" />

      {isFetching === undefined || isFetching === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-3">
            <Summary transaction={transaction} />
          </div>
          <div className="col-lg-9">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  {!transaction.invoice.error_message ? (
                    <ReactJson
                      src={JSON.parse(transaction.invoice.response)}
                      theme="monokai"
                    />
                  ) : transaction.invoice.response}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const TransactionDetailWithState = enhancers.redux(TransactionDetail);
export default TransactionDetailWithState;
