import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import core from 'core';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = state => ({
  currentUser: core.selectors.getCurrentUser(
    state[core.constants.NAME]
  ) || {},
  topHits: selectors.getTopHits(state[constants.NAME]) || {},
  summary: selectors.getDashboardSummary(state[constants.NAME]) || {},
  dailyOrders: selectors.getDailyOrders(state[constants.NAME]) || {},
  monthlyOrders: selectors.getMonthlyOrders(state[constants.NAME]) || {},
  dailyHits: selectors.getDailyHits(state[constants.NAME]) || {},
  monthlyHits: selectors.getMonthlyHits(state[constants.NAME]) || {},
});
export const mapDispatchToProps = dispatch => ({
  fetchSummary() {
    dispatch(actions.fetchSummary());
  },
  fetchTopHits() {
    dispatch(actions.fetchTopHits());
  },
  fetchDailyOrders() {
    dispatch(actions.fetchDailyOrders({
      end: moment().endOf('day').toISOString(),
      start: moment().subtract(30, 'days').startOf('day').toISOString(),
    }));
  },
  fetchMonthlyOrders() {
    dispatch(actions.fetchMonthlyOrders());
  },
  fetchDailyHits() {
    dispatch(actions.fetchDailyHits({
      end: moment().endOf('day').toISOString(),
      start: moment().subtract(30, 'days').startOf('day').toISOString(),
    }));
  },
  fetchMonthlyHits() {
    dispatch(actions.fetchMonthlyHits());
  },
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
