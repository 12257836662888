export const RESET_ERROR = "@@orders/RESET_ERROR";
export const SET_FILTER = "@@orders/SET_FILTER";
export const FETCH_ORDERS_BEGIN = "@@orders/FETCH_ORDERS_BEGIN";
export const FETCH_ORDERS_COMPLETE = "@@orders/FETCH_ORDERS_COMPLETE";
export const FETCH_ORDERS_ERROR = "@@orders/FETCH_ORDERS_ERROR";
export const FETCH_ORDER_DETAIL_BEGIN = "@@orders/FETCH_ORDER_DETAIL_BEGIN";
export const FETCH_ORDER_DETAIL_COMPLETE =
  "@@orders/FETCH_ORDER_DETAIL_COMPLETE";
export const FETCH_ORDER_DETAIL_ERROR = "@@orders/FETCH_ORDER_DETAIL_ERROR";
export const CHANGE_STATUS_BEGIN = "@@orders/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@orders/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@orders/CHANGE_STATUS_ERROR";
