import React from "react";
import moment from "moment";
import classNames from "classnames";
import { Link } from "react-router-dom";

import core from "core";

function Table({
  rows = [],
  limit = 50,
  currentPage,
  archive,
  changeStatus,
  totalCount,
  status,
}) {
  const _handleChangeStatus = (event, id, is_subscribed) => {
    event.preventDefault();
    if (window.confirm("Та итгэлтэй байна уу?")) {
      changeStatus(id, is_subscribed ? "suspend" : "activate");
    }
  };

  const _handleArchive = (event, id, is_subscribed) => {
    event.preventDefault();
    if (!is_subscribed) {
      if (window.confirm("Та итгэлтэй байна уу?")) {
        archive(id);
      }
    }
  };
  
  return (
    <div className="table-responsive">
      <table className="shops-table table table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th>Төлөв</th>
            <th>Нэр</th>
            <th>Й-мэйл</th>
            <th>Загвар</th>
            <th style={{ width: 120 }}>Б.С</th>
            <th style={{ width: 150 }}>Ангилал</th>
            <th style={{ width: 100 }}>Огноо</th>
            <th style={{ width: 150 }}>Гэрээ дуусах</th>
            <th className="text-center" style={{ width: 120 }} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`tr_${row.id}`}>
              <td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
              <td style={{ width: 100 }}>
                <span
                  className={classNames("badge p-1", {
                    "badge-success": row.is_subscribed === true,
                    "badge-warning": !row.is_subscribed && row.expired,
                    "badge-info": !row.is_subscribed && !row.expired,
                  })}
                >
                  {!!row.is_subscribed && "идэвхтэй"}
                  {!row.is_subscribed && row.expired && "идэвхгүй"}
                  {!row.is_subscribed && !row.expired && "шинэ"}
                </span>
              </td>
              <td className="name">
                <Link to={`/shops/d/${row.id}`}>
                  <div className="media">
                    <div
                      className="d-flex mr-2 rounded-circle"
                      style={{
                        width: 40,
                        height: 40,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundImage: `url('${row.logo}')`,
                      }}
                    />
                    <div className="media-body">
                      <strong>{row.name}</strong>
                      <div>{row.phone}</div>
                    </div>
                  </div>
                </Link>
              </td>
              <td>{row.email}</td>
              <td>{row.theme === "default" ? "Сошиал" : "Минимал"}</td>
              <td>
                {(row.sale_channels || []).length === 0 && "-"}
                {_render(row.sale_channels)}
              </td>
              <td>
                {(row.category_codes || []).length === 0 && "-"}
                {(row.category_codes || []).length > 0 &&
                  (row.category_codes || [])
                    .map((code) => core.helpers.MainCategoryMap[code])
                    .join(", ")}
              </td>
              <td>{moment(row.created_at).format("YYYY-MM-DD")}</td>
              <td>
                {!row.expire_at
                  ? "-"
                  : moment(row.expire_at).format("YYYY-MM-DD")}
              </td>
              <td className="text-center" style={{ width: 100 }}>
                <a
                  className="action-icon"
                  target="blank"
                  href={
                    !row.custom_domain
                      ? `https://${row.uid}.zochil.shop`
                      : `https://${row.custom_domain}`
                  }
                >
                  <i className="uil-link" />
                </a>
                <Link
                  className="action-icon"
                  to={`/shops/edit/${row.id}?page=${currentPage}&status=${status}`}
                >
                  <i className="mdi mdi-square-edit-outline" />
                </Link>
                <a
                  href="#"
                  className={classNames("action-icon", {
                    "text-danger": row.is_subscribed,
                    "text-success": !row.is_subscribed,
                  })}
                  onClick={(event) =>
                    _handleChangeStatus(event, row.id, row.is_subscribed)
                  }
                >
                  <i
                    className={classNames("mdi", {
                      "mdi-checkbox-marked-circle-outline": !row.is_subscribed,
                      "mdi-cancel": row.is_subscribed,
                    })}
                  />
                </a>
                {!row.is_subscribed && row.status === "enabled" && (
                  <a
                    href="#"
                    className="action-icon text-danger"
                    onClick={(event) =>
                      _handleArchive(event, row.id, row.is_subscribed)
                    }
                  >
                    <i className="uil-archive-alt" />
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function _render(sc) {
  try {
    return sc.join(", ");
  } catch (error) {
    return JSON.stringify(sc);
  }
}

export default Table;
