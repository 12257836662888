import React, { Fragment, useEffect } from "react";
import core from "core";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function ChannelList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  list,
  limit,
  updateQueryString,
  changeStatus,
  isChangingStatus,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);
  useEffect(() => {
    if (isChangingStatus === false) {
      list(currentPage, filter);
    }
  }, [isChangingStatus]);
  return (
    <Fragment>
      <PageTitle title="Борлуулалтын сувагт нэгдэх хүсэлтүүд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/channels/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["code"]);

                if (formData.status) {
                  theFilter.status = formData.status.id;
                }

                if (formData.channel_type) {
                  theFilter.channel_type = formData.channel_type.id;
                }

                if (formData.merchant) {
                  theFilter.merchant_id = formData.merchant.id;
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Table
              limit={limit}
              changeStatus={changeStatus}
              isChangingStatus={isChangingStatus}
              currentPage={currentPage}
              rows={fetchResult.sale_channel_request}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
const ChannelListWithState = enhancers.redux(ChannelList);
export default ChannelListWithState;
