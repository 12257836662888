import omit from "lodash/omit";
import * as types from "./actionTypes";
import * as constants from "./constants";
export const initialState = {
  errors: {}
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_BEGIN:
      return {
        ...state,
        isChangingPassword: true
      };

    case types.CHANGE_PASSWORD_COMPLETE:
      return {
        ...state,
        isChangingPassword: false
      };

    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isChangingPassword: false,
        errors: {
          ...state.errors,
          [constants.CHANGE_PASSWORD]: action.payload.error.message
        }
      };

    case types.FETCH_SUMMARY_BEGIN:
      return {
        ...state,
        isFetchingSummary: true
      };

    case types.FETCH_SUMMARY_COMPLETE:
      return {
        ...state,
        isFetchingSummary: false,
        dashboardSummary: action.payload.dashboardSummary
      };

    case types.FETCH_SUMMARY_ERROR:
      return {
        ...state,
        isFetchingSummary: false,
        errors: {
          ...state.errors,
          [constants.FETCH_SUMMARY]: action.payload.error.message
        }
      };

    case types.FETCH_TOP_HITS_BEGIN:
      return {
        ...state,
        isFetchingTopHits: true
      };

    case types.FETCH_TOP_HITS_COMPLETE:
      return {
        ...state,
        isFetchingTopHits: false,
        topHits: action.payload.topHits
      };

    case types.FETCH_TOP_HITS_ERROR:
      return {
        ...state,
        isFetchingTopHits: false,
        errors: {
          ...state.errors,
          [constants.FETCH_TOP_HITS]: action.payload.error.message
        }
      };

    case types.FETCH_DAILY_HITS_BEGIN:
      return {
        ...state,
        isFetchingDailyHits: true
      };

    case types.FETCH_DAILY_HITS_COMPLETE:
      return {
        ...state,
        isFetchingDailyHits: false,
        dailtyHits: action.payload.dailtyHits
      };

    case types.FETCH_DAILY_HITS_ERROR:
      return {
        ...state,
        isFetchingDailyHits: false,
        errors: {
          ...state.errors,
          [constants.FETCH_DAILY_HITS]: action.payload.error.message
        }
      };

    case types.FETCH_MONTHLY_HITS_BEGIN:
      return {
        ...state,
        isFetchingMonthlyHits: true
      };

    case types.FETCH_MONTHLY_HITS_COMPLETE:
      return {
        ...state,
        isFetchingMonthlyHits: false,
        monthlyHits: action.payload.monthlyHits
      };

    case types.FETCH_MONTHLY_HITS_ERROR:
      return {
        ...state,
        isFetchingMonthlyHits: false,
        errors: {
          ...state.errors,
          [constants.FETCH_MONTHLY_HITS]: action.payload.error.message
        }
      };

    case types.FETCH_DAILY_ORDERS_BEGIN:
      return {
        ...state,
        isFetchingDailyOrders: true
      };

    case types.FETCH_DAILY_ORDERS_COMPLETE:
      return {
        ...state,
        isFetchingDailyOrders: false,
        dailyOrders: action.payload.dailyOrders
      };

    case types.FETCH_DAILY_ORDERS_ERROR:
      return {
        ...state,
        isFetchingDailyOrders: false,
        errors: {
          ...state.errors,
          [constants.FETCH_DAILY_ORDERS]: action.payload.error.message
        }
      };

    case types.FETCH_MONTHLY_ORDERS_BEGIN:
      return {
        ...state,
        isFetchingMonthlyOrders: true
      };

    case types.FETCH_MONTHLY_ORDERS_COMPLETE:
      return {
        ...state,
        isFetchingMonthlyOrders: false,
        monthlyOrders: action.payload.monthlyOrders
      };

    case types.FETCH_MONTHLY_ORDERS_ERROR:
      return {
        ...state,
        isFetchingMonthlyOrders: false,
        errors: {
          ...state.errors,
          [constants.FETCH_MONTHLY_ORDERS]: action.payload.error.message
        }
      };

    case "__PLACEHOLDER__":
      // ignore this.
      return state;

    case types.RESET_ERROR:
      return {
        ...state,
        errors: omit(state.errors, action.payload.name)
      };

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };

    default:
      return state;
  }
};

export default dashboardReducer;
