import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchModules({ payload: { filter, options } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_MODULES_URL,
      {
        filter,
        options,
      }
    );
    yield put({
      type: types.FETCH_MODULES_COMPLETE,
      payload: {
        modules: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MODULES_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchModuleDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_MODULE_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_MODULE_DETAIL_COMPLETE,
      payload: {
        moduleDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MODULE_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* changeStatus({ payload: { shop_id, module_uid } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_STATUS_URL,
      {
        shop_id,
        module_uid,
      }
    );
    yield put({
      type: types.CHANGE_STATUS_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* changeDisableStatus({ payload: { shop_id, module_uid } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_DISABLE_STATUS_URL,
      {
        shop_id,
        module_uid,
      }
    );
    yield put({
      type: types.CHANGE_DISABLE_STATUS_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_DISABLE_STATUS_ERROR,
      payload: {
        error,
      },
    });
  }
}

const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.FETCH_MODULES_BEGIN, fetchModules);
  yield takeLatest(types.FETCH_MODULE_DETAIL_BEGIN, fetchModuleDetail);
  yield takeLatest(types.CHANGE_STATUS_BEGIN, changeStatus);
  yield takeLatest(types.CHANGE_DISABLE_STATUS_BEGIN, changeDisableStatus);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
