import * as constants from "./constants";
import { createSelector } from "reselect";

const loadingSelector = function (state, featureName) {
  switch (featureName) {
    case constants.FETCH_WITHDRAWALS:
      return state.isFetchingWithdrawals;

    case constants.FETCH_WITHDRAWAL_DETAIL:
      return state.isFetchingWithdrawalDetail;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getWithdrawals = createSelector(
  [(state) => state.withdrawals],
  (withdrawals) => withdrawals
);
export const getWithdrawalDetail = createSelector(
  [(state) => state.withdrawal],
  (withdrawal) => withdrawal
);

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
