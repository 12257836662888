import React, { Fragment, useEffect } from "react";
import core from "core";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";
const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
export function AdminList({
  isFetching,
  isRemoving,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  fetchAdmins,
  remove,
  currentUser,
}) {
  useEffect(() => {
    fetchAdmins(currentPage, filter);
  }, [currentPage, filter]);
  useEffect(() => {
    if (isRemoving === false) {
      fetchAdmins(currentPage, filter);
    }
  }, [isRemoving]);
  console.log("currentUser: ", currentUser);
  return (
    <Fragment>
      <PageTitle title="Админууд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/admins/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Table
              rows={fetchResult.admins}
              remove={remove}
              isRemoving={isRemoving}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
const AdminListWithState = enhancers.redux(AdminList);
export default AdminListWithState;
