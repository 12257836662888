import * as types from "./actionTypes";
export const fetchBroadcasts = ({ filter, options }) => ({
  type: types.FETCH_BROADCASTS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchBroadcastDetail = ({ id }) => ({
  type: types.FETCH_BROADCAST_DETAIL_BEGIN,
  payload: {
    id,
  },
});
export const addBroadcast = ({ broadcast }) => ({
  type: types.ADD_BROADCAST_BEGIN,
  payload: {
    broadcast,
  },
});
export const updateBroadcast = ({ id, broadcast }) => ({
  type: types.UPDATE_BROADCAST_BEGIN,
  payload: {
    id,
    broadcast,
  },
});
export const removeBroadcast = ({ id }) => ({
  type: types.REMOVE_BROADCAST_BEGIN,
  payload: {
    id,
  },
});
export const setShopIds = ({ ids }) => ({
  type: types.SET_SHOP_IDS,
  payload: {
    ids,
  },
});
export const clearCreateUpdateId = () => ({
  type: types.CLEAR_CREATE_UPDATE_ID,
});
export const sendBroadcast = ({ id, shop_ids }) => ({
  type: types.SEND_BROADCAST_BEGIN,
  payload: {
    id,
    shop_ids,
  },
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
export const setShopFilter = ({ filter }) => ({
  type: types.SET_SHOP_FILTER,
  payload: {
    filter,
  },
});
