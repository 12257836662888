import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function(state, featureName) {
  switch (featureName) {
    case constants.FETCH_SETTLEMENTS:
      return state.isFetchingSettlements;
      break;

    case constants.FETCH_SETTLEMENT_DETAIL:
      return state.isFetchingSettlementDetail;
      break;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], error => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], loading => loading);
};
export const getSettlements = createSelector(
  [state => state.settlements],
  settlements => settlements
);
export const getSettlementDetail = createSelector(
  [state => state.settlementDetail],
  settlementDetail => settlementDetail
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [state => state.filter],
  filter => filter
);
