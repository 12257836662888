/*global Promise*/
import uuid from 'uuid';
import objectKeys from 'lodash/keys';
import Storage from './storage';

class FetchUtil {
  static getInstance() {
    if (!FetchUtil._instance) {
      FetchUtil._instance = new FetchUtil();
    }

    return FetchUtil._instance;
  }

  constructor() {
    this._storage = new Storage();
  }  

  async sendGet(url, isSecure = true) {
    const options = await this._buildOptions(false, isSecure);

    return fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, options).then(this._fetchResponseHandler);
  } 

  async downloadFile(url, isSecure = true) {
    const options = await this._buildOptions(false, isSecure);

    return await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, options);
  } 

  async sendDownload(url, isSecure = true) {
    const options = await this._buildOptions(false, isSecure);

    return fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, options);
  }

  async sendPost(url, body, isSecure = true) {
    const options = await this._buildOptions(true, isSecure);
    return fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, {
      ...options,
      body: JSON.stringify(body),
    }).then(this._fetchResponseHandler);
  }

  async _buildOptions(isPost = false, isSecure = true) {
    const options = {
      method: (isPost ? 'POST' : 'GET'),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (isSecure) {
      options.headers['access-token'] = await this._storage.getItem('access_token');
    }
    
    return options;
  }

  _fetchResponseHandler(response) {
    return Promise
      .resolve(response)
      .then(response => response.json())
      .then((response) => {
        if (response.status !== 'ok') {
          let errorMessage = response.message || 'server error';
          return Promise.reject(new Error(errorMessage));
        } else {
          return Promise.resolve(response);
        }
      })
  }

  qs({
    filter = {},
    options = {},
  }) {
    const {
      page = 1,
      sort_field='created_at',
      sort_direction='desc',
      limit=50
    } = options || {};

    const filterQueryString = objectKeys(filter).reduce((s, filterKey) => `${s}&${filterKey}=${filter[filterKey]}`, '');
    return `?page=${page}&sort_field=${sort_field}&limit=${limit}&sort_direction=${sort_direction}${filterQueryString}`;
  }
}


export default FetchUtil;
