export const SET_FILTER = "@@dashboard/SET_FILTER";
export const RESET_ERROR = "@@dashboard/RESET_ERROR";
export const CHANGE_PASSWORD_BEGIN = "@@dashboard/CHANGE_PASSWORD_BEGIN";
export const CHANGE_PASSWORD_COMPLETE = "@@dashboard/CHANGE_PASSWORD_COMPLETE";
export const CHANGE_PASSWORD_ERROR = "@@dashboard/CHANGE_PASSWORD_ERROR";
export const FETCH_SUMMARY_BEGIN = "@@dashboard/FETCH_SUMMARY_BEGIN";
export const FETCH_SUMMARY_COMPLETE = "@@dashboard/FETCH_SUMMARY_COMPLETE";
export const FETCH_SUMMARY_ERROR = "@@dashboard/FETCH_SUMMARY_ERROR";
export const FETCH_TOP_HITS_BEGIN = "@@dashboard/FETCH_TOP_HITS_BEGIN";
export const FETCH_TOP_HITS_COMPLETE = "@@dashboard/FETCH_TOP_HITS_COMPLETE";
export const FETCH_TOP_HITS_ERROR = "@@dashboard/FETCH_TOP_HITS_ERROR";
export const FETCH_DAILY_HITS_BEGIN = "@@dashboard/FETCH_DAILY_HITS_BEGIN";
export const FETCH_DAILY_HITS_COMPLETE =
  "@@dashboard/FETCH_DAILY_HITS_COMPLETE";
export const FETCH_DAILY_HITS_ERROR = "@@dashboard/FETCH_DAILY_HITS_ERROR";
export const FETCH_MONTHLY_HITS_BEGIN = "@@dashboard/FETCH_MONTHLY_HITS_BEGIN";
export const FETCH_MONTHLY_HITS_COMPLETE =
  "@@dashboard/FETCH_MONTHLY_HITS_COMPLETE";
export const FETCH_MONTHLY_HITS_ERROR = "@@dashboard/FETCH_MONTHLY_HITS_ERROR";
export const FETCH_DAILY_ORDERS_BEGIN = "@@dashboard/FETCH_DAILY_ORDERS_BEGIN";
export const FETCH_DAILY_ORDERS_COMPLETE =
  "@@dashboard/FETCH_DAILY_ORDERS_COMPLETE";
export const FETCH_DAILY_ORDERS_ERROR = "@@dashboard/FETCH_DAILY_ORDERS_ERROR";
export const FETCH_MONTHLY_ORDERS_BEGIN =
  "@@dashboard/FETCH_MONTHLY_ORDERS_BEGIN";
export const FETCH_MONTHLY_ORDERS_COMPLETE =
  "@@dashboard/FETCH_MONTHLY_ORDERS_COMPLETE";
export const FETCH_MONTHLY_ORDERS_ERROR =
  "@@dashboard/FETCH_MONTHLY_ORDERS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
