import * as types from "./actionTypes";
export const fetchCampaigns = ({ filter, options }) => ({
  type: types.FETCH_CAMPAIGNS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchCampaignDetail = ({ id }) => ({
  type: types.FETCH_CAMPAIGN_DETAIL_BEGIN,
  payload: {
    id,
  },
});
export const addCampaign = ({ campaign }) => ({
  type: types.ADD_CAMPAIGN_BEGIN,
  payload: {
    campaign,
  },
});
export const updateCampaign = ({ id, campaign }) => ({
  type: types.UPDATE_CAMPAIGN_BEGIN,
  payload: {
    id,
    campaign,
  },
});
export const removeCampaign = ({ id }) => ({
  type: types.REMOVE_CAMPAIGN_BEGIN,
  payload: {
    id,
  },
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
