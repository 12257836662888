import { connect } from "react-redux";
import { push } from "connected-react-router";

import * as actions from "../../../actions";
import * as constants from "../../../constants";
import * as selectors from "../../../selectors";

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
  isEdit: !!match.params.id,
  addError: error(state[constants.NAME], constants.ADD_ADMIN),
  isAdding: loading(state[constants.NAME], constants.ADD_ADMIN),
  updateError: error(state[constants.NAME], constants.UPDATE_ADMIN),
  isUpdating: loading(state[constants.NAME], constants.UPDATE_ADMIN),
  isFetching: loading(state[constants.NAME], constants.ADD_MANAGER),
  initialValues: !match.params.id
    ? {}
    : selectors.getAdminDetail(state[constants.NAME]),
  getResult: selectors.getManagerResult(state[constants.NAME]),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchDetail() {
    dispatch(actions.fetchAdminDetail({ id: params.id }));
  },
  onSubmit(admin) {
    if (params.id) {
      dispatch(actions.addRoles({ id: params.id, admin }));
    } else {
      dispatch(actions.addManager({ admin }));
    }
  },
  resetErrors() {
    dispatch(actions.resetError({ name: constants.ADD_MANAGER }));
    dispatch(actions.resetError({ name: constants.UPDATE_ADMIN }));
  },
  clearForm() {},
  // goBackList() {
  //   dispatch(push("/admins"));
  // },
});

export default (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
