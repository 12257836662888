import { createSelector } from "reselect";
import * as constants from "./constants";

const loadingSelector = function (state, featureName) {
  switch (featureName) {
    case constants.FETCH_CAMPAIGNS:
      return state.isFetchingCampaigns;

    case constants.FETCH_CAMPAIGN_DETAIL:
      return state.isFetchingCampaignDetail;

    case constants.ADD_CAMPAIGN:
      return state.isAddingCampaigns;

    case constants.UPDATE_CAMPAIGN:
      return state.isUpdatingCampaigns;

    case constants.REMOVE_CAMPAIGN:
      return state.isRemovingCampaigns;

    case "__PLACEHOLDER_LOADING__":
      return false;

    default:
      return false;
  }
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
  return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
  return createSelector([loadingSelector], (loading) => loading);
};
export const getCampaigns = createSelector(
  [(state) => state.campaigns],
  (campaigns) => campaigns
);
export const getCampaignDetail = createSelector(
  [(state) => state.campaignDetail],
  (campaignDetail) => campaignDetail || {}
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector(
  [(state) => state.filter],
  (filter) => filter
);
