
export default class TransactionService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchTransactions({ filter, options }) {
    return await this.fetchUtil.sendGet(
      `/payment-invoice/lists${this.fetchUtil.qs({
        filter,
        options,
      })}`
    );
  }

  async fetchTransactionDetail({ id }) {
    return await this.fetchUtil.sendGet(`/payment-invoice/detail/${id}`);
  }

  async changeStatus({ id, status }) {}

  __PLACEHOLDER_METHOD__() {}
}
