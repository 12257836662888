import React from "react";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";

import core from "core";

function Filter({ newUrl, filter, clearFilter, handleSubmit }) {
  return (
    <div className="row mb-2">
      <div className="col-lg-8">
        <form className="form-inline" onSubmit={handleSubmit}>
          <div style={{ width: 180 }} className="form-group mr-2 mb-2">
            <Field
              name="status"
              placeholder="Төлөв сонгох.."
              options={[
                { value: "pending", label: "Хүлээгдэж байгаа" },
                { value: "approved", label: "Баталсан" },
                { value: "cancelled", label: "Цуцлагдсан" },
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div className="form-group mb-2 mr-2">
            <Field
              component="input"
              type="text"
              className="form-control"
              id="searchField"
              placeholder="Нэрээр хайх"
              name="name"
            />
          </div>
          <div className="form-group mb-2 mr-2">
            <Field
              component="input"
              type="number"
              className="form-control"
              id="searchField"
              placeholder="Утасны дугаар"
              name="phone"
            />
          </div>
          <div className="form-group mb-2 mr-2">
            <Field
              component="input"
              type="text"
              className="form-control"
              id="searchField"
              placeholder="Имейл"
              name="email"
            />
          </div>
          <div className="form-group mb-2">
            <button className="btn btn-primary text-bold">ХАЙХ</button>{" "}
            {!!(filter && !isEqual(filter, {})) && (
              <button
                onClick={() => clearFilter()}
                className="btn btn-danger ml-2 text-bold"
              >
                <i className="uil-times-square"></i>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "contractFilterForm",
  enableReinitialize: true,
})(Filter);
