export const RESET_ERROR = "@@settlements/RESET_ERROR";
export const SET_FILTER = "@@settlements/SET_FILTER";
export const FETCH_SETTLEMENTS_BEGIN = "@@settlements/FETCH_SETTLEMENTS_BEGIN";
export const FETCH_SETTLEMENTS_COMPLETE =
  "@@settlements/FETCH_SETTLEMENTS_COMPLETE";
export const FETCH_SETTLEMENTS_ERROR = "@@settlements/FETCH_SETTLEMENTS_ERROR";
export const FETCH_SETTLEMENT_DETAIL_BEGIN =
  "@@settlements/FETCH_SETTLEMENT_DETAIL_BEGIN";
export const FETCH_SETTLEMENT_DETAIL_COMPLETE =
  "@@settlements/FETCH_SETTLEMENT_DETAIL_COMPLETE";
export const FETCH_SETTLEMENT_DETAIL_ERROR =
  "@@settlements/FETCH_SETTLEMENT_DETAIL_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
