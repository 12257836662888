
export default class PaymentService {
  constructor(fetchUtil, storage) {
    this.storage = storage;
    this.fetchUtil = fetchUtil;
  }

  async fetchPayments({ filter, options }) {
    const {data : payments, total: totalCount} = await this.fetchUtil.sendGet(
      `/payment-requests/list${this.fetchUtil.qs({
        filter, 
        options,
      })}`
    );

    return {payments, totalCount};
  }

  async fetchPaymentDetail({ id }) {
    return await this.fetchUtil.sendGet(`/payment-requests/detail/${id}`);
  }

  async changeStatus({ id, status }) {}

  __PLACEHOLDER_METHOD__() {}
}
