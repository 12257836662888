export const RESET_ERROR = "@@issues/RESET_ERROR";
export const SET_FILTER = "@@issues/SET_FILTER";
export const FETCH_ISSUES_BEGIN = "@@issues/FETCH_ISSUES_BEGIN";
export const FETCH_ISSUES_COMPLETE = "@@issues/FETCH_ISSUES_COMPLETE";
export const FETCH_ISSUES_ERROR = "@@issues/FETCH_ISSUES_ERROR";
export const FETCH_ISSUE_DETAIL_BEGIN = "@@issues/FETCH_ISSUE_DETAIL_BEGIN";
export const FETCH_ISSUE_DETAIL_COMPLETE =
  "@@issues/FETCH_ISSUE_DETAIL_COMPLETE";
export const FETCH_ISSUE_DETAIL_ERROR = "@@issues/FETCH_ISSUE_DETAIL_ERROR";
export const ADD_ISSUE_BEGIN = "@@issues/ADD_ISSUE_BEGIN";
export const ADD_ISSUE_COMPLETE = "@@issues/ADD_ISSUE_COMPLETE";
export const ADD_ISSUE_ERROR = "@@issues/ADD_ISSUE_ERROR";
export const UPDATE_ISSUE_BEGIN = "@@issues/UPDATE_ISSUE_BEGIN";
export const UPDATE_ISSUE_COMPLETE = "@@issues/UPDATE_ISSUE_COMPLETE";
export const UPDATE_ISSUE_ERROR = "@@issues/UPDATE_ISSUE_ERROR";
export const REMOVE_ISSUE_BEGIN = "@@issues/REMOVE_ISSUE_BEGIN";
export const REMOVE_ISSUE_COMPLETE = "@@issues/REMOVE_ISSUE_COMPLETE";
export const REMOVE_ISSUE_ERROR = "@@issues/REMOVE_ISSUE_ERROR";
export const CHANGE_STATUS_BEGIN = "@@issues/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@issues/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@issues/CHANGE_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
