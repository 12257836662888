import screens from './screens';

export default [
  {
    exact: true,
    path: "/users",
    component: screens.UserList,
  },
  {
    exact: true,
    path: "/users/new",
    component: screens.UserForm,
  },
  {
    exact: true,
    path: "/users/edit/:id",
    component: screens.UserForm,
  },
];