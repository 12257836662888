import * as types from "./actionTypes";
export const fetchBills = ({ filter, options }) => ({
  type: types.FETCH_BILLS_BEGIN,
  payload: {
    filter,
    options
  }
});
export const fetchBillDetail = ({ id }) => ({
  type: types.FETCH_BILL_DETAIL_BEGIN,
  payload: {
    id
  }
});
export const changeBillStatus = ({ id, status }) => ({
  type: types.CHANGE_BILL_STATUS_BEGIN,
  payload: {
    id,
    status
  }
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name
  }
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter
  }
});
