import queryString from 'query-string';

export const getCurrentRoute = (pathname) => {
  if (pathname === '/') {
    return "/";
  }

  if (pathname.indexOf('/', 1) === -1) {
    return pathname;
  }

  return pathname.substr(0, pathname.indexOf('/', 1));
}

export const compareName = (a, b) => {
  var nameA = a.value.toUpperCase();
  var nameB = b.value.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
}

export const parseQueryString = (search) => {
  return queryString.parse(search);
}

export const getTableIndex = (page, limit, index) => ((page - 1) * limit ) +  index + 1;

export const nameSorter = (a, b) => {
  if (a.name.toUpperCase() < b.name.toUpperCase()) {
    return -1;
  }

  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1;
  }

  return 0;

}

export function generatePagination({ page, total, limit, adjacents = 3 }) {
  var lastPage, lpm, num, pagination, r1, r2;
  pagination = [];
  lastPage = Math.ceil(total / limit);
  lpm = lastPage - 1;
  if (lastPage > 0) {
    if (lastPage < 7 + (adjacents * 2)) {
      pagination = (function() {
        var i, ref, results;
        results = [];
        for (num = i = 1, ref = lastPage; 1 <= ref ? i <= ref : i >= ref; num = 1 <= ref ? ++i : --i) {
          results.push(num);
        }
        return results;
      })();
    } else {
      if (page < 1 + (adjacents * 3)) {
        r2 = 4 + (adjacents * 2);
        pagination = (function() {
          var i, ref, results;
          results = [];
          for (num = i = 1, ref = r2; 1 <= ref ? i <= ref : i >= ref; num = 1 <= ref ? ++i : --i) {
            results.push(num);
          }
          return results;
        })();
        pagination = pagination.concat([-1, lpm, lastPage]);
      } else if (lastPage - (adjacents * 2) > page && page > adjacents * 2) {
        r1 = page - adjacents;
        r2 = page + adjacents;
        pagination = (function() {
          var i, ref, ref1, results;
          results = [];
          for (num = i = ref = r1, ref1 = r2; ref <= ref1 ? i <= ref1 : i >= ref1; num = ref <= ref1 ? ++i : --i) {
            results.push(num);
          }
          return results;
        })();
        pagination = pagination.concat([-1, lpm, lastPage]);
        pagination = [1, 2, -1].concat(pagination);
      } else {
        r1 = lastPage - (1 + (adjacents * 3));
        pagination = (function() {
          var i, ref, ref1, results;
          results = [];
          for (num = i = ref = r1, ref1 = lastPage; ref <= ref1 ? i <= ref1 : i >= ref1; num = ref <= ref1 ? ++i : --i) {
            results.push(num);
          }
          return results;
        })();
        pagination = [1, 2, -1].concat(pagination);
      }
    }
  }
  return pagination;
}

export function transformValToOpt(value, isMulti) {
  if (isMulti && Array.isArray(value)) {
    return value.map(val => ({
      label: val.name,
      value: val.id,
    }))
  } else {  
    return value && ({
      label: value.name,
      value: value.id,
    });
  }
}

export function transformOptToVal(option, isMulti) {
  if (isMulti && Array.isArray(option)) {
    return option.map(opt => ({
      name: opt.label,
      id: opt.value,
    }))
  } else {  
    return option && ({
      name: option.label,
      id: option.value,
    });
  }
}

export function haveRole(user, role) {
  if (user?.is_super === true) {
    return true;
  } else {
    return (user?.roles || []).indexOf(role) > -1;
  }
}

export const MainCategoryMap = {
  "A5": "Гоо сайхан",
  "A3": "Спорт",
  "A4": "Хүнс",
  "A9": "Бичиг хэрэг",
  "A2": "Эрэгтэй",
  "A0": "Гэр ахуй",
  "A1": "Эмэгтэй",
  "A7": "Үнэт эдлэл",
  "A6": "Хүүхдийн",
  "A8": "Электрон бараа",
  "A11": "Авто",
  "A12": "Хувцас",
  "A13": "Амьтан",
  "A15": "Хөгжим",
  "A14": "Эрүүл мэнд",
  "A16": "Үйлчилгээ ",
  "A17": "Сургалт ",
  "A18": "Цүнх ",
  "A19": "Гутал ",
  "A20": "Бусад "
};


export const PM_MAP = {
  qpay: "QPay",
  lend: "LendMN",
  card: "Карт",
  candy: "CandyPay",
  socialpay: "SocialPay",
  mostmoney: "Most Money",
  khan: "Хаан банк",
  golomt: "Голомт банк",
  state: "Төрийн банк",
  statebank: "Төрийн банк",
  tdb: "ХХБ",
  xac: "Хас Банк",
  ubcity: "Улаанбаатар хотын банк",
  capitron: "Капитрон банк",
  arig: "Ариг банк",
  storepay: "StorePay",
  bank: "Дансаар шилжүүлэх",
  chbank: "Чингис хаан банк",
  arig: 'Ариг банк',
  nibank: 'Хөрөнгө оруулалтын банк',
  chinggis: 'Чингис хаан банк',
  bogd: 'Богд банк',
  transbank: 'Тээвэр хөгжлийн банк'
}


const __PLACEHOLDER__ = null; // ignore this.