export const RESET_ERROR = "@@modules/RESET_ERROR";
export const SET_FILTER = "@@modules/SET_FILTER";
export const FETCH_MODULES_BEGIN = "@@modules/FETCH_MODULES_BEGIN";
export const FETCH_MODULES_COMPLETE = "@@modules/FETCH_MODULES_COMPLETE";
export const FETCH_MODULES_ERROR = "@@modules/FETCH_MODULES_ERROR";
export const FETCH_MODULE_DETAIL_BEGIN = "@@modules/FETCH_MODULE_DETAIL_BEGIN";
export const FETCH_MODULE_DETAIL_COMPLETE =
  "@@modules/FETCH_MODULE_DETAIL_COMPLETE";
export const FETCH_MODULE_DETAIL_ERROR = "@@modules/FETCH_MODULE_DETAIL_ERROR";
export const CHANGE_STATUS_BEGIN = "@@modules/CHANGE_STATUS_BEGIN";
export const CHANGE_STATUS_COMPLETE = "@@modules/CHANGE_STATUS_COMPLETE";
export const CHANGE_STATUS_ERROR = "@@modules/CHANGE_STATUS_ERROR";
export const CHANGE_DISABLE_STATUS_BEGIN =
  "@@modules/CHANGE_DISABLE_STATUS_BEGIN";
export const CHANGE_DISABLE_STATUS_COMPLETE =
  "@@modules/CHANGE_DISABLE_STATUS_COMPLETE";
export const CHANGE_DISABLE_STATUS_ERROR =
  "@@modules/CHANGE_DISABLE_STATUS_ERROR";
const __PLACEHOLDER__ = null; // ignore this.
