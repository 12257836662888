import ProductForm from "./ProductForm";
import ProductList from "./ProductList";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";

export default {
  ProductForm,
  ProductList,
  CategoryForm,
  CategoryList,
};