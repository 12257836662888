import React from "react";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";

import core from "core";

function Filter({ newUrl, filter, clearFilter, handleSubmit }) {
  return (
    <div className="row mb-2">
      <div className="col-lg-8">
        <form className="form-inline" onSubmit={handleSubmit}>
          <div style={{ width: 180 }} className="form-group mr-2 mb-2">
            <Field
              name="status"
              placeholder="Төлөв сонгох.."
              options={[
                { value: "pending", label: "Хүлээгдэж байгаа" },
                { value: "enabled", label: "Баталсан" },
                { value: "cancelled", label: "Цуцлагдсан" },
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div style={{ width: 180 }} className="form-group mr-2 mb-2">
            <Field
              name="module_uid"
              placeholder="Модулаар шүүх.."
              options={[
                { value: "coupons", label: "Купон" },
                { value: "socialshop", label: "Chatbot" },
                { value: "sale-campaigns", label: "Хямдралын аян" },
                { value: "socialpay", label: "SocialPay app" },
                { value: "kool", label: "KOOL." },
                { value: "hipay", label: "HiPay app" },
                { value: "monpay", label: "Monpay market" },
                { value: "lookbook", label: "Lookbook" },
                { value: "events", label: "Арга хэмжээ" },
                { value: "zochilgo", label: "Zochil GO" },
              ]}
              component={core.components.SelectField}
            />
          </div>
          <div className="form-group mb-2">
            <button className="btn btn-primary text-bold">ХАЙХ</button>{" "}
            {!!(filter && !isEqual(filter, {})) && (
              <button
                onClick={() => clearFilter()}
                className="btn btn-danger ml-2 text-bold"
              >
                <i className="uil-times-square"></i>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "modulesFilterForm",
  enableReinitialize: true,
})(Filter);
