import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* changePassword({ payload: { currentPassword, newPassword } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.CHANGE_PASSWORD_URL,
      {
        currentPassword,
        newPassword
      }
    );
    yield put({
      type: types.CHANGE_PASSWORD_COMPLETE
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_PASSWORD_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchSummary() {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_SUMMARY_URL,
      {}
    );
    yield put({
      type: types.FETCH_SUMMARY_COMPLETE,
      payload: {
        dashboardSummary: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_SUMMARY_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchTopHits() {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_TOP_HITS_URL,
      {}
    );
    yield put({
      type: types.FETCH_TOP_HITS_COMPLETE,
      payload: {
        topHits: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_TOP_HITS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchDailyHits({ payload: { start, end } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_DAILY_HITS_URL,
      {
        start,
        end
      }
    );
    yield put({
      type: types.FETCH_DAILY_HITS_COMPLETE,
      payload: {
        dailtyHits: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_DAILY_HITS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchMonthlyHits() {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_MONTHLY_HITS_URL,
      {}
    );
    yield put({
      type: types.FETCH_MONTHLY_HITS_COMPLETE,
      payload: {
        monthlyHits: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MONTHLY_HITS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchDailyOrders({ payload: { start, end } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_DAILY_ORDERS_URL,
      {
        start,
        end
      }
    );
    yield put({
      type: types.FETCH_DAILY_ORDERS_COMPLETE,
      payload: {
        dailyOrders: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_DAILY_ORDERS_ERROR,
      payload: {
        error
      }
    });
  }
}
export function* fetchMonthlyOrders() {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_MONTHLY_ORDERS_URL,
      {}
    );
    yield put({
      type: types.FETCH_MONTHLY_ORDERS_COMPLETE,
      payload: {
        monthlyOrders: result
      }
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MONTHLY_ORDERS_ERROR,
      payload: {
        error
      }
    });
  }
}
const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
  yield takeLatest(types.CHANGE_PASSWORD_BEGIN, changePassword);
  yield takeLatest(types.FETCH_SUMMARY_BEGIN, fetchSummary);
  yield takeLatest(types.FETCH_TOP_HITS_BEGIN, fetchTopHits);
  yield takeLatest(types.FETCH_DAILY_HITS_BEGIN, fetchDailyHits);
  yield takeLatest(types.FETCH_MONTHLY_HITS_BEGIN, fetchMonthlyHits);
  yield takeLatest(types.FETCH_DAILY_ORDERS_BEGIN, fetchDailyOrders);
  yield takeLatest(types.FETCH_MONTHLY_ORDERS_BEGIN, fetchMonthlyOrders);
  const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
