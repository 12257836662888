import React, { Fragment, useEffect } from "react";
import enhancers from "./enhancers";

import Summary from './partials/summary';
import BarChart from 'core/components/BarChart';

function HomeScreen({
  fetchSummary,
  summary,
  dailyOrders,
  monthlyOrders,
  dailyHits,
  monthlyHits,
  fetchDailyOrders,
  fetchMonthlyOrders,
  currentUser,
}) {
  useEffect(() => {
    if (currentUser.is_super) {
      fetchSummary();
      fetchDailyOrders();
      fetchMonthlyOrders();
    }
  }, [currentUser]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 mt-3">
          <Summary summary={summary} />
        </div>
      </div>
      <div className="row" >
        <div className="col-lg-12 col-sm-12" >
          {
            currentUser.is_super === true &&
            Array.isArray(monthlyOrders.data) &&
            Array.isArray(dailyOrders.data) &&
            <>
              <BarChart
                title="Захиалгын статистик"
                data={{
                  days: dailyOrders.data || [],
                  months: monthlyOrders.data || [],
                }}
              />
              <BarChart
                title="Хандалтын статистик"
                data={{
                  days: dailyHits.data || [],
                  months: monthlyHits.data || [],
                }}
                hideChooser={true}
                defaultDataKey="total_count"
              />
            </>
          }
        </div>
      </div>
    </Fragment>
  );
}

const HomeScreenWithState = enhancers.redux(HomeScreen);
export default HomeScreenWithState;
