import * as types from "./actionTypes";
export const fetchOrders = ({ filter, options }) => ({
  type: types.FETCH_ORDERS_BEGIN,
  payload: {
    filter,
    options,
  },
});
export const fetchOrderDetail = ({ id }) => ({
  type: types.FETCH_ORDER_DETAIL_BEGIN,
  payload: {
    id,
  },
});
export const changeStatus = ({ id, status }) => ({
  type: types.CHANGE_STATUS_BEGIN,
  payload: {
    id,
    status,
  },
});

export const resetError = ({ name }) => ({
  type: types.RESET_ERROR,
  payload: {
    name,
  },
});
export const setFilter = ({ filter }) => ({
  type: types.SET_FILTER,
  payload: {
    filter,
  },
});
