import { call, put, takeLatest } from "redux-saga/effects";
import ServiceFactory from "lib/serviceFactory";
import * as types from "./actionTypes";
import * as constants from "./constants";
export function* fetchAdmins({ payload: { filter, options } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.FETCH_ADMINS_URL, {
      filter,
      options,
    });
    yield put({
      type: types.FETCH_ADMINS_COMPLETE,
      payload: {
        admins: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_ADMINS_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* fetchAdminDetail({ payload: { id } }) {
  try {
    const result = yield call(
      ServiceFactory.call,
      constants.FETCH_ADMIN_DETAIL_URL,
      {
        id,
      }
    );
    yield put({
      type: types.FETCH_ADMIN_DETAIL_COMPLETE,
      payload: {
        adminDetail: result,
      },
    });
  } catch (error) {
    yield put({
      type: types.FETCH_ADMIN_DETAIL_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addAdmin({ payload: { admin } }) {
  try {
    yield call(ServiceFactory.call, constants.ADD_ADMIN_URL, {
      admin,
    });
    yield put({
      type: types.ADD_ADMIN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.ADD_ADMIN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addManager({ payload: { admin } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_MANAGER_URL, {
      admin,
    });

    yield put({
      type: types.ADD_MANAGER_COMPLETE,
      payload: {
        admin: result,  
      },
    });
  } catch (error) {
    
    yield put({
      type: types.ADD_MANAGER_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* addRoles({ payload: { admin } }) {
  try {
    const result = yield call(ServiceFactory.call, constants.ADD_ROLES_URL, {
      admin,
    });

    yield put({
      type: types.ADD_ROLES_COMPLETE,
      payload: {
        result: result,  
      },
    });
  } catch (error) {
    
    yield put({
      type: types.ADD_ROLES_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* updateAdmin({ payload: { id, admin } }) {
  try {
    yield call(ServiceFactory.call, constants.UPDATE_ADMIN_URL, {
      id,
      admin,
    });
    yield put({
      type: types.UPDATE_ADMIN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_ADMIN_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* removeAdmin({ payload: { id } }) {
  try {
    yield call(ServiceFactory.call, constants.REMOVE_ADMIN_URL, {
      id,
    });
    yield put({
      type: types.REMOVE_ADMIN_COMPLETE,
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_ADMIN_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* watchSagas() {
  yield takeLatest(types.FETCH_ADMINS_BEGIN, fetchAdmins);
  yield takeLatest(types.FETCH_ADMIN_DETAIL_BEGIN, fetchAdminDetail);
  yield takeLatest(types.ADD_ADMIN_BEGIN, addAdmin);
  yield takeLatest(types.ADD_ROLES_BEGIN, addRoles);
  yield takeLatest(types.ADD_MANAGER_BEGIN, addManager);
  yield takeLatest(types.UPDATE_ADMIN_BEGIN, updateAdmin);
  yield takeLatest(types.REMOVE_ADMIN_BEGIN, removeAdmin);
}
export default [watchSagas()];
