import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

function Filter({ newUrl, handleSubmit }) {
  return (
    <div className="row mb-2">
      {/*
      <div className="col-lg-8">
        <form
          className="form-inline"
          onSubmit={handleSubmit}
        />
      </div>
      */}
      {
        !!newUrl &&
        <div className="col-lg-4">
          <div className="text-lg-left">
            <Link
              to={newUrl}
              className="btn btn-danger mb-2 mr-2"
            >
              <i className="mdi mdi-plus-circle mr-1" /> Нэмэх
            </Link>
          </div>
        </div>
      }
    </div>
  );
}

export default reduxForm({
  form: 'broadcastFilterForm',
  enableReinitialize: true,
})(Filter);
